import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Grid,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import { Switch, useHistory, useParams,useLocation } from "react-router-dom";
import API from "../../../utilities/api";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "1%",
    paddingBottom: "1%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  root2: {
    width: "100%",
    maxWidth: 350,
    backgroundColor: theme.palette.background.paper,
  },
  forNodes: {
    width: "100%",
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
}));

const WalkoutTask = (props) => {
  const classes = useStyles();
  const [walkout, setWalkout] = useState({});
  const [taskHistory, setTaskHistory] = useState([]);

  const getTaskHistory = async () => {
    await API.get(`/taskHistory/task/${props.idHistory}`)
      .then((response) => {
        setTaskHistory(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getWalkoutHistory = async () => {
    await API.get(`/walkout/task/${props.idHistory}`)
      .then((response) => {
        setWalkout(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getTaskHistory();
    getWalkoutHistory();
  }, []);
  return (
    <>
      <div className={classes.root}>
        {taskHistory.map((item, index) => (
          <Accordion key={index} style={{ borderLeft: "8px solid #38BC7C" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Avance {index + 1} (Fecha: {item.createdAt.slice(0, 10)}) (Hora:{" "}
                {item.createdAt.slice(11)})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <List className={classes.root2}>
                  <ListItem>
                    <ListItemText
                      primary="Evidencia"
                      secondary={
                        <a
                          style={{ color: " #0000FF", cursor: "pointer" }}
                          onClick={() =>
                            window.open(`${item.action}`, "_blank")
                          }
                        >
                          {item.action}
                        </a>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Comentario"
                      secondary={item.description}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Km de enlace realizado "
                      secondary={item.linkKm}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Km de zona realizado "
                      secondary={item.network_km}
                    />
                  </ListItem>
                  {item.task.status.id === 5 &&
                  taskHistory.length - 1 === index ? (
                    <ListItem>
                      <ListItemText
                        primary="km de conteo realizado"
                        secondary={walkout.mapCount}
                      />
                    </ListItem>
                  ) : (
                    <></>
                  )}
                </List>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
};

const NodeDesignTask = (props) => {
  const classes = useStyles();
  const [nodeDesign, setnodeDesign] = useState([]);
  const [taskHistory, setTaskHistory] = useState([]);

  const getTaskHistory = async () => {
    await API.get(`/taskHistory/task/${props.idHistory}`)
      .then((response) => {
        setTaskHistory(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getNodeHistory = async () => {
    await API.get(`/node-design/task/${props.idHistory}`)
      .then((response) => {
        setnodeDesign(response.data);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getTaskHistory();
    getNodeHistory();
  }, []);

  return (
    <>
      <div className={classes.root}>
        {taskHistory.map((item, index) => (
          <Accordion key={index} style={{ borderLeft: "8px solid #38BC7C" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Avance {index + 1} (Fecha: {item.createdAt.slice(0, 10)}) (Hora:{" "}
                {item.createdAt.slice(11)})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List className={classes.root2}>
                <ListItem>
                  <ListItemText
                    primary="Evidencia"
                    secondary={
                      <a
                        style={{ color: " #0000FF", cursor: "pointer" }}
                        onClick={() => window.open(`${item.action}`, "_blank")}
                      >
                        {item.action}
                      </a>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Comentario"
                    secondary={item.description}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Km de enlace realizado "
                    secondary={item.linkKm}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Km de zona realizado "
                    secondary={item.network_km}
                  />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <div className={classes.root}>
        {nodeDesign.map((item, index) => (
          <Accordion key={index} style={{ borderLeft: "8px solid #38BC7C" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Nodo {index + 1} (Fecha: {item.task.deliveredAt.slice(0, 10)})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List className={classes.root2}>
                <ListItem>
                  <ListItemText
                    primary="Nombre del nodo"
                    secondary={item.name}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Km del nodo"
                    secondary={item.kilometer}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Km de enlace-nodo"
                    secondary={item.linkKm}
                  />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
};

const DigitizerTask = (props) => {
  const classes = useStyles();
  const [digitizier, setDigitizier] = useState({});
  const [taskHistory, setTaskHistory] = useState([]);

  const getTaskHistory = async () => {
    await API.get(`/taskHistory/task/${props.idHistory}`)
      .then((response) => {
        console.log(response.data);
        setTaskHistory(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getDigitizierHistory = async () => {
    await API.get(`/digitizer/task/${props.idHistory}`)
      .then((response) => {
        console.log(response.data);
        setDigitizier(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getTaskHistory();
    getDigitizierHistory();
  }, []);
  return (
    <>
      <div className={classes.root}>
        {taskHistory.map((item, index) => (
          <Accordion key={index} style={{ borderLeft: "8px solid #38BC7C" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Avance {index + 1} (Fecha: {item.createdAt.slice(0, 10)}) (Hora:{" "}
                {item.createdAt.slice(11)})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <List className={classes.root2}>
                  <ListItem>
                    <ListItemText
                      primary="Evidencia"
                      secondary={
                        <a
                          style={{ color: " #0000FF", cursor: "pointer" }}
                          onClick={() =>
                            window.open(`${item.action}`, "_blank")
                          }
                        >
                          {item.action}
                        </a>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Comentario"
                      secondary={item.description}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Km de enlace realizado "
                      secondary={item.linkKm}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Km de zona realizado "
                      secondary={item.network_km}
                    />
                  </ListItem>

                  {item.task.status.id === 5 &&
                  index === taskHistory.length - 1 ? (
                    <div>
                      <ListItem>
                        <ListItemText
                          primary="Km de zona propuesto"
                          secondary={digitizier.proposedKm}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Km de final de linea "
                          secondary={digitizier.finalLineKm}
                        />
                      </ListItem>
                    </div>
                  ) : (
                    <></>
                  )}
                </List>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
};

const DocumentationTask = (props) => {
  const classes = useStyles();
  const [taskHistory, setTaskHistory] = useState([]);

  const getTaskHistory = async () => {
    await API.get(`/taskHistory/task/${props.idHistory}`)
      .then((response) => {
        setTaskHistory(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getTaskHistory();
  }, []);

  return (
    <>
      <div className={classes.root}>
        {taskHistory.map((item, index) => (
          <Accordion key={index} style={{ borderLeft: "8px solid #38BC7C" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Avance {index + 1} (Fecha: {item.createdAt.slice(0, 10)}) (Hora:{" "}
                {item.createdAt.slice(11)})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <List className={classes.root2}>
                  <ListItem>
                    <ListItemText
                      primary="Evidencia"
                      secondary={
                        <a
                          style={{ color: " #0000FF", cursor: "pointer" }}
                          onClick={() =>
                            window.open(`${item.action}`, "_blank")
                          }
                        >
                          {item.action}
                        </a>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Comentario"
                      secondary={item.description}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Km de enlace realizado "
                      secondary={item.linkKm}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Km de zona realizado "
                      secondary={item.network_km}
                    />
                  </ListItem>
                </List>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </>
  );
};

const TaskDeltails = () => {
  const location = useLocation();
  //const { idTask } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [singleTask, setSingleTask] = useState({
    user: {
      email: "",
    },
    labor: {
      name: "",
      id: 0,
    },
    activity: {
      name: "",
    },
  });

  const getUniqueTask = async () => {
    await API.get(`/task/${location.state.idTaskDetail}`)
      .then((response) => {
        console.log(response.data);
        setSingleTask(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getUniqueTask();
  }, []);

  return (
    <Container>
      <Paper
        elevation={1}
        style={{ borderRadius: 10, borderLeft: "8px solid #FF9A24" }}
      >
        <Grid
          style={{ marginBottom: 20, marginTop: 10 }}
          container
          spacing={3}
          justify="space-between"
        >
          <Grid item>
            <Typography variant="h5" style={{ marginLeft: 20 }}>
              <b>Historial de la tarea asignada</b>
            </Typography>
            <Grid item>
              <Typography
                style={{ color: "#9B9A9A", marginTop: 10, marginLeft: 20 }}
                variant="subtitle1"
              >
                Más detalles a continuación
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              startIcon={<KeyboardBackspace />}
              fullWidth
              style={{
                marginLeft: 10,
                color: "white",
                backgroundColor: "#f7a900",
                width: 150,
                height: 35,
                marginRight: 20,
              }}
              onClick={() => {
                history.goBack();
              }}
            >
              Regresar
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper
        elevation={1}
        style={{
          borderRadius: 10,
          borderRight: "8px solid #FF9A24",
          borderLeft: "8px solid #FF9A24",
        }}
      >
        <Grid style={{ marginTop: 10 }} container spacing={3} direction="row">
          <Grid item>
            <Typography variant="h5" style={{ marginLeft: 20 }}>
              <b>{singleTask.name}</b>
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <div>
            <List className={classes.root2}>
              <ListItem>
                <ListItemText
                  primary="Km de enlance"
                  secondary={singleTask.linkKm}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Km de zona"
                  secondary={singleTask.networkKm}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Km total"
                  secondary={singleTask.totalKm}
                />
              </ListItem>
            </List>
          </div>

          <div>
            <List className={classes.root2}>
              <ListItem>
                <ListItemText
                  primary="Correo del asignado"
                  secondary={singleTask.user.email}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Tipo de tarea"
                  secondary={singleTask.labor.name}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Actividad perteneciente"
                  secondary={singleTask.activity.name}
                />
              </ListItem>
            </List>
          </div>
          <div>
            <List className={classes.root2}>
              <ListItem>
                <ListItemText
                  primary="Fecha de asignación"
                  secondary={singleTask.createdAt}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Fecha de entrega"
                  secondary={singleTask.deliveredAt}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Descripción"
                  secondary={singleTask.description}
                />
              </ListItem>
            </List>
          </div>
        </Grid>
      </Paper>
      {(() => {
        if (singleTask.labor.id == 1) {
          return <NodeDesignTask idHistory={location.state.idTaskDetail} />;
        } else if (singleTask.labor.id == 2) {
          return <DigitizerTask idHistory={location.state.idTaskDetail} />;
        } else if (singleTask.labor.id == 3) {
          return <WalkoutTask idHistory={location.state.idTaskDetail} />;
        } else if (singleTask.labor.id == 4) {
          return <DocumentationTask idHistory={location.state.idTaskDetail} />;
        }
      })()}
    </Container>
  );
};

export default TaskDeltails;
