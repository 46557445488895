import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import {
  Typography, Icon, Button, Container, Grid, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, TextField, Paper,
} from "@material-ui/core";
import {
  AddCircle, Save, Close, KeyboardBackspace,
  FolderShared,
} from "@material-ui/icons";
import API from "../../../utilities/api";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import deLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Toast from "../../../utilities/Notifications";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles({
  colHeader: {
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
});

const LoadingHeader = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width={100 + "%"}
        height={100}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
    </>
  );
};

const Loading = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width={100 + "%"}
        height={150}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
      <Skeleton
        variant="rect"
        width={100 + "%"}
        height={320}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
    </>
  );
};

const TableTasks = () => {
  const history = useHistory();
  const classes = useStyles();
  const { idActivity } = useParams();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [activityData, setActivityData] = useState({
    id: 1,
    polygon: {
      id: 0,
      project: {
        id: 0,
        isCompleted: false,
        tentativeAt: "",
      },
    },
    name: "",
    description: "",
    createdAt: "",
    updatedAt: "",
    isCompleted: false,
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [task, setTask] = useState({
    id: null,
    user: { id: null },
    status: { id: 1 },
    activity: { id: null },
    name: "",
    labor: { id: "", name: "Ninguno" },
    commentary: "",
    networkKm: 0,
    linkKm: 0,
    totalKm: 0,
    deliverable: "",
    createdAt: "",
    description: "",
    updatedAt: "",
    workedAt: "",
    deliveredAt: "",
  });
  const [taskDigitizer, setTaskDigitizer] = useState({
    id: null,
    task: { id: task.id },
    proposedKm: "",
    finalLineKm: 0,
  });
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [disabledDelete, setDisabledDelete] = useState(false);

  const [errorFlagName, setErrorFlagName] = useState(false);
  const [textErrorName, setTextErrorName] = useState("");
  const [errorFlagDescription, setErrorFlagDescription] = useState(false);
  const [textErrorDescription, setTextErrorDescription] = useState("");
  const [errorFlagDate, setErrorFlagDate] = useState(false);
  const [textErrorDate, setTextErrorDate] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);
  const [textError, setTextError] = useState("");

  const handleClickOpen = (taskRowData) => {
    cleanErrors();
    setTask(taskRowData);
    setOpen(true);
    setDisabledEdit(false);
    setDisabledDelete(false);
    if (taskRowData.labor.name === "Digitalizar") {
      getDigitizer(taskRowData.id);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setTask((prevState) => ({
      ...prevState,
      deliveredAt: date,
    }));
  };
  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    setTask((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChangeKm = (e) => {
    const { name, value } = e.target;
    setTaskDigitizer((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const cleanErrors = () => {
    setErrorFlagName(false);
    setTextErrorName("");
    setErrorFlagDescription(false);
    setTextErrorDescription("");
    setErrorFlagDate(false);
    setTextErrorDate("");
    setErrorFlag(false);
    setTextError("");
  };

  const getDigitizer = async (idTask) => {
    await API.get(`/digitizer/task/${idTask}`)
      .then((response) => {
        setTaskDigitizer(response.data);
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
      });
  };

  const validarGeneric = () => {
    if (task.name.trim() === "" || task.name == null) {
      setErrorFlagName(true);
      setTextErrorName("Campo requerido");
    } else if (!task.name.match(/^[[Ññíóáéú/_0-9 a-zA-Z\-]+$/)) {
      setErrorFlagName(true);
      setTextErrorName("Sólo se permiten letras, números, guiones y diagonal");
    } else if (
      !task.description.match(/^[[Ññíóáéú/_0-9 a-zA-Z\-]+$/) &&
      task.description.trim() != ""
    ) {
      setErrorFlagDescription(true);
      setTextErrorDescription(
        "Sólo se permiten letras, números, guiones y diagonal"
      );
    } else if (!task.deliveredAt == null) {
      setErrorFlagDate(true);
      setTextErrorDate("Campo requerido");
    } else {
      return false;
    }
    return true;
  };

  const validarDigitizer = () => {
    if (taskDigitizer.proposedKm === "") {
      setErrorFlag(true);
      setTextError("Campo requerido");
    } else if (taskDigitizer.proposedKm <= 0) {
      setErrorFlag(true);
      setTextError("No se aceptan valores menores o igual que 0");
    } else {
      return false;
    }
    return true;
  };

  const saveEditTask = async () => {
    setDisabledEdit(true);
    setDisabledDelete(true);
    cleanErrors();
    if (task.labor.name != "Digitalizar") {
      if (validarGeneric()) {
        setDisabledEdit(false);
        setDisabledDelete(false);
      } else {
        await API.put("/task/", task)
          .then((response) => {
            cleanErrors();
            Toast.SuccessMessage(
              "¡Modificación realizada!",
              "Los datos han sido modificados correctamente"
            );
            setOpen(false);
            peticionGet();
          })
          .catch((error) => {
            setOpen(false);
            console.log(error);
            Toast.ErrorMessage(
              "¡Ha ocurrido un error!",
              "Los sentimos, no se pudo completar el envío"
            );
          });
      }
    } else {
      if (validarGeneric() || validarDigitizer()) {
        setDisabledEdit(false);
        setDisabledDelete(false);
      } else {
        await API.put("/task/", task)
          .then((response) => {
            cleanErrors();
            API.put("/digitizer/", taskDigitizer)
              .then((response) => {
                cleanErrors();
                Toast.SuccessMessage(
                  "¡Modificación realizada!",
                  "Los datos han sido modificados correctamente"
                );
                setOpen(false);
                peticionGet();
              })
              .catch((error) => {
                console.log(error);
                setOpen(false);
                Toast.ErrorMessage(
                  "¡Ha ocurrido un error!",
                  "Los sentimos, no se pudo completar el envío"
                );
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const getActivity = async () => {
    await API.get(`/activity/${idActivity}`)
      .then((response) => {
        setActivityData(response.data);
        var date = new Date(
          response.data.polygon.project.tentativeAt
        ).toDateString();
        var now = new Date().toDateString();
        if (
          new Date(date) < new Date(now) &&
          response.data.polygon.project.isCompleted == false
        ) {
          Toast.WarningMessage(
            "Se recomienda actualizar.",
            "Fecha límite del proyecto excede el día de hoy"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
      });
  };

  const deleteTask = async () => {
    //sin problemas documentacion. diseño. levantamiento
    setDisabledEdit(true);
    setDisabledDelete(true);
    handleClose();
    if (task.labor.id === 2) {
      await API.get(`/taskHistory/task/${task.id}`)
        .then((response) => {
          if (response.data.length === 0) {
            API.get(`/digitizer/task/${task.id}`)
              .then((response) => {
                let id = response.data.id;
                API.delete(`/digitizer/${id}`)
                  .then((response) => {
                    API.delete(`/task/${task.id}`)
                      .then((response) => {
                        if (response === undefined) {
                          Toast.WarningMessage(
                            "¡Atención!",
                            "No se pueden eliminar tareas con avances."
                          );
                        } else {
                          Toast.SuccessMessage(
                            "¡Eliminación realizada!",
                            "Se eliminó la tarea correctamente"
                          );
                          peticionGet();
                          handleClose();
                        }
                      })
                      .catch((e) => {
                        console.log(e);
                        Toast.ErrorMessage(
                          "¡Ha ocurrido un error!",
                          "No se pudo establecer una conexión con el servidor."
                        );
                        handleClose();
                      });
                  })
                  .catch((e) => {
                    console.log(e);
                    Toast.ErrorMessage(
                      "¡Ha ocurrido un error!",
                      "No se pudo establecer una conexión con el servidor."
                    );
                    handleClose();
                  });
              })
              .catch((e) => {
                console.log(e);
                Toast.ErrorMessage(
                  "¡Ha ocurrido un error!",
                  "No se pudo establecer una conexión con el servidor."
                );
                handleClose();
              });
          } else {
            Toast.WarningMessage(
              "¡Atención!",
              "No se pueden eliminar tareas con avances."
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      handleClose();
      await API.delete(`/task/${task.id}`)
        .then((response) => {
          if (response === undefined) {
            Toast.WarningMessage(
              "¡Atención!",
              "No se pueden eliminar tareas con avances."
            );
          } else {
            Toast.SuccessMessage(
              "¡Eliminación realizada!",
              "Se eliminó la tarea correctamente"
            );
            peticionGet();
          }
        })
        .catch((e) => {
          console.log(e);
          Toast.ErrorMessage(
            "¡Ha ocurrido un error!",
            "No se pudo establecer una conexión con el servidor."
          );
          handleClose();
        });
    }
  };

  const goToAddTask = () => {
    history.push(`/homeAdministrator/task/addtask/${idActivity}`);
  };

  const goToTaskDeltails = (data) => {
    history.push(`/homeAdministrator/task/deltails/${data.id}`);
  };

  const peticionGet = async () => {
    await API.get(`/task/activity/${idActivity}`)
      .then((response) => {
        setData(response.data);
        if (response.data.length === 0) {
          Toast.InfoMessage(
            "Información",
            "Aún no hay tareas en la actividad.",
            "bottom-right"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
      });
  };

  useEffect(() => {
    peticionGet();
    getActivity();
  }, []);

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
        {/* Componente del botón */}
        <Container>
          {activityData.name == "" ? (
            <LoadingHeader />
          ) : (
            <Paper
              elevation={1}
              style={{ borderRadius: 10, borderLeft: "8px solid #38BC7C" }}
            >
              <Grid
                style={{ marginBottom: 20, marginTop: 10 }}
                container
                spacing={3}
                justify="space-between"
              >
                <Grid item>
                  <Typography variant="h5" style={{ marginLeft: 20 }}>
                    <b>
                      Gestiona tareas para la actividad: {activityData.name}
                    </b>
                    <Grid item>
                      <Typography
                        style={{ color: "#9B9A9A", marginTop: 10 }}
                        variant="subtitle1"
                      >
                        Listado de tareas existentes
                      </Typography>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid item>
                  {activityData.polygon.project.isCompleted ||
                  activityData.isCompleted ||
                  new Date(
                    new Date(
                      activityData.polygon.project.tentativeAt
                    ).toDateString()
                  ) < new Date(new Date().toDateString()) ? (
                    <Button
                      startIcon={<AddCircle />}
                      fullWidth
                      style={{
                        color: "white",
                        backgroundColor: "gray",
                        width: 150,
                        height: 35,
                        marginLeft: 20,
                        marginRight: 10,
                      }}
                      disabled
                    >
                      Nuevo
                    </Button>
                  ) : (
                    <Button
                      startIcon={<AddCircle />}
                      fullWidth
                      style={{
                        color: "white",
                        backgroundColor: "#38BC7C",
                        width: 150,
                        height: 35,
                        marginLeft: 20,
                        marginRight: 10,
                      }}
                      onClick={goToAddTask}
                    >
                      Nuevo
                    </Button>
                  )}
                  <Button
                    startIcon={<KeyboardBackspace />}
                    fullWidth
                    style={{
                      marginLeft: 10,
                      color: "white",
                      backgroundColor: "#f7a900",
                      width: 150,
                      height: 35,
                      marginRight: 20,
                    }}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Regresar
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Container>
        <Container>
          {data.length === 0 ? (
            <Loading />
          ) : (
            <MaterialTable
              style={{ borderRadius: 10, padding: 10 }}
              columns={[
                {
                  title: (
                    <Typography
                      className={classes.colHeader}
                      variant="subtitle2"
                    >
                      Nombre
                    </Typography>
                  ),
                  field: "name",
                  headerStyle: {
                    WebkitBorderTopLeftRadius: 10,
                    WebkitBorderTopRightRadius: 0,
                  },
                },
                {
                  title: (
                    <Typography
                      className={classes.colHeader}
                      variant="subtitle2"
                    >
                      Rol
                    </Typography>
                  ),
                  field: "labor.name",
                  headerStyle: {
                    WebkitBorderTopRightRadius: 0,
                  },
                },
                {
                  title: (
                    <Typography
                      className={classes.colHeader}
                      variant="subtitle2"
                    >
                      Fecha de entrega
                    </Typography>
                  ),
                  field: "deliveredAt",
                  type: "date",
                  headerStyle: {
                    WebkitBorderTopRightRadius: 0,
                  },
                },
                {
                  title: (
                    <Typography
                      className={classes.colHeader}
                      variant="subtitle2"
                    >
                      Descripción
                    </Typography>
                  ),
                  field: "description",
                  type: "date",
                  headerStyle: {
                    WebkitBorderTopRightRadius: 0,
                  },
                  render: (rowData) =>
                    rowData.description.trim() === "" ? (
                      <Typography variant="body2">
                        <em>Sin descripción</em>
                      </Typography>
                    ) : (
                      <Typography variant="body2">
                        {rowData.description}
                      </Typography>
                    ),
                },
                {
                  title: (
                    <Typography
                      className={classes.colHeader}
                      variant="subtitle2"
                    >
                      Estatus
                    </Typography>
                  ),
                  field: "status.name",
                  headerStyle: {
                    WebkitBorderTopRightRadius: 0,
                  },
                },
              ]}
              data={data}
              title=""
              actions={[
                //Botones de la tabla
                (rowData) => ({
                  icon: () =>
                    activityData.polygon.project.isCompleted ||
                    activityData.isCompleted ||
                    (rowData.status.id != 2 && rowData.status.id != 3) ? (
                      <Icon style={{ color: "gray" }}>edit</Icon>
                    ) : (
                      <Icon style={{ color: "#f7a900" }}>edit</Icon>
                    ),
                  tooltip:
                    activityData.polygon.project.isCompleted ||
                    activityData.isCompleted ||
                    (rowData.status.id != 2 && rowData.status.id != 3) ? (
                      <Typography>Ya concluido</Typography>
                    ) : (
                      <Typography>Editar tarea</Typography>
                    ),
                  disabled:
                    activityData.polygon.project.isCompleted ||
                    activityData.isCompleted ||
                    (rowData.status.id != 2 && rowData.status.id != 3),
                  //onClick: (event, rowData) => goToEditTask(rowData),
                  onClick: (event, rowData) => handleClickOpen(rowData),
                }),
                (rowData) => ({
                  icon: () => <FolderShared style={{ color: "#38BC7C" }} />,
                  tooltip: <Typography>Historial de avances</Typography>,
                  onClick: (event, rowData) => {
                    goToTaskDeltails(rowData);
                  },
                }),
              ]}
              //Configuraciín de las etiquetas de la tabla
              options={{
                draggable: false,
                actionsColumnIndex: -1, //orientación de los botonotes de Acciones
                headerStyle: {
                  fontWeight: "bold",
                  backgroundColor: "#01579b",
                  color: "#FFF",
                  WebkitBorderTopRightRadius: 10,
                },
              }}
              localization={{
                header: {
                  actions: "Editar",
                },
                body: {
                  emptyDataSourceMessage: "No hay registros aún",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Filtro",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsPerPage: "Filas por página:",
                  labelRowsSelect: "Filas",
                  firstAriaLabel: "Primer página",
                  firstTooltip: "Primer página",
                  previousAriaLabel: "Anterior",
                  previousTooltip: "Anterior",
                  nextAriaLabel: "Siguiente",
                  nextTooltip: "Siguiente",
                  lastAriaLabel: "Última",
                  lastTooltip: "Última",
                },
              }}
            />
          )}
        </Container>
        {/**Editar task */}
        <Dialog
          onClose={handleClose}
          aria-labelledby="Editar actividad"
          open={open}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle
            id="editTask"
            onClose={handleClose}
            style={{ color: "white", backgroundColor: "#555454" }}
          >
            <Icon
              style={{ float: "left", marginTop: "4px", marginRight: "5px" }}
            >
              edit
            </Icon>
            Editar
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              Edita la información de la tarea seleccionada
            </DialogContentText>
            <TextField
              id="name"
              label="Nombre de la tarea"
              color="primary"
              size="small"
              required
              fullWidth
              error={errorFlagName}
              helperText={textErrorName}
              name="name"
              onChange={handleChangeAdd}
              value={task && task.name}
              variant="filled"
            />
            <br />
            <br />
            <TextField
              id="description"
              label="Descripción"
              color="primary"
              multiline
              rows={1}
              fullWidth
              name="description"
              onChange={handleChangeAdd}
              error={errorFlagDescription}
              helperText={textErrorDescription}
              value={task && task.description}
              size="small"
              variant="filled"
            />
            <br />
            {task.labor.name === "Digitalizar" ? (
              <TextField
                id="proposedKm"
                label="Kilómetros propuestos"
                color="primary"
                size="small"
                required
                fullWidth
                error={errorFlag}
                helperText={textError}
                value={taskDigitizer && taskDigitizer.proposedKm}
                type="number"
                name="proposedKm"
                onChange={handleChangeKm}
                variant="filled"
                style={{ marginTop: 20 }}
              />
            ) : (
              ""
            )}
            <KeyboardDatePicker
              margin="normal"
              id="tentativeAt"
              required
              error={errorFlagDate}
              helperText={textErrorDate}
              minDate={new Date()}
              maxDate={activityData.polygon.project.tentativeAt}
              minDateMessage="Fecha fuera de rango"
              maxDateMessage="Fecha fuera de rango"
              label="Fecha de entrega"
              format="dd/MM/yyyy"
              value={task && task.deliveredAt}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <br />
            <br />
            <Grid container direction="row">
              {disabledDelete ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: 100 + "%",
                    height: 35,
                    paddingTop: 5,
                  }}
                >
                  <BeatLoader
                    color="#D1372D"
                    loading={disabledDelete}
                    size={20}
                  />
                </div>
              ) : (
                <Button
                  onClick={() => {
                    deleteTask();
                  }}
                  startIcon={<HighlightOffIcon />}
                  style={{
                    color: "white",
                    backgroundColor: "#D1372D",
                    width: 100 + "%",
                  }}
                >
                  Eliminar tarea
                </Button>
              )}
            </Grid>
          </DialogContent>
          <DialogActions style={{ float: "right", margin: "auto" }}>
            {disabledEdit ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: 175,
                  height: 35,
                  paddingTop: 5,
                }}
              >
                <BeatLoader color="#38BC7C" loading={disabledEdit} size={20} />
              </div>
            ) : (
              <Button
                startIcon={<Save />}
                style={{ color: "white", backgroundColor: "#38BC7C" }}
                onClick={saveEditTask}
              >
                Guardar Cambios
              </Button>
            )}
            <Button
              startIcon={<Close />}
              style={{ color: "white", backgroundColor: "gray" }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default TableTasks;
