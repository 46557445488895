import { Button, Grid, Typography } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Logo from "../Images/logo.png";
const theme = createMuiTheme();

theme.typography.h4 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2.4rem',
    },
};
theme.typography.h6 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
        fontSize: '1.5rem',
    },
};
const NotFound404 = () => {
    const history = useHistory();
    return (
        <ThemeProvider theme={theme}>
            <div style={{ backgroundColor: '#555454' }}>
                <Grid container
                    direction="column"
                    justify="center"
                    alignItems="center">
                    <img src={Logo} width="150" style={{ margin: 20 }} />
                    <Typography variant="h5" style={{ color: 'white', marginBottom: -20, }}>FIBERPROMEX S.A.S. de C.V.</Typography>
                    <Typography variant="h4" style={{ color: 'white', marginBottom: -20, fontSize: 140 }}>404</Typography>
                </Grid>
            </div>
            <Grid container
                direction="column"
                justify="center"
                alignItems="center"
                style={{ marginTop: 60 }}
            >
                <Typography variant="h4" style={{ color: '#555454', marginBottom: 30, marginBottom: 5, }}>Lo sentimos, página no encontrada</Typography>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Typography variant="h6" style={{ color: '#555454', }}>La pagína solicitada no pudo ser encontrada</Typography>
                </Grid>

                <Button variant="contained" style={{ backgroundColor: '#075E71', color: 'white', marginTop: 40, }} onClick={() => {
                    history.replace("/");
                }}>Ir a inicio de sesión</Button>
            </Grid>
        </ThemeProvider>
    )
}
export default NotFound404;