import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import 'animate.css';
import 'animate.css/animate.compat.css'
class Notify {
    SuccessMessage(Externtitle, Externmessage) {
        return store.addNotification({
            title: Externtitle,
            message: Externmessage,
            type: "success",
            dismiss: {
                duration: 5000,
                onScreen: true
            },
            insert: "top",
            container: "bottom-right",
            animationIn: ["animated", "pulse"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],
            width: 400
        });
    }

    ErrorMessage(Externtitle, Externmessage) {
        return store.addNotification({
            title: Externtitle,
            message: Externmessage,
            type: "danger",
            dismiss: {
                duration: 5000,
                onScreen: true
            },
            insert: "top",
            container: "bottom-right",
            animationIn: ["animated", "shakeX"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],
            width: 400
        });
    }

    WarningMessage(Externtitle, Externmessage) {
        return store.addNotification({
            title: Externtitle,
            message: Externmessage,
            type: "warning",
            dismiss: {
                duration: 5000,
                onScreen: true
            },
            insert: "top",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOutDown"],
            width: 400
        });
    }

    InfoMessage(Externtitle, Externmessage) {
        return store.addNotification({
            title: Externtitle,
            message: Externmessage,
            type: "info",
            dismiss: {
                duration: 5000,
                onScreen: true
            },
            insert: "top",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],
            width: 400
        });
    }
    InfoMessageLess(Externtitle, Externmessage) {
        return store.addNotification({
            title: Externtitle,
            message: Externmessage,
            type: "info",
            dismiss: {
                duration: 6000,
                onScreen: true
            },
            insert: "top",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
            animationOut: ["animated", "fadeOut"],
            width: 400
        });
    }
}

export default new Notify();