import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import {
  Container, Grid, Typography, Icon, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Button, Paper, Avatar, List, ListItem, ListItemAvatar, ListItemText, Divider
} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import { Close, Work, Phone, Email, EventNote } from "@material-ui/icons";
import API from "../../../utilities/api"
import Toast from "../../../utilities/Notifications"

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  rooting: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  colHeader: {
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
}));

const Loading = () => {
  return (
    <>
      <Skeleton variant="text" width={100 + '%'} height={150} animation="wave" style={{ borderRadius: 20 }} />
      <Skeleton variant="rect" width={100 + '%'} height={320} animation="wave" style={{ borderRadius: 20 }} />
    </>
  )
}

const TableEmployees = () => {
  const classes = useStyles();
  const [openDetails, setOpenDetails] = React.useState(false);
  const [openLabor, setOpenLabor] = React.useState(false);
  const [httpData, setHttpData] = React.useState([]);
  const [labors, setLabors] = React.useState([]);
  const [deltails, setDeltails] = React.useState({
    user: {
      createdAt: "",
      email: "",
      id: 0,
      isActive: true,
      isAdmin: false,
    },
    id: 0,
    lastName: "",
    name: "",
    phoneNumber: "",
    secondName: "",
  });

  const handleClickOpenDetails = (props) => {
    setDeltails(props);
    setOpenDetails(true);
  };

  const handleClickOpenLabor = (props) => {
    setDeltails(props);
    setOpenLabor(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  const handleCloseLabor = () => {
    setOpenLabor(false);
  };

  const httpGetEmployee = async () => {
    await API.get("/profile/").then((response) => {
      setHttpData(response.data)
    }).catch((e) => {
      console.log(e);
      Toast.ErrorMessage("Algo ocurrió en el servidor", "No se logró obtener información de los empleados.");
    })
  };

  const httpGetEmployeeLabors = async () => {
    await API.get(`/user-labor/user/${deltails.user.id}`).then((response) => {
      setLabors(response.data)
    }).catch((e) => {
      console.log(e);
      Toast.ErrorMessage("Algo ocurrió en el servidor", "No se logró obtener información de los roles.");
    })
  }

  useEffect(() => {
    httpGetEmployee();
    httpGetEmployeeLabors();
  }, [deltails.user.id]);

  return (
    <>
      <Container>
        <Paper elevation={1} style={{ borderRadius: 10, borderLeft: '8px solid #01579b' }}>
          <Grid
            style={{ marginBottom: 20, marginTop: 10 }}
            container
            spacing={3}
            justify="space-between"
          >
            <Grid item>
              <Typography variant="h5" style={{ marginLeft: 20, fontWeight: "bold" }}>
                Consulta general de todos los empleados
              </Typography>
              <Grid item>
                <Typography style={{ color: "#9B9A9A", marginTop: 10, marginLeft: 20, }} variant="subtitle1">
                  Listado de empleados existentes
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        {httpData.length === 0 ? (<Loading />) : (<MaterialTable
          style={{ borderRadius: 20, padding: 10 }}
          columns={[
            {
              title: (
                <Typography className={classes.colHeader} variant="subtitle2">
                  Nombre(s)
                </Typography>
              ),
              field: "name",
              headerStyle: {
                WebkitBorderTopLeftRadius: 10,
                WebkitBorderTopRightRadius: 0,
              },
            },
            {
              title: (
                <Typography className={classes.colHeader} variant="subtitle2">
                  Primer apellido
                </Typography>
              ),
              field: "lastName",
              headerStyle: {
                WebkitBorderTopRightRadius: 0,
              },
            },
            {
              title: (
                <Typography className={classes.colHeader} variant="subtitle2">
                  Cargo
                </Typography>
              ),
              field: "user.isAdmin",
              render: (rowData) =>
                rowData.user.isAdmin ? (<Typography>Administrador</Typography>) : (<Typography>Empleado</Typography>),
              headerStyle: {
                WebkitBorderTopRightRadius: 0,
              },
            },
            {
              title: (
                <Typography className={classes.colHeader} variant="subtitle2">
                  E-mail
                </Typography>
              ),
              field: "user.email",
              headerStyle: {
                WebkitBorderTopRightRadius: 0,
              },
            },
            {
              title: (
                <Typography className={classes.colHeader} variant="subtitle2">
                  Estatus
                </Typography>
              ),
              field: "user.isActive",
              render: (rowData) =>
                rowData.user.isActive ? (<Typography>Activo</Typography>) : (<Typography>Inactivo</Typography>),
              headerStyle: {
                WebkitBorderTopRightRadius: 0,
              },
            },
          ]}
          data={httpData}
          title=""
          //Configuraciín de las etiquetas de la tabla
          actions={[
            //Botones de la tabla
            (rowData) => ({
              icon: () => (
                <Icon style={{ color: "#00A4D8" }}>remove_red_eye</Icon>
              ),
              tooltip: <Typography>Ver Roles</Typography>,
              onClick: (event, rowData) => { handleClickOpenLabor(rowData) }
            }),
            (rowData) => ({
              icon: () => <Icon style={{ color: "#249B62" }}>more_vert</Icon>,
              tooltip: <Typography>Detalles</Typography>,
              onClick: (event, rowData) => { handleClickOpenDetails(rowData) },
            }),
          ]}
          //Configuraciín de las etiquetas de la tabla
          options={{
            draggable: false,
            actionsColumnIndex: -1, //orientación de los botonotes de Acciones
            headerStyle: {
              fontWeight: "bold",
              backgroundColor: "#01579b",
              color: "#FFF",
              WebkitBorderTopRightRadius: 10,
            },
          }}
          localization={{
            header: {
              actions: "Acciones",
            },
            body: {
              emptyDataSourceMessage: "No hay registros aún",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Filtro",
            },
            pagination: {
              labelDisplayedRows: "{from}-{to} de {count}",
              labelRowsPerPage: "Filas por página:",
              labelRowsSelect: "Filas",
              firstAriaLabel: "Primer página",
              firstTooltip: "Primer página",
              previousAriaLabel: "Anterior",
              previousTooltip: "Anterior",
              nextAriaLabel: "Siguiente",
              nextTooltip: "Siguiente",
              lastAriaLabel: "Última",
              lastTooltip: "Última",
            },
          }}
        />)}
        <Dialog
          onClose={handleCloseDetails}
          aria-labelledby="Añadir actividad"
          open={openDetails}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle
            id="addActivity"
            onClose={handleCloseDetails}
            style={{ color: "white", backgroundColor: "#555454" }}
          >
            <Icon
              style={{ float: "left", marginTop: "4px", marginRight: "5px" }}
            >
              assignment
            </Icon>
            Detalles del empleado
          </DialogTitle>

          <DialogContent dividers>
            <DialogContentText>
              Información detallada del empleado seleccionado
            </DialogContentText>
            <List className={classes.rooting}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#ababab" }}>
                    <EventNote />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Fecha de alta" secondary={deltails.user.createdAt} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#ababab" }}>
                    <Phone />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Teléfono" secondary={deltails.phoneNumber} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#ababab" }}>
                    <Email />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Correo electrónico" secondary={deltails.user.email} />
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions style={{ float: "center", margin: "auto" }}>
            <Button
              startIcon={<Close />}
              style={{ color: "white", backgroundColor: "#00A4D8" }}
              onClick={handleCloseDetails}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>

        {/*Dialog de ver roles */}

        <Dialog
          onClose={handleCloseLabor}
          aria-labelledby="Añadir actividad"
          open={openLabor}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle
            id="addActivity"
            onClose={handleCloseLabor}
            style={{ color: "white", backgroundColor: "#555454" }}
          >
            <Icon
              style={{ float: "left", marginTop: "4px", marginRight: "5px" }}
            >
              work
            </Icon>
            Roles del empleado
          </DialogTitle>

          <DialogContent dividers>
            <DialogContentText>
              Lista de los roles del empleado seleccionado
            </DialogContentText>
            <List className={classes.rooting}>
              {
                labors.map(item => (
                  <>
                    <ListItem key={item.labor.id}>
                      <ListItemAvatar>
                        <Avatar style={{ backgroundColor: "#ababab" }}>
                          <Work />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Rol" secondary={item.labor.name} />
                    </ListItem>
                  </>
                ))
              }
            </List>
          </DialogContent>
          <DialogActions style={{ float: "center", margin: "auto" }}>
            <Button
              startIcon={<Close />}
              style={{ color: "white", backgroundColor: "#00A4D8" }}
              onClick={handleCloseLabor}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export default TableEmployees;
