import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import {
  Typography,
  Icon,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  InputAdornment,
  Container,
  Grid,
  Paper,
} from "@material-ui/core";
import { AddCircle, Save, Close } from "@material-ui/icons";
import API from "../../../utilities/api";
import { useHistory } from "react-router-dom";
import Toast from "../../../utilities/Notifications";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles({
  colHeader: {
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
});

const Loading = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width={100 + "%"}
        height={150}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
      <Skeleton
        variant="rect"
        width={100 + "%"}
        height={320}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
    </>
  );
};

const TableTechnologies = () => {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [errorFlag, setErrorFlag] = useState(false);
  const [textError, setTextError] = useState("");
  const [openAdd, setOpenAdd] = useState(false);
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const [technology, setTechnology] = useState({
    id: null,
    name: "",
  });

  const handleClickOpen = (technologyRowData) => {
    setDisabledEdit(false);
    cleanErrors();
    setTechnology(technologyRowData);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenAdd = () => {
    const empty = {
      id: 0,
      name: "",
    };
    setTechnology(empty);
    cleanErrors();
    setOpenAdd(true);
    setDisabledSave(false);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setTechnology((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    setTechnology((prevState) => ({
      ...prevState,
      id: null,
      [name]: value,
    }));
  };

  const validar = () => {
    if (technology.name.trim() === "" || technology.name == null) {
      setErrorFlag(true);
      setTextError("Campo requerido");
    } else if (!technology.name.match(/^[Ññíóáéú/_0-9 a-zA-Z\-]+$/)) {
      setErrorFlag(true);
      setTextError("Sólo se permiten letras, números, guiones y diagonal");
    } else {
      return false;
    }
    return true;
  };

  const cleanErrors = () => {
    setErrorFlag(false);
    setTextError("");
  };

  const saveNewTechnology = async () => {
    cleanErrors();
    setDisabledSave(true);
    if (validar()) {
      setDisabledSave(false);
    } else {
      await API.post("/technology/", technology)
        .then((response) => {
          Toast.SuccessMessage(
            "¡Registro realizado!",
            "Datos guardados correctamente"
          );
          peticionGet();
          setOpenAdd(false);
        })
        .catch((error) => {
          setOpenAdd(false);
          Toast.ErrorMessage(
            "¡Ha ocurrido un error!",
            "Los sentimos, no se pudo completar el envío"
          );
          console.log(error);
        });
    }
  };

  const saveTechnology = async () => {
    cleanErrors();
    setDisabledEdit(true);
    if (validar()) {
      setDisabledEdit(false);
    } else {
      await API.put("/technology/", technology)
        .then((response) => {
          Toast.SuccessMessage(
            "¡Modificación realizada!",
            "Los datos han sido modificados correctamente"
          );
          peticionGet();
          setOpen(false);
        })
        .catch((error) => {
          setOpen(false);
          console.log(error);
          Toast.ErrorMessage(
            "¡Ha ocurrido un error!",
            "Los sentimos, no se pudo completar el envío"
          );
        });
    }
  };

  const goToPolygons = (technologyRowData) => {
    history.push(
      `/homeAdministrator/technologies/polygons/${technologyRowData.id}`
    );
  };

  const peticionGet = async () => {
    await API.get("/technology/")
      .then((response) => {
        setData(response.data);
        if (response.data.length === 0) {
          Toast.InfoMessage(
            "Información",
            "Aún no hay tecnologías en el sistema.",
            "bottom-right"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
      });
  };

  useEffect(() => {
    peticionGet();
  }, []); //Está en constante revisión de cambios de la data

  return (
    <>
      <Container>
        <Paper
          elevation={1}
          style={{ borderRadius: 10, borderLeft: "8px solid #38BC7C" }}
        >
          <Grid
            style={{ marginBottom: 20, marginTop: 10 }}
            container
            spacing={3}
            justify="space-between"
          >
            <Grid item>
              <Typography variant="h5" style={{ marginLeft: 20 }}>
                <b>Administra tecnologías utilizadas en los polígonos</b>
              </Typography>
              <Grid item>
                <Typography
                  style={{ color: "#9B9A9A", marginTop: 10, marginLeft: 20 }}
                  variant="subtitle1"
                >
                  Listado de tecnologías existentes
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                startIcon={<AddCircle />}
                fullWidth
                style={{
                  color: "white",
                  backgroundColor: "#38BC7C",
                  width: 150,
                  height: 35,
                  marginRight: 20,
                }}
                onClick={handleClickOpenAdd}
              >
                Nuevo
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>

      {/*Add */}
      <Dialog
        onClose={handleCloseAdd}
        aria-labelledby="Añadir actividad"
        open={openAdd}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          id="addActivity"
          onClose={handleCloseAdd}
          style={{ color: "white", backgroundColor: "#555454" }}
        >
          <Icon style={{ float: "left", marginTop: "4px", marginRight: "5px" }}>
            add_circle
          </Icon>
          Agregar
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            Agrega una nueva tecnología al sistema
          </DialogContentText>
          <TextField
            id="name"
            label="Nombre"
            color="primary"
            fullWidth
            required
            error={errorFlag}
            helperText={textError}
            name="name"
            onChange={handleChangeAdd}
            size="small"
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon style={{ color: "gray" }}>memory</Icon>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions style={{ float: "right", margin: "auto" }}>
          {disabledSave ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: 108,
                height: 35,
                paddingTop: 5,
              }}
            >
              <BeatLoader color="#38BC7C" loading={disabledSave} size={20} />
            </div>
          ) : (
            <Button
              startIcon={<Save />}
              style={{ color: "white", backgroundColor: "#38BC7C" }}
              onClick={saveNewTechnology}
            >
              Guardar
            </Button>
          )}
          <Button
            startIcon={<Close />}
            style={{ color: "white", backgroundColor: "gray" }}
            onClick={handleCloseAdd}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Container>
        {data.length === 0 ? (
          <Loading />
        ) : (
          <MaterialTable
            style={{ borderRadius: 20, padding: 10 }}
            columns={[
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Nombre
                  </Typography>
                ),
                field: "name",
                headerStyle: {
                  WebkitBorderTopLeftRadius: 10,
                  WebkitBorderTopRightRadius: 0,
                },
              },
            ]}
            data={data}
            title=""
            actions={[
              //Botones de la tabla
              (rowData) => ({
                icon: () => <Icon style={{ color: "#f7a900" }}>edit</Icon>,
                tooltip: <Typography>Editar tecnología</Typography>,
                onClick: (event, rowData) => handleClickOpen(rowData),
              }),
              (rowData) => ({
                icon: () => (
                  <Icon style={{ color: "#00A4D8" }}>remove_red_eye</Icon>
                ),
                tooltip: <Typography>Ver polígonos</Typography>,
                onClick: (event, rowData) => goToPolygons(rowData),
              }),
            ]}
            //Configuraciín de las etiquetas de la tabla
            options={{
              draggable: false,
              actionsColumnIndex: -1, //orientación de los botonotes de Acciones
              headerStyle: {
                fontWeight: "bold",
                backgroundColor: "#01579b",
                color: "#FFF",
                WebkitBorderTopRightRadius: 10,
              },
            }}
            localization={{
              header: {
                actions: "Acciones",
              },
              body: {
                emptyDataSourceMessage: "No hay registros aún",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Filtro",
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsPerPage: "Filas por página:",
                labelRowsSelect: "Filas",
                firstAriaLabel: "Primer página",
                firstTooltip: "Primer página",
                previousAriaLabel: "Anterior",
                previousTooltip: "Anterior",
                nextAriaLabel: "Siguiente",
                nextTooltip: "Siguiente",
                lastAriaLabel: "Última",
                lastTooltip: "Última",
              },
            }}
          />
        )}
      </Container>

      {/* Dialog de editar */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="Editar actividad"
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          id="addActivity"
          onClose={handleClose}
          style={{ color: "white", backgroundColor: "#555454" }}
        >
          <Icon style={{ float: "left", marginTop: "4px", marginRight: "5px" }}>
            edit
          </Icon>
          Editar
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            Edita el nombre de la tecnología seleccionada
          </DialogContentText>
          <TextField
            id="name"
            name="name"
            label="Nombre"
            required
            color="primary"
            onChange={handleChangeEdit}
            value={technology && technology.name}
            fullWidth
            error={errorFlag}
            helperText={textError}
            size="small"
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon style={{ color: "gray" }}>memory</Icon>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions style={{ float: "right", margin: "auto" }}>
          {disabledEdit ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: 175,
                height: 35,
                paddingTop: 5,
              }}
            >
              <BeatLoader color="#38BC7C" loading={disabledEdit} size={20} />
            </div>
          ) : (
            <Button
              startIcon={<Save />}
              style={{ color: "white", backgroundColor: "#38BC7C" }}
              onClick={saveTechnology}
            >
              Guardar Cambios
            </Button>
          )}
          <Button
            startIcon={<Close />}
            style={{ color: "white", backgroundColor: "gray" }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <br />
    </>
  );
};

export default TableTechnologies;
