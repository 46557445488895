import React, { useEffect } from "react";
import PrintIcon from '@material-ui/icons/Print';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from "../../../Images/LogoPDF.png";
import Container from "@material-ui/core/Container";
import API from "../../../utilities/api"
import Toast from "../../../utilities/Notifications";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid, Typography, Icon, Paper,
} from "@material-ui/core";

const useStyles = makeStyles({
  colHeader: {
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
});

const columnsPDF = [
  {
    title: "Nombre(s)",
    field: 'name',
  },
  {
    title: "Primer apellido",
    field: 'lastName',

  },
  {
    title: "Segundo Apellido",
    field: 'secondName',
 
  },
 /* {
    title: "Email",
    field: 'id',
  },
  {
    title: "Estatus",
    field:"user.email",
  },*/
]

const Loading = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width={100 + "%"}
        height={150}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
      <Skeleton
        variant="rect"
        width={100 + "%"}
        height={320}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
    </>
  );
};


const SearchEmployee = () => {
  const [employeeList, setEmployeeList] = React.useState([]);
  const classes = useStyles();
  const history = useHistory();

  const downloadPdf = () => {
    const doc = new jsPDF()
    doc.setFontSize(40);
    doc.text("A D P R O", 75, 20, 'center')
    doc.addImage(Logo, 'JEPG', 15, 9, 23, 23)

    doc.setFontSize(12);
    doc.text('FIBERPROMEX S.A.S de C.V Administrador\rde Proyectos.', 42, 27);

    doc.setFontSize(10);
    doc.text('Domicilio Fiscal: Privada Xitle #343,\rColonia los volcanes. C.P.62350\rDelegación Cuernavaca, Mor.', 139, 13);

    doc.setFontSize(15);
    doc.text('Lista de Empleados', 81, 45);

    doc.autoTable({
      margin: { top: 50 },
      theme: "striped",
      columns: columnsPDF.map(col => ({ ...col, dataKey: col.field })),
      body: employeeList
    })
    doc.save('Reporte_General.pdf');
  }

  const goToReport = (projectRowData) => {
    history.push(`/homeAdministrator/employeeReport/report/${projectRowData.id}`);
  };

  const peticionGetGeneralReport = async () => {
    let isEmployee = [];
    await API.get("/profile/").then((response) => {
      response.data.map((item) => {
        if (!item.user.isAdmin) {
          item.user.isActive = item.user.isActive ? "Finalizado" : "Proceso";
          isEmployee.push(item)
        }
      })
      setEmployeeList(isEmployee)
    }).catch((e) => {
      console.log(e);
      Toast.ErrorMessage("Algo ocurrió en el servidor", "No se logró obtener información de los reportes generales.");
    })
  };

  useEffect(() => {
    peticionGetGeneralReport();
  }, []);

  return (

    <Container>
      <Container style={{ marginLeft: -25 }}>
        <Paper elevation={1} style={{ borderRadius: 10, borderLeft: '8px solid #01579b' }}>
          <Grid
            style={{ marginBottom: 20, marginTop: 10 }}
            container
            spacing={3}
          >
            <Grid item>
              <Typography variant="h5" style={{ marginLeft: 20 }}>
                <b>Consulta de  Todos los empleados</b>
              </Typography>
              <Grid item>
                <Typography style={{ color: "#9B9A9A", marginTop: 10, marginLeft: 20, }} variant="subtitle1">
                  Visualiza todos los empleados que trabajan en la empresa
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Container style={{ marginLeft: -25 }}>
        {employeeList.length === 0 ? (
          <Loading />
        ) : (
          <MaterialTable
            style={{ borderRadius: 20, padding: 10 }}
            columns={[
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Nombre(s)
                  </Typography>
                ),
                field: "name",
                headerStyle: {
                  WebkitBorderTopLeftRadius: 10,
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Primer apellido
                  </Typography>
                ),
                field: "lastName",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Segundo apellido
                  </Typography>
                ),
                field: "secondName",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    E-mail
                  </Typography>
                ),
                field: "user.email",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Estatus
                  </Typography>
                ),
                field: "user.isActive",
                render: (rowData) =>
                  rowData.user.isActive ? (<Typography>Activo</Typography>) : (<Typography>Inactivo</Typography>),
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
            ]}
            data={employeeList}
            title=""
            actions={[
              (rowData) => ({
                icon: () => (
                  <Icon style={{ color: "#00A4D8" }}>remove_red_eye</Icon>
                ),
                tooltip: <Typography>Ver Proyecto</Typography>,
                onClick: (event, rowData) => goToReport(rowData),
              }),
            ]}
            //Configuraciín de las etiquetas de la tabla
            options={{
              draggable: false,
              actionsColumnIndex: -1, //orientación de los botonotes de Acciones
              headerStyle: {
                fontWeight: "bold",
                backgroundColor: "#01579b",
                color: "#FFF",
                WebkitBorderTopRightRadius: 10,
              },
            }}
            localization={{
              header: {
                actions: "Acciones",        
              },
              body: {
                emptyDataSourceMessage: "No hay registros aún",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Filtro",
                exportCSVName: "Exportar a CSV",
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsPerPage: "Filas por página:",
                labelRowsSelect: "Filas",
                firstAriaLabel: "Primer página",
                firstTooltip: "Primer página",
                previousAriaLabel: "Anterior",
                previousTooltip: "Anterior",
                nextAriaLabel: "Siguiente",
                nextTooltip: "Siguiente",
                lastAriaLabel: "Última",
                lastTooltip: "Última",
              },
            }}
          />
        )}
      </Container>
    </Container>


  )
};
export default SearchEmployee;