import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import {
  Typography, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Icon, Button, TextField, InputAdornment, Container,
  Grid, Paper,
} from "@material-ui/core";
import {
  AddCircle, Save, Close, CheckCircle,
  KeyboardBackspace,
} from "@material-ui/icons";
import API from "../../../utilities/api";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Toast from "../../../utilities/Notifications";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles({
  colHeader: {
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
});
const LoadingHeader = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width={100 + "%"}
        height={100}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
    </>
  );
};

const Loading = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width={100 + "%"}
        height={150}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
      <Skeleton
        variant="rect"
        width={100 + "%"}
        height={320}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
    </>
  );
};

const TableActivities = () => {
  const history = useHistory();
  const classes = useStyles();
  const { idPolygon } = useParams(); //{ idPolygon: 1 }

  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const [disabledDelete, setDisabledDelete] = useState(false);
  const [disabledEnd, setDisabledEnd] = useState(false);

  const [errorFlag, setErrorFlag] = useState(false);
  const [textError, setTextError] = useState("");
  const [errorFlagDescription, setErrorFlagDescription] = useState(false);
  const [textErrorDescription, setTextErrorDescription] = useState("");

  const [data, setData] = useState([]);
  const [activity, setActivity] = useState({
    id: 1,
    polygon: {
      id: 0,
    },
    name: "",
    description: "",
    createdAt: null,
    updatedAt: null,
    isCompleted: false,
  });
  const [polygonData, setPolygonData] = React.useState({
    id: 0,
    project: { id: 0 },
    technology: { id: 0 },
    type: { id: 0 },
    city: { id: 0 },
    name: "",
    createdAt: "",
    udpatedAt: "",
    kilometers: 0.0,
  });

  const handleClickOpenAdd = () => {
    const empty = {
      id: null,
      polygon: {
        id: idPolygon,
      },
      name: "",
      description: "",
      createdAt: "",
      updatedAt: "",
      isCompleted: false,
    };
    setActivity(empty);
    cleanErrors();
    setOpenAdd(true);
    setDisabledSave(false);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };
  const handleClickOpen = (activityRowData) => {
    cleanErrors();
    setActivity(activityRowData);
    setOpen(true);
    setDisabledEdit(false);
    setDisabledDelete(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenEnd = (activityRowData) => {
    setActivity(activityRowData);
    setOpenEnd(true);
    setDisabledEnd(false);
  };
  const handleCloseEnd = () => {
    setOpenEnd(false);
  };

  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setActivity((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    setActivity((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const endActivity = async () => {
    setDisabledEnd(true);
    handleCloseEnd();
    const nuevo = {
      isCompleted: true,
    };
    await API.patch(`/activity/${activity.id}`, nuevo)
      .then((response) => {
        peticionGet();
        Toast.SuccessMessage(
          "¡Modificación realizada!",
          "Se ha concluido la actividad correctamente"
        );
        setOpenEnd(false);
      })
      .catch((error) => {
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudo completar el envío"
        );
        console.log(error);
        setOpenEnd(false);
      });
  };

  const saveEdit = async () => {
    setDisabledEdit(true);
    setDisabledDelete(true);
    cleanErrors();
    if (validar()) {
      setDisabledEdit(false);
      setDisabledDelete(false);
    } else {
      await API.put("/activity/", activity)
        .then((response) => {
          peticionGet();
          Toast.SuccessMessage(
            "¡Modificación realizada!",
            "Los datos han sido modificados correctamente"
          );
          setOpen(false);
        })
        .catch((error) => {
          console.log(error);
          Toast.ErrorMessage(
            "¡Ha ocurrido un error!",
            "Los sentimos, no se pudo completar el envío"
          );
          setOpen(false);
        });
    }
  };

  const saveNewActivity = async () => {
    setDisabledSave(true);
    cleanErrors();
    if (validar()) {
      setDisabledSave(false);
    } else {
      await API.post("/activity/", activity)
        .then((response) => {
          peticionGet();
          Toast.SuccessMessage(
            "¡Registro realizado!",
            "Datos guardados correctamente"
          );
          setOpenAdd(false);
        })
        .catch((error) => {
          console.log(error);
          Toast.ErrorMessage(
            "¡Ha ocurrido un error!",
            "Los sentimos, no se pudo completar el envío"
          );
          setOpenAdd(false);
        });
    }
  };

  const getPolygon = async () => {
    await API.get(`/polygon/${idPolygon}`)
      .then((response) => {
        setPolygonData(response.data);
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
      });
  };

  const goToTask = (activityRowData) => {
    history.push(`/homeAdministrator/projects/task/${activityRowData.id}`);
  };

  const validar = () => {
    if (activity.name.trim() === "" || activity.name == null) {
      setErrorFlag(true);
      setTextError("Campo requerido");
    } else if (!activity.name.match(/^[[Ññíóáéú/_0-9 a-zA-Z\-]+$/)) {
      setErrorFlag(true);
      setTextError("Sólo se permiten letras, números, guiones y diagonal");
    } else if (
      !activity.description.match(/^[[Ññíóáéú/_0-9 a-zA-Z\-]+$/) &&
      activity.description.trim() != ""
    ) {
      setErrorFlagDescription(true);
      setTextErrorDescription(
        "Sólo se permiten letras, números, guiones y diagonal"
      );
    } else {
      return false;
    }
    return true;
  };

  const cleanErrors = () => {
    setErrorFlag(false);
    setTextError("");
    setErrorFlagDescription(false);
    setTextErrorDescription("");
  };

  const deleteActivity = async () => {
    setDisabledDelete(true);
    setDisabledEdit(true);
    handleClose();
    await API.delete(`/activity/${activity.id}`)
      .then((response) => {
        if (response === undefined) {
          Toast.WarningMessage(
            "¡Atención!",
            "No se pueden eliminar actividades con tareas."
          );
        } else {
          Toast.SuccessMessage(
            "¡Eliminación Realizada!",
            "Se eliminó la actividad correctamente."
          );
          peticionGet();
        }
      })
      .catch((e) => {
        console.log(e);
        handleClose();
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Ocurrió un error en el servidor."
        );
      });
  };

  const peticionGet = async () => {
    await API.get(`/activity/polygon/${idPolygon}`)
      .then((response) => {
        setData(response.data);
        if (response.data.length === 0) {
          Toast.InfoMessage(
            "Información",
            "Aún no hay actividades en el polígino.",
            "bottom-right"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
      });
  };

  useEffect(() => {
    peticionGet();
    getPolygon();
  }, []);

  return (
    <>
      {/* Componente del botón */}
      <Container>
        {polygonData.name == "" ? (
          <LoadingHeader />
        ) : (
          <Paper
            elevation={1}
            style={{ borderRadius: 10, borderLeft: "8px solid #38BC7C" }}
          >
            <Grid
              style={{ marginBottom: 20, marginTop: 10 }}
              container
              spacing={3}
              justify="space-between"
            >
              <Grid item>
                <Typography variant="h5" style={{ marginLeft: 20 }}>
                  <b>
                    Gestiona actividades para el polígono: {polygonData.name}
                  </b>
                </Typography>
                <Grid item>
                  <Typography
                    style={{ color: "#9B9A9A", marginTop: 10, marginLeft: 20 }}
                    variant="subtitle1"
                  >
                    Listado de actividades existentes
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                {polygonData.project.isCompleted ? (
                  <Button
                    startIcon={<AddCircle />}
                    fullWidth
                    style={{
                      color: "white",
                      backgroundColor: "gray",
                      width: 150,
                      height: 35,
                      marginLeft: 20,
                      marginRight: 10,
                    }}
                    disabled
                    onClick={handleClickOpenAdd}
                  >
                    Nuevo
                  </Button>
                ) : (
                  <Button
                    startIcon={<AddCircle />}
                    fullWidth
                    style={{
                      color: "white",
                      backgroundColor: "#38BC7C",
                      width: 150,
                      height: 35,
                      marginLeft: 20,
                      marginRight: 10,
                    }}
                    onClick={handleClickOpenAdd}
                  >
                    Nuevo
                  </Button>
                )}
                <Button
                  startIcon={<KeyboardBackspace />}
                  fullWidth
                  style={{
                    marginLeft: 10,
                    color: "white",
                    backgroundColor: "#f7a900",
                    width: 150,
                    height: 35,
                    marginRight: 20,
                  }}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Regresar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Container>

      {/*dialog de agregar*/}
      <Dialog
        onClose={handleCloseAdd}
        aria-labelledby="Añadir actividad"
        open={openAdd}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          id="addActivity"
          onClose={handleCloseAdd}
          style={{ color: "white", backgroundColor: "#555454" }}
        >
          <Icon style={{ float: "left", marginTop: "4px", marginRight: "5px" }}>
            add_circle
          </Icon>
          Agregar
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            Agrega una nueva actividad al polígo seleccionado
          </DialogContentText>
          <TextField
            id="name"
            label="Nombre"
            color="primary"
            fullWidth
            error={errorFlag}
            helperText={textError}
            required
            name={"name"}
            onChange={handleChangeAdd}
            size="small"
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon style={{ color: "gray" }}>font_download</Icon>
                </InputAdornment>
              ),
            }}
          />
          <br />
          <br />
          <TextField
            id="description"
            label="Descripción"
            fullWidth
            color="primary"
            variant="filled"
            multiline
            error={errorFlagDescription}
            helperText={textErrorDescription}
            name={"description"}
            onChange={handleChangeAdd}
            size="small"
            rows={3}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon style={{ color: "gray" }}>chat</Icon>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions style={{ float: "right", margin: "auto" }}>
          {disabledSave ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: 108,
                height: 35,
                paddingTop: 5,
              }}
            >
              <BeatLoader color="#38BC7C" loading={disabledSave} size={20} />
            </div>
          ) : (
            <Button
              startIcon={<Save />}
              style={{ color: "white", backgroundColor: "#38BC7C" }}
              onClick={saveNewActivity}
            >
              Guardar
            </Button>
          )}
          <Button
            startIcon={<Close />}
            style={{ color: "white", backgroundColor: "gray" }}
            onClick={handleCloseAdd}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Container>
        {data.length === 0 ? (
          <Loading />
        ) : (
          <MaterialTable
            style={{ borderRadius: 10, padding: 10 }}
            columns={[
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Nombre
                  </Typography>
                ),
                field: "name",
                headerStyle: {
                  WebkitBorderTopLeftRadius: 10,
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Descripción
                  </Typography>
                ),
                field: "description",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
                render: (rowData) =>
                  rowData.description.trim() === "" ? (
                    <Typography variant="body2">
                      <em>Sin descripción</em>
                    </Typography>
                  ) : (
                    <Typography variant="body2">
                      {rowData.description}
                    </Typography>
                  ),
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Estatus
                  </Typography>
                ),
                field: "isCompleted",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
                render: (rowData) =>
                  rowData.isCompleted === true ? (
                    <Typography>Terminado</Typography>
                  ) : (
                    <Typography>En proceso</Typography>
                  ),
              },
            ]}
            data={data}
            title=""
            actions={[
              //Botones de la tabla
              (rowData) => ({
                icon: () =>
                  rowData.isCompleted || polygonData.project.isCompleted ? (
                    <Icon style={{ color: "gray" }}>check_circle</Icon>
                  ) : (
                    <Icon style={{ color: "#38BC7C" }}>check_circle</Icon>
                  ),
                tooltip:
                  rowData.isCompleted || polygonData.project.isCompleted ? (
                    <Typography>Ya concluido</Typography>
                  ) : (
                    <Typography>Concluir</Typography>
                  ),
                onClick: (event, rowData) => handleClickOpenEnd(rowData),
                disabled:
                  rowData.isCompleted || polygonData.project.isCompleted,
              }),
              (rowData) => ({
                icon: () =>
                  rowData.isCompleted || polygonData.project.isCompleted ? (
                    <Icon style={{ color: "gray" }}>edit</Icon>
                  ) : (
                    <Icon style={{ color: "#f7a900" }}>edit</Icon>
                  ),
                tooltip:
                  rowData.isCompleted || polygonData.project.isCompleted ? (
                    <Typography>Ya concluido</Typography>
                  ) : (
                    <Typography>Editar proyecto</Typography>
                  ),
                disabled:
                  rowData.isCompleted || polygonData.project.isCompleted,
                onClick: (event, rowData) => handleClickOpen(rowData),
              }),

              (rowData) => ({
                icon: () =>
                  rowData.isCompleted || polygonData.project.isCompleted ? (
                    <Icon style={{ color: "#00A4D8" }}>remove_red_eye</Icon>
                  ) : (
                    <Icon style={{ color: "#00A4D8" }}>add_circle</Icon>
                  ),
                tooltip:
                  rowData.isCompleted || polygonData.project.isCompleted ? (
                    <Typography>Ver tareas</Typography>
                  ) : (
                    <Typography>Agregar tareas</Typography>
                  ),
                onClick: (event, rowData) => goToTask(rowData),
              }),
            ]}
            //Configuraciín de las etiquetas de la tabla
            options={{
              draggable: false,
              actionsColumnIndex: -1, //orientación de los botonotes de Acciones
              headerStyle: {
                fontWeight: "bold",
                backgroundColor: "#01579b",
                color: "#FFF",
                WebkitBorderTopRightRadius: 10,
              },
            }}
            localization={{
              header: {
                actions: "Acciones",
              },
              body: {
                emptyDataSourceMessage: "No hay registros aún",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Filtro",
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsPerPage: "Filas por página:",
                labelRowsSelect: "Filas",
                firstAriaLabel: "Primer página",
                firstTooltip: "Primer página",
                previousAriaLabel: "Anterior",
                previousTooltip: "Anterior",
                nextAriaLabel: "Siguiente",
                nextTooltip: "Siguiente",
                lastAriaLabel: "Última",
                lastTooltip: "Última",
              },
            }}
          />
        )}
      </Container>
      {/* Dialog de editar actividad */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="Editar actividad"
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          id="addActivity"
          onClose={handleClose}
          style={{ color: "white", backgroundColor: "#555454" }}
        >
          <Icon style={{ float: "left", marginTop: "4px", marginRight: "5px" }}>
            edit
          </Icon>
          Editar
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            Edita la información de la actividad seleccionada
          </DialogContentText>
          <TextField
            id="name"
            label="Nombre"
            color="primary"
            error={errorFlag}
            helperText={textError}
            fullWidth
            required
            name={"name"}
            value={activity && activity.name}
            onChange={handleChangeEdit}
            size="small"
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon style={{ color: "gray" }}>font_download</Icon>
                </InputAdornment>
              ),
            }}
          />
          <br />
          <br />
          <TextField
            id="description"
            label="Descripción"
            fullWidth
            color="primary"
            variant="filled"
            multiline
            name={"description"}
            error={errorFlagDescription}
            helperText={textErrorDescription}
            value={activity && activity.description}
            onChange={handleChangeEdit}
            size="small"
            rows={3}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon style={{ color: "gray" }}>chat</Icon>
                </InputAdornment>
              ),
            }}
          />
          <br />
          <br />
          <Grid container direction="row">
            {disabledDelete ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: 100 + "%",
                  height: 35,
                  paddingTop: 5,
                }}
              >
                <BeatLoader
                  color="#D1372D"
                  loading={disabledDelete}
                  size={20}
                />
              </div>
            ) : (
              <Button
                onClick={() => {
                  deleteActivity();
                }}
                startIcon={<HighlightOffIcon />}
                style={{
                  color: "white",
                  backgroundColor: "#D1372D",
                  width: 100 + "%",
                }}
              >
                Eliminar actividad
              </Button>
            )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ float: "right", margin: "auto" }}>
          {disabledEdit ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: 175,
                height: 35,
                paddingTop: 5,
              }}
            >
              <BeatLoader color="#38BC7C" loading={disabledEdit} size={20} />
            </div>
          ) : (
            <Button
              startIcon={<Save />}
              style={{ color: "white", backgroundColor: "#38BC7C" }}
              onClick={saveEdit}
            >
              Guardar Cambios
            </Button>
          )}
          <Button
            startIcon={<Close />}
            style={{ color: "white", backgroundColor: "gray" }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/**Concluir actividad */}
      <Dialog
        onClose={handleCloseEnd}
        aria-labelledby="Finalizar actividad"
        open={openEnd}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          id="endActivity"
          onClose={handleCloseEnd}
          style={{ color: "white", backgroundColor: "#555454" }}
        >
          <Icon style={{ float: "left", marginTop: "4px", marginRight: "5px" }}>
            check_circle
          </Icon>
          Concluir actividad
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Una vez que finalices la actividad no podrás cambiar su status
          </DialogContentText>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            ¿Estás seguro de finalizar la actividad{" "}
            <b style={{ color: "gray" }}>{activity && activity.name}</b>?
          </Typography>
        </DialogContent>
        <DialogActions style={{ float: "right", margin: "auto" }}>
          {disabledEnd ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: 175,
                height: 35,
                paddingTop: 5,
              }}
            >
              <BeatLoader color="#38BC7C" loading={disabledEnd} size={20} />
            </div>
          ) : (
            <Button
              startIcon={<CheckCircle />}
              style={{ color: "white", backgroundColor: "#38BC7C" }}
              onClick={endActivity}
            >
              Finalizar
            </Button>
          )}
          <Button
            startIcon={<Close />}
            style={{ color: "white", backgroundColor: "gray" }}
            onClick={handleCloseEnd}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <br />
    </>
  );
};

export default TableActivities;
