import React, { useContext } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
    ExpandMore, ExpandLess, Home, Assignment, AccountCircle, ExitToApp
} from "@material-ui/icons"
import {
    Grid, Collapse, List, ListItem, ListItemIcon, ListItemText, Divider, Typography
} from "@material-ui/core"
import { LoginContext } from "../../security/LoginProvider";
import { types } from '../../security/LoginReducer';
const useStyles = makeStyles((theme) => ({
    options: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: '#555454',
        color: 'FFFFFF'
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 2),
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
        background: "#FF9A24",
    },
    nested: {
        paddingLeft: theme.spacing(11),
    },
    active: {
        background: "#868585",
        paddingLeft: theme.spacing(11),
    },
    singleNested: {

    },
    singleActive: {
        background: "#868585",
    }
}));

const EmployeeOptions = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [state, dispatch] = useContext(LoginContext);
    const [taskDesp, setTaskDesp] = React.useState(false);
    const [dataEmployee, setDataEmployee] = React.useState(JSON.parse(localStorage.getItem('profile')));
    const handleClick = () => {
        setTaskDesp(!taskDesp);
    };

    return (
        <>
            {/* Lista de opciones */}
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.options}
                style={{ color: "white" }}
            >
                <div className={classes.drawerHeader}>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                        <AccountCircle style={{ color: "white", width: 32, height: 32 }} />
                    </Grid>
                    <Grid container style={{ marginLeft: -200 }} direction="row" justify="center" alignItems="flex-start">
                        <Typography style={{ color: "white", marginLeft: 5, fontSize: 13 }} variant="caption"><b>{dataEmployee?.lastName.toUpperCase() + " " + dataEmployee?.secondName.toUpperCase() + " " + dataEmployee?.name.toUpperCase()}</b></Typography>
                        <Typography style={{ color: "white", marginLeft: 5, fontSize: 13 }} variant="caption">{dataEmployee?.user.isAdmin ? "ADMINISTRADOR" : "EMPLEADO"}</Typography>
                    </Grid>
                </div>

                {/* Opcion unica */}
                <ListItem className={location.pathname == "/homeEmployee/startScreenEmployee" ? classes.singleActive : classes.sigleNested} button onClick={() => {
                    history.push("/homeEmployee/startScreenEmployee")
                }}>
                    <ListItemIcon>
                        <Home style={{ color: "white", width: 32, height: 32 }} />
                    </ListItemIcon>
                    <ListItemText primary="Inicio" />
                </ListItem>

                <Divider />
                {/* Opcion con desplegable */}
                <ListItem button onClick={handleClick}>
                    <ListItemIcon>
                        <Assignment style={{ color: "white", width: 32, height: 32 }} />
                    </ListItemIcon>
                    <ListItemText primary="Tareas" />
                    {taskDesp ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                {/* Desplegables de una opcion */}
                <Collapse in={taskDesp} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem className={location.pathname == "/homeEmployee/projects" ? classes.active : classes.nested} button onClick={() => {
                            history.push("/homeEmployee/projects");
                        }}>
                            <ListItemText primary="Subir Avances" />
                        </ListItem>
                    </List>
                </Collapse>
                <Divider />
                <ListItem className={location.pathname == "/homeEmployee/profile" ? classes.singleActive : classes.sigleNested} button onClick={() => {
                    history.push("/homeEmployee/profile");
                }}>
                    <ListItemIcon>
                        <AccountCircle style={{ color: "white", width: 32, height: 32 }} />
                    </ListItemIcon>
                    <ListItemText primary="Perfil de usuario" />
                </ListItem>
                <ListItem button onClick={() => {
                    localStorage.clear();
                    dispatch({ type: types.authLogout });
                    history.push("/")
                }}>
                    <ListItemIcon>
                        <ExitToApp style={{ color: "white", width: 32, height: 32 }} />
                    </ListItemIcon>
                    <ListItemText primary="Cerrar sesión" />
                </ListItem>
                <Divider />
            </List>
        </>
    )
};

export default EmployeeOptions;