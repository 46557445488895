import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import {
    Container, Grid, Typography, Icon, MenuItem, Paper,
    AppBar, Toolbar, Dialog, IconButton, Button, Slide, Card, Select
} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import { Save } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import API from "../../../utilities/api"
import Toast from "../../../utilities/Notifications"
import BeatLoader from "react-spinners/BeatLoader";
const useStyles = makeStyles((theme) => ({
    board: {
        textAlign: "center",
        borderRadius: 20,
    },
    appBar: {
        position: 'relative',
        backgroundColor: "#555454",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        margin: 'auto',
        backgroundColor: "#01579b"
    },
    paper: {
        width: 560,
        height: 400,
        overflow: 'auto',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,

    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
    toolbar: theme.mixins.toolbar,
    colHeader: {
        color: "white",
        "&:hover": {
            color: "white",
        },
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Loading = () => {
    return (
        <>
            <Skeleton variant="text" width={100 + '%'} height={150} animation="wave" style={{ borderRadius: 20 }} />
            <Skeleton variant="rect" width={100 + '%'} height={320} animation="wave" style={{ borderRadius: 20 }} />
        </>
    )
}

const AddLabors = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [existActiveLabor, setExistActiveLabor] = React.useState(false);
    const [uniqueLabor, setUniqueLabor] = React.useState(-1);
    const [levelLabor, setLevelLabor] = React.useState(-1);
    const [httpLabors, setHttpLabors] = React.useState([{}])
    const [httpData, setHttpData] = React.useState([]);
    const [httpSendLabors, setHttpSendLabors] = React.useState([])
    const [labors, setLabors] = React.useState({
        user: {
            id: '',
        },
    })

    let objLabors = {
        name: '',
        user: {
            id: labors.user.id
        },
        labor: {
            id: uniqueLabor
        },
        status: true,
        level: levelLabor
    }


    const handleChange = (event) => {
        setUniqueLabor(event.target.value);
    };

    const handleChangeLevel = (event) => {
        setLevelLabor(event.target.value);
    };
    const handleDelete = (id) => {
        const newList = httpSendLabors.filter((item) => item.labor.id !== id);
        setHttpSendLabors(newList);
    }

    const handleClickOpen = (ed) => {
        labors.user.id = ed
        setLabors({ ...labors, user: { id: ed } })
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setLevelLabor(-1);
        setUniqueLabor(-1);
        setHttpSendLabors([]);
    };
    const httpGetEmployee = async () => {
        await API.get("/profile/without/labor").then((response) => {
            let newArrayEmployee = [];
            response.data.map((item, i) => {
                if (!item.user.isAdmin) {
                    newArrayEmployee.push(item);
                }
            });
            setHttpData(newArrayEmployee)
            if (newArrayEmployee.length === 0) {
                Toast.InfoMessageLess("Información", "Al parecer todos tus empleados tienen roles.");
            }
        }).catch((e) => {
            console.log(e);
            Toast.ErrorMessage("Algo ocurrió en el servidor", "No se logró obtener información de los empleados.");
        })
    };

    const httpGetLabors = async () => {
        await API.get("/labor/").then((response) => {
            var filtrados = response.data.filter((item) => {
                return item.status;
            });
            if (filtrados.length == 0) {
                setExistActiveLabor(true);
                Toast.InfoMessage(
                    "Información",
                    "No hay roles activos.",
                    "bottom-right"
                )
            } else {
                setHttpLabors(filtrados);
                setExistActiveLabor(false);
            }
        }).catch((e) => {
            console.log(e);
            Toast.ErrorMessage("Algo ocurrió en el servidor", "No se logró obtener información de los roles.");
        })
    }

    const httpSendData = async () => {
        setLoading(true)
        if (httpSendLabors.length === 0) {
            setLoading(false)
            Toast.WarningMessage("¡Atención!", "Debes agregar al menos un rol al empleado.")
        } else {
            await API.post("/user-labor/multisave", httpSendLabors).then((response) => {
                Toast.SuccessMessage("¡Inserción Realizada!", "Se han guardado los datos correctamente.")
                setLoading(false)
                handleClose();
                httpGetEmployee();
            }).catch((e) => {
                setLoading(false)
                console.log(e);
                Toast.ErrorMessage("Algo ocurrió en el servidor", "No se logró obtener información de los roles.");
            })
        }
    }

    useEffect(() => {
        httpGetEmployee();
        httpGetLabors();
    }, []);

    return (
        <>
            <Container>
                <Paper elevation={1} style={{ borderRadius: 10, borderLeft: '8px solid #01579b' }}>
                    <Grid
                        style={{ marginBottom: 20, marginTop: 10 }}
                        container
                        spacing={3}
                        justify="space-between"
                    >
                        <Grid item>
                            <Typography variant="h5" style={{ marginLeft: 20 }}>
                                <b>Asignación de roles y niveles</b>
                            </Typography>
                            <Grid item>
                                <Typography style={{ color: "#9B9A9A", marginTop: 10, marginLeft: 20, }} variant="subtitle1">
                                    Listado de empleados sin rol y nivel
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                {httpData.length === 0 ? (<Loading />) : (
                    <MaterialTable
                        style={{ borderRadius: 20, padding: 10 }}
                        columns={[
                            {
                                title: (
                                    <Typography className={classes.colHeader} variant="subtitle2">
                                        Nombre(s)
                                    </Typography>
                                ),
                                field: "name",
                                headerStyle: {
                                    WebkitBorderTopLeftRadius: 10,
                                    WebkitBorderTopRightRadius: 0,
                                },
                            },
                            {
                                title: (
                                    <Typography className={classes.colHeader} variant="subtitle2">
                                        Primer apellido
                                    </Typography>
                                ),
                                field: "lastName",
                                headerStyle: {
                                    WebkitBorderTopRightRadius: 0,
                                },
                            },
                            {
                                title: (
                                    <Typography className={classes.colHeader} variant="subtitle2">
                                        Cargo
                                    </Typography>
                                ),
                                field: "user.isAdmin",
                                render: (rowData) =>
                                    rowData.user.isAdmin ? (<Typography>Administrador</Typography>) : (<Typography>Empleado</Typography>),
                                headerStyle: {
                                    WebkitBorderTopRightRadius: 0,
                                },
                            },
                            {
                                title: (
                                    <Typography className={classes.colHeader} variant="subtitle2">
                                        E-mail
                                    </Typography>
                                ),
                                field: "user.email",
                                headerStyle: {
                                    WebkitBorderTopRightRadius: 0,
                                },
                            },
                            {
                                title: (
                                    <Typography className={classes.colHeader} variant="subtitle2">
                                        Estatus
                                    </Typography>
                                ),
                                field: "user.isActive",
                                render: (rowData) =>
                                    rowData.user.isActive ? (<Typography>Activo</Typography>) : (<Typography>Inactivo</Typography>),
                                headerStyle: {
                                    WebkitBorderTopRightRadius: 0,
                                },
                            },
                        ]}
                        data={httpData}
                        title=""
                        //Configuraciín de las etiquetas de la tabla
                        actions={[
                            //Botones de la tabla
                            (rowData) => ({
                                icon: () =>
                                    existActiveLabor ? (
                                        <Icon style={{ color: "gray" }}>add_circle</Icon>
                                    ) : (
                                        <Icon style={{ color: "#38BC7C" }}>add_circle</Icon>
                                    ),
                                tooltip:
                                    existActiveLabor ? (
                                        <Typography>No hay roles activos</Typography>
                                    ) : (
                                        <Typography>Añadir rol</Typography>
                                    ),
                                onClick: (event, rowData) => handleClickOpen(rowData.user.id),
                                disabled: existActiveLabor
                            })
                        ]}
                        //Configuraciín de las etiquetas de la tabla
                        options={{
                            draggable: false,
                            actionsColumnIndex: -1, //orientación de los botonotes de Acciones
                            headerStyle: {
                                fontWeight: "bold",
                                backgroundColor: "#01579b",
                                color: "#FFF",
                                WebkitBorderTopRightRadius: 10,
                            },
                        }}
                        localization={{
                            header: {
                                actions: "Acciones",
                            },
                            body: {
                                emptyDataSourceMessage: "No hay registros aún",
                            },
                            toolbar: {
                                searchTooltip: "Buscar",
                                searchPlaceholder: "Filtro",
                            },
                            pagination: {
                                labelDisplayedRows: "{from}-{to} de {count}",
                                labelRowsPerPage: "Filas por página:",
                                labelRowsSelect: "Filas",
                                firstAriaLabel: "Primer página",
                                firstTooltip: "Primer página",
                                previousAriaLabel: "Anterior",
                                previousTooltip: "Anterior",
                                nextAriaLabel: "Siguiente",
                                nextTooltip: "Siguiente",
                                lastAriaLabel: "Última",
                                lastTooltip: "Última",
                            },
                        }}
                    />
                )}

            </Container>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Cerrar sin guardar
                        </Typography>

                        {loading ? (
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                width: 100,
                                marginLeft: 20,
                                marginRight: 10,
                                marginTop: 10
                            }}>
                                <BeatLoader color="#38BC7C" loading={loading} size={19} margin={4} />
                            </div>
                        ) : (
                            <Button autoFocus color="inherit" style={{ backgroundColor: "#38BC7C" }} onClick={() => {
                                httpSendData();
                            }}>
                                <Save style={{ marginRight: 10 }} />
                                Guardar Asignación
                            </Button>
                        )}
                    </Toolbar>
                </AppBar>
                <div className={classes.toolbar} />
                <Container>
                    <Card elevation={1} style={{ borderRadius: 20 }}>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Typography
                                style={{ marginTop: 15 }}
                                variant="h6"
                            >
                                Sólo se considerarán los roles del sistema
                            </Typography>
                            <Typography style={{ color: "#9B9A9A", marginBottom: 15 }} variant="subtitle1">
                                <b>Selecciona el rol y el nivel</b>
                            </Typography>
                            <Grid container direction="row" justify="center" alignItems="center ">
                                <Select
                                    style={{ width: 20 + "%", marginBottom: 20, marginLeft: 20 }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={uniqueLabor}
                                    onChange={handleChange}
                                >
                                    {

                                        httpLabors.map(item => (
                                            item.status ? (
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                            ) : (<></>)
                                        ))
                                    }
                                </Select>
                                <Select
                                    style={{ width: 20 + "%", marginBottom: 20, marginLeft: 20 }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={levelLabor}
                                    onChange={handleChangeLevel}
                                >
                                    <MenuItem value={3}>Nivel 3</MenuItem>
                                    <MenuItem value={2}>Nivel 2</MenuItem>
                                    <MenuItem value={1}>Nivel 1</MenuItem>
                                    <MenuItem value={0}>Nivel 0</MenuItem>
                                </Select>
                            </Grid>
                            <Button variant="outlined" style={{
                                color: "white",
                                backgroundColor: "#38BC7C",
                                marginBottom: 20
                            }} onClick={() => {
                                if (levelLabor === -1 || uniqueLabor === -1) {
                                    Toast.InfoMessage("Sugerencia", "Debes seleccionar un rol y un nivel")
                                } else {
                                    let flag = false;
                                    httpLabors.map((item) => {
                                        if (item.id == uniqueLabor) {
                                            objLabors.name = item.name;
                                        }
                                    })
                                    if (httpSendLabors.length === 0) {
                                        setHttpSendLabors(prevArray => [...prevArray, objLabors])
                                    } else if (httpSendLabors.length > 0) {
                                        for (let i = 0; i < httpSendLabors.length; i++) {
                                            if (httpSendLabors[i].labor.id === objLabors.labor.id) {
                                                Toast.WarningMessage("¡Atención!", "El rol seleccionado ya está en la tabla.")
                                                flag = true;
                                                break;
                                            }
                                        }
                                        if (flag) {
                                        } else {
                                            setHttpSendLabors(prevArray => [...prevArray, objLabors])
                                            flag = false
                                        }
                                    }
                                }
                            }}>
                                Añadir la selección a la tabla
                            </Button>
                        </Grid>
                    </Card>
                    <br />
                    <MaterialTable
                        style={{ borderRadius: 20, padding: 10, marginTop: 20 }}
                        columns={[
                            {
                                title: "Rol",
                                field: "name",
                                headerStyle: {
                                    WebkitBorderTopLeftRadius: 10,
                                    WebkitBorderTopRightRadius: 0,
                                },
                            },
                            {
                                title: "Nivel (0-3)",
                                field: "level",
                                headerStyle: {
                                    WebkitBorderTopRightRadius: 0,
                                },
                            },
                        ]}
                        data={httpSendLabors}
                        actions={[
                            //Botones de la tabla
                            (rowData) => ({
                                icon: () => (
                                    <Icon style={{ color: "#38BC7C" }}>remove_circle</Icon>
                                ),
                                tooltip: <Typography>Quitar rol</Typography>,
                                onClick: (event, rowData) => handleDelete(rowData.labor.id)
                            }),
                        ]}
                        title="Roles del empleado"
                        //Configuraciín de las etiquetas de la tabla
                        options={{
                            draggable: false,
                            actionsColumnIndex: -1,
                            headerStyle: {
                                fontWeight: "bold",
                                backgroundColor: "#01579b",
                                color: "#FFF",
                                WebkitBorderTopRightRadius: 10,
                            },
                        }}
                        localization={{
                            header: {
                                actions: "Acciones",
                            },
                            body: {
                                emptyDataSourceMessage: "No hay roles asignados aún",
                            },
                            toolbar: {
                                searchTooltip: "Buscar",
                                searchPlaceholder: "Filtro",
                            },
                            pagination: {
                                labelDisplayedRows: "{from}-{to} de {count}",
                                labelRowsPerPage: "Filas por página:",
                                labelRowsSelect: "Filas",
                                firstAriaLabel: "Primer página",
                                firstTooltip: "Primer página",
                                previousAriaLabel: "Anterior",
                                previousTooltip: "Anterior",
                                nextAriaLabel: "Siguiente",
                                nextTooltip: "Siguiente",
                                lastAriaLabel: "Última",
                                lastTooltip: "Última",
                            },
                        }}
                    />
                </Container>
            </Dialog>
        </>
    )
}
export default AddLabors;