import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import {
  Typography, Icon, Button, Dialog, DialogActions, DialogContent,
  DialogTitle, TextField, InputAdornment, Container, Grid, MenuItem,
  InputLabel,  Select, FormControl, FormHelperText, Paper,
} from "@material-ui/core";
import { AddCircle, Save, Close, KeyboardBackspace } from "@material-ui/icons";
import API from "../../../utilities/api";
import { useParams, useHistory } from "react-router-dom";
import Toast from "../../../utilities/Notifications";
import Skeleton from "@material-ui/lab/Skeleton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { makeStyles } from "@material-ui/core/styles";
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles({
  colHeader: {
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
});

const LoadingHeader = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width={100 + "%"}
        height={100}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
    </>
  );
};

const Loading = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width={100 + "%"}
        height={150}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
      <Skeleton
        variant="rect"
        width={100 + "%"}
        height={320}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
    </>
  );
};

const TablePolygons = () => {
  const history = useHistory();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [projectData, setProjectData] = React.useState({
    id: 0,
    name: "",
    isStarted: false,
    isCompleted: false,
    createdAt: "",
    updatedAt: null,
    limitKm: 0.0,
    tentativeAt: "",
  });
  const [polygon, setPolygon] = React.useState({
    id: 0,
    project: { id: 0 },
    technology: { id: 0 },
    type: { id: 0 },
    city: { id: 0, state: { id: 0 } },
    name: "",
    createdAt: "",
    udpatedAt: "",
    kilometers: 0,
  });
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [technologyList, setTechnologyList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const { idProject } = useParams(); //{ idProject: 1 };
  const [selectCity, setSelectCity] = useState("");
  const [statesList, setStatesList] = useState([]);
  const [selectState, setSelectState] = useState("");
  const [openCity, setOpenCity] = useState(true);
  const [activeCityEdit, setActiveCityEdit] = useState(false);
  const [state, setState] = useState({});
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const [disabledDelete, setDisabledDelete] = useState(false);

  const [errorFlagName, setErrorFlagName] = useState(false);
  const [textErrorName, setTextErrorName] = useState("");
  const [errorFlagKm, setErrorFlagKm] = useState(false);
  const [textErrorKm, setTextErrorKm] = useState("");
  const [errorFlagTechnology, setErrorFlagTechnology] = useState(false);
  const [textErrorTechnology, setTextErrorTechnology] = useState("");
  const [errorFlagType, setErrorFlagType] = useState(false);
  const [textErrorType, setTextErrorType] = useState("");
  const [errorFlagCity, setErrorFlagCity] = useState(false);
  const [textErrorCity, setTextErrorCity] = useState("");

  const cleanErrors = () => {
    setErrorFlagName(false);
    setTextErrorName("");
    setErrorFlagKm(false);
    setTextErrorKm("");
    setErrorFlagTechnology(false);
    setTextErrorTechnology("");
    setErrorFlagType(false);
    setTextErrorType("");
    setErrorFlagCity(false);
    setTextErrorCity("");
  };

  const handleClickOpenAdd = () => {
    cleanErrors();
    const emptyPolygon = {
      id: 0,
      project: { id: 0 },
      technology: { id: 0, name: "Ninguno" },
      type: { id: 0, name: "Ninguno" },
      city: null,
      name: "",
      createdAt: "",
      udpatedAt: "",
      kilometers: "",
    };
    setPolygon(emptyPolygon);
    getStates();
    setOpenCity(true);
    getTechnology();
    getType();
    setOpenAdd(true);
    setDisabledSave(false);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
    setOpenCity(true);
  };
  const handleClickOpen = (polygonRowData) => {
    cleanErrors();
    setDisabledDelete(false);
    setDisabledEdit(false);
    setPolygon(polygonRowData);
    getTechnology();
    getType();
    setState(polygonRowData.city.state);
    getCity(polygonRowData.city.state);
    getStates();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenCity(true);
  };
  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setPolygon((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    setPolygon((prevState) => ({
      ...prevState,
      id: null,
      project: { id: idProject },
      [name]: value,
    }));
  };
  const handleChangeCity = (citySelected) => {
    setPolygon((prevState) => ({
      ...prevState,
      id: null,
      city: citySelected,
    }));
  };
  const handleChangeState = (stateSelected) => {
    setSelectCity("");
    setPolygon((prevState) => ({
      ...prevState,
      city: null,
    }));
    if (stateSelected == null || stateSelected.name == "") {
      setOpenCity(true);
    } else {
      getCity(stateSelected);
    }
  };
  const handleChangeCityEdit = (citySelected) => {
    setPolygon((prevState) => ({
      ...prevState,
      city: citySelected,
    }));
  };
  const handleChangeStateEdit = (stateSelected) => {
    setSelectCity("");
    setPolygon((prevState) => ({
      ...prevState,
      city: null,
    }));
    if (stateSelected == null || stateSelected.name == "") {
      setOpenCity(true);
    } else {
      getCity(stateSelected);
    }
  };

  const getProject = async () => {
    await API.get(`/project/${idProject}`)
      .then((response) => {
        setProjectData(response.data);
      })
      .catch((error) => {
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
        console.log(error);
      });
  };
  const getCity = async (state) => {
    await API.get(`city/state/${state.id}`)
      .then((response) => {
        setOpenCity(false);
        setCityList(response.data);
      })
      .catch((error) => {
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
        console.log(error);
      });
  };
  const getTechnology = async () => {
    await API.get("/technology/")
      .then((response) => {
        setTechnologyList(response.data);
      })
      .catch((error) => {
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
        console.log(error);
      });
  };
  const getType = async () => {
    await API.get("/type/")
      .then((response) => {
        setTypeList(response.data);
      })
      .catch((error) => {
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
        console.log(error);
      });
  };

  const deletePolygon = async () => {
    setDisabledDelete(true);
    setDisabledEdit(true);
    handleClose();
    await API.delete(`/polygon/${polygon.id}`)
      .then((response) => {
        if (response === undefined) {
          Toast.WarningMessage(
            "¡Atención!",
            "No se pueden eliminar polígonos con actividades."
          );
        } else {
          Toast.SuccessMessage(
            "¡Eliminación Realizada!",
            "Se eliminó el polígono correctamente."
          );
          peticionGet();
        }
      })
      .catch((e) => {
        console.log(e);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "No se logró eliminar el polígono"
        );
      });
  };

  const getStates = async () => {
    await API.get("/state/")
      .then((response) => {
        setStatesList(response.data);
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
      });
  };

  const peticionGet = async () => {
    await API.get(`/polygon/project/${idProject}`)
      .then((response) => {
        setData(response.data);
        if (response.data.length === 0) {
          Toast.InfoMessage(
            "Información",
            "Aún no hay polígonos en el proyecto.",
            "bottom-right"
          );
        }
      })
      .catch((error) => {
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
        console.log(error);
      });
  };

  const goToActivities = (polygonRowData) => {
    history.push(`/homeAdministrator/projects/activities/${polygonRowData.id}`);
  };

  const validar = (tipo) => {
    switch (tipo) {
      case "nuevo": {
        if (polygon.name.trim() === "" || polygon.name == null) {
          setErrorFlagName(true);
          setTextErrorName("Campo requerido");
        } else if (!polygon.name.match(/^[[Ññíóáéú/_0-9 a-zA-Z\-]+$/)) {
          setErrorFlagName(true);
          setTextErrorName(
            "Sólo se permiten letras, números, guiones y diagonal"
          );
        } else if (polygon.kilometers == "" || polygon.kilometers == null) {
          setErrorFlagKm(true);
          setTextErrorKm("Campo requerido");
        } else if (polygon.kilometers <= 0) {
          setErrorFlagKm(true);
          setTextErrorKm("No se admiten valores menores o igual a 0");
        } else if (polygon.technology.name === "Ninguno") {
          setErrorFlagTechnology(true);
          setTextErrorTechnology("Selecciona una tecnología válida");
        } else if (polygon.type.name === "Ninguno") {
          setErrorFlagType(true);
          setTextErrorType("Selecciona un tipo válido");
        } else if (polygon.city == null || selectCity === "") {
          setErrorFlagCity(true);
          setTextErrorCity("Selecciona una ciudad válida");
        } else {
          return false;
        }
        return true;
        break;
      }
      case "editar": {
        if (polygon.name.trim() === "" || polygon.name == null) {
          setErrorFlagName(true);
          setTextErrorName("Campo requerido");
        } else if (!polygon.name.match(/^[[Ññíóáéú/_0-9 a-zA-Z\-]+$/)) {
          setErrorFlagName(true);
          setTextErrorName(
            "Sólo se permiten letras, números, guiones y diagonal"
          );
        } else if (polygon.city == null) {
          setErrorFlagCity(true);
          setTextErrorCity("Selecciona una ciudad válida");
        } else {
          return false;
        }
        return true;
      }
    }
  };

  const saveNewPolygon = async () => {
    setDisabledSave(true);
    cleanErrors();
    if (validar("nuevo")) {
      setDisabledSave(false);
    } else {
      await API.post("/polygon/", polygon)
        .then((response) => {
          peticionGet();
          Toast.SuccessMessage(
            "¡Registro realizado!",
            "Datos guardados correctamente"
          );
          setOpenAdd(false);
        })
        .catch((error) => {
          setOpenAdd(false);
          console.log(error);
          Toast.ErrorMessage(
            "¡Ha ocurrido un error!",
            "Los sentimos, no se pudo completar el envío"
          );
        });
    }
  };

  const saveEdit = async () => {
    setDisabledEdit(true);
    setDisabledDelete(true);
    cleanErrors();
    if (validar("editar")) {
      setDisabledEdit(false);
      setDisabledDelete(false);
    } else {
      await API.put("/polygon/", polygon)
        .then((response) => {
          Toast.SuccessMessage(
            "¡Modificación realizada!",
            "Los datos han sido modificados correctamente"
          );
          peticionGet();
          setOpen(false);
        })
        .catch((error) => {
          setOpen(false);
          console.log(error);
          Toast.ErrorMessage(
            "¡Ha ocurrido un error!",
            "Los sentimos, no se pudo completar el envío"
          );
        });
    }
  };

  useEffect(() => {
    peticionGet();
    getProject();
  }, []);

  return (
    <>
      {/* Componente del botón y dialog de agregar */}

      <Container>
        {projectData.name == "" ? (
          <LoadingHeader />
        ) : (
          <Paper
            elevation={1}
            style={{ borderRadius: 10, borderLeft: "8px solid #38BC7C" }}
          >
            <Grid
              style={{ marginBottom: 20, marginTop: 10 }}
              container
              spacing={3}
              justify="space-between"
            >
              <Grid item>
                <Typography variant="h5" style={{ marginLeft: 20 }}>
                  <b>Gestiona polígonos para el proyecto: {projectData.name}</b>
                </Typography>
                <Grid item>
                  <Typography
                    style={{ color: "#9B9A9A", marginTop: 10, marginLeft: 20 }}
                    variant="subtitle1"
                  >
                    Listado de polígonos existentes
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                {projectData.isCompleted ? (
                  <Button
                    startIcon={<AddCircle />}
                    fullWidth
                    style={{
                      color: "white",
                      backgroundColor: "gray",
                      width: 150,
                      height: 35,
                      marginLeft: 20,
                      marginRight: 10,
                    }}
                    disabled
                  >
                    Nuevo
                  </Button>
                ) : (
                  <Button
                    startIcon={<AddCircle />}
                    fullWidth
                    style={{
                      color: "white",
                      backgroundColor: "#38BC7C",
                      width: 150,
                      height: 35,
                      marginLeft: 20,
                      marginRight: 10,
                    }}
                    onClick={handleClickOpenAdd}
                  >
                    Nuevo
                  </Button>
                )}
                <Button
                  startIcon={<KeyboardBackspace />}
                  fullWidth
                  style={{
                    marginLeft: 10,
                    color: "white",
                    backgroundColor: "#f7a900",
                    width: 150,
                    height: 35,
                    marginRight: 20,
                  }}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Regresar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Container>

      {/**Añadir polígono */}
      <Dialog
        onClose={handleCloseAdd}
        aria-labelledby="Añadir actividad"
        open={openAdd}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          id="addActivity"
          onClose={handleCloseAdd}
          style={{ color: "white", backgroundColor: "#555454" }}
        >
          <Icon style={{ float: "left", marginTop: "4px", marginRight: "5px" }}>
            add_circle
          </Icon>
          Agregar
        </DialogTitle>

        <DialogContent dividers>
          <TextField
            id="name"
            label="Nombre"
            required
            color="primary"
            fullWidth
            error={errorFlagName}
            helperText={textErrorName}
            name={"name"}
            value={polygon && polygon.name}
            onChange={handleChangeAdd}
            size="small"
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon style={{ color: "gray" }}>font_download</Icon>
                </InputAdornment>
              ),
            }}
          />
          <br />
          <br />
          <FormControl
            error={errorFlagTechnology}
            required
            size="small"
            variant="filled"
            fullWidth
          >
            <InputLabel id="technology">Tecnología</InputLabel>
            <Select
              id="technology"
              labelId="technology"
              color="primary"
              name={"technology"}
              value={polygon && polygon.technology}
              onChange={handleChangeAdd}
              startAdornment={
                <InputAdornment position="start">
                  <Icon style={{ color: "gray" }}>timeline</Icon>
                </InputAdornment>
              }
            >
              <MenuItem value={polygon.technology}>
                <em>{polygon.technology.name}</em>
              </MenuItem>
              {technologyList.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{textErrorTechnology}</FormHelperText>
          </FormControl>
          <br />
          <br />
          <TextField
            id="km"
            required
            type="number"
            label="Kilómetros"
            color="primary"
            fullWidth
            error={errorFlagKm}
            helperText={textErrorKm}
            size="small"
            name={"kilometers"}
            inputProps={{ min: 0 }}
            value={polygon && polygon.kilometers}
            onChange={handleChangeAdd}
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon style={{ color: "gray" }}>map</Icon>
                </InputAdornment>
              ),
            }}
          />
          <br />
          <br />
          <FormControl
            error={errorFlagType}
            size="small"
            required
            variant="filled"
            fullWidth
          >
            <InputLabel id="type">Tipo</InputLabel>
            <Select
              id="type"
              labelId="type"
              color="primary"
              name={"type"}
              value={polygon && polygon.type}
              onChange={handleChangeAdd}
              startAdornment={
                <InputAdornment position="start">
                  <Icon style={{ color: "gray" }}>call_merge</Icon>
                </InputAdornment>
              }
            >
              <MenuItem value={polygon.type}>
                <em>{polygon.type.name}</em>
              </MenuItem>
              {typeList.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{textErrorType}</FormHelperText>
          </FormControl>
          <br />
          <br />
          <Autocomplete
            fullWidth
            size="small"
            options={statesList}
            getOptionLabel={(option) => option.name}
            inputValue={selectState}
            getOptionSelected={(option, value) => option.id === value.id}
            onInputChange={(event, newInputValue) => {
              setSelectState(newInputValue);
              setSelectCity("");
              setPolygon((prevState) => ({
                ...prevState,
                city: null,
              }));
            }}
            onChange={(event, newValue) => {
              setOpenCity(false);
              handleChangeState(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Estado" variant="filled" />
            )}
          />
          <br />
          <Autocomplete
            fullWidth
            value={polygon.city}
            size="small"
            disabled={openCity}
            noOptionsText="No hay ciudades en ese estado aún"
            options={cityList}
            inputValue={selectCity}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(event, newValue) => {
              handleChangeCity(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setSelectCity(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                required
                error={errorFlagCity}
                helperText={textErrorCity}
                {...params}
                label="Ciudad"
                variant="filled"
              />
            )}
          />
        </DialogContent>
        <DialogActions style={{ float: "right", margin: "auto" }}>
          {disabledSave ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: 108,
                height: 35,
                paddingTop: 5,
              }}
            >
              <BeatLoader color="#38BC7C" loading={disabledSave} size={20} />
            </div>
          ) : (
            <Button
              startIcon={<Save />}
              style={{ color: "white", backgroundColor: "#38BC7C" }}
              onClick={saveNewPolygon}
            >
              Guardar
            </Button>
          )}
          <Button
            startIcon={<Close />}
            style={{ color: "white", backgroundColor: "gray" }}
            onClick={handleCloseAdd}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Container>
        {data.length === 0 ? (
          <Loading />
        ) : (
          <MaterialTable
            style={{ borderRadius: 10, padding: 10 }}
            columns={[
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Nombre
                  </Typography>
                ),
                field: "name",
                headerStyle: {
                  WebkitBorderTopLeftRadius: 10,
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Kilómetros
                  </Typography>
                ),
                field: "kilometers",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Tecnología
                  </Typography>
                ),
                field: "technology.name",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Tipo
                  </Typography>
                ),
                field: "type.name",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Ciudad
                  </Typography>
                ),
                field: "city.name",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Estado
                  </Typography>
                ),
                field: "city.state.name",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
            ]}
            data={data}
            title=""
            actions={[
              //Botones de la tabla
              (rowData) => ({
                icon: () =>
                  projectData.isCompleted === false ? (
                    <Icon style={{ color: "#f7a900" }}>edit</Icon>
                  ) : (
                    <Icon style={{ color: "gray" }}>edit</Icon>
                  ),
                tooltip:
                  projectData.isCompleted === false ? (
                    <Typography>Editar polígono</Typography>
                  ) : (
                    <Typography>Proyecto concluido</Typography>
                  ),
                onClick: (event, rowData) => handleClickOpen(rowData),
                disabled: projectData.isCompleted,
              }),

              (rowData) => ({
                icon: () =>
                  projectData.isCompleted === false ? (
                    <Icon style={{ color: "#00A4D8" }}>add_circle</Icon>
                  ) : (
                    <Icon style={{ color: "#00A4D8" }}>remove_red_eye</Icon>
                  ),
                tooltip:
                  projectData.isCompleted === false ? (
                    <Typography>Agregar actividades</Typography>
                  ) : (
                    <Typography>Ver actividades</Typography>
                  ),
                onClick: (event, rowData) => goToActivities(rowData),
              }),
            ]}
            //Configuraciín de las etiquetas de la tabla
            options={{
              draggable: false,
              actionsColumnIndex: -1, //orientación de los botonotes de Acciones
              headerStyle: {
                fontWeight: "bold",
                backgroundColor: "#01579b",
                color: "#FFF",
                WebkitBorderTopRightRadius: 10,
              },
            }}
            localization={{
              header: {
                actions: "Acciones",
              },
              body: {
                emptyDataSourceMessage: "No hay registros aún",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Filtro",
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsPerPage: "Filas por página:",
                labelRowsSelect: "Filas",
                firstAriaLabel: "Primer página",
                firstTooltip: "Primer página",
                previousAriaLabel: "Anterior",
                previousTooltip: "Anterior",
                nextAriaLabel: "Siguiente",
                nextTooltip: "Siguiente",
                lastAriaLabel: "Última",
                lastTooltip: "Última",
              },
            }}
          />
        )}
      </Container>

      {/* Dialog de editar polígono */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="Editar actividad"
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          id="addActivity"
          onClose={handleClose}
          style={{ color: "white", backgroundColor: "#555454" }}
        >
          <Icon style={{ float: "left", marginTop: "4px", marginRight: "5px" }}>
            edit
          </Icon>
          Editar
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            id="name"
            label="Nombre"
            color="primary"
            fullWidth
            required
            name={"name"}
            value={polygon && polygon.name}
            onChange={handleChangeEdit}
            size="small"
            error={errorFlagName}
            helperText={textErrorName}
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon style={{ color: "gray" }}>font_download</Icon>
                </InputAdornment>
              ),
            }}
          />
          <br />
          <br />
          <FormControl required size="small" variant="filled" fullWidth>
            <InputLabel id="technology">Tecnología</InputLabel>
            <Select
              id="technology"
              labelId="technology"
              color="primary"
              name={"technology"}
              value={polygon && polygon.technology}
              onChange={handleChangeEdit}
              startAdornment={
                <InputAdornment position="start">
                  <Icon style={{ color: "gray" }}>timeline</Icon>
                </InputAdornment>
              }
            >
              <MenuItem value={polygon.technology}>
                {polygon.technology.name}
              </MenuItem>
              {technologyList.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <br />
          <FormControl required size="small" variant="filled" fullWidth>
            <InputLabel id="type">Tipo</InputLabel>
            <Select
              id="type"
              labelId="type"
              color="primary"
              name={"type"}
              value={polygon && polygon.type}
              onChange={handleChangeEdit}
              startAdornment={
                <InputAdornment position="start">
                  <Icon style={{ color: "gray" }}>call_merge</Icon>
                </InputAdornment>
              }
            >
              <MenuItem value={polygon.type}>{polygon.type.name}</MenuItem>
              {typeList.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <br />
          <Autocomplete
            fullWidth
            value={state}
            size="small"
            options={statesList}
            disableClearable={true}
            getOptionLabel={(option) => option.name}
            inputValue={selectState}
            getOptionSelected={(option, value) => option.id === value.id}
            onInputChange={(event, newInputValue) => {
              setSelectState(newInputValue);
            }}
            onChange={(event, newValue) => {
              setOpenCity(false);
              handleChangeStateEdit(newValue);
              setState(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Estado" variant="filled" />
            )}
          />
          <br />
          <Autocomplete
            fullWidth
            size="small"
            disabled={activeCityEdit}
            value={polygon.city}
            options={cityList}
            inputValue={selectCity}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(event, newValue) => {
              handleChangeCityEdit(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setSelectCity(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                required
                error={errorFlagCity}
                helperText={textErrorCity}
                {...params}
                label="Ciudad"
                variant="filled"
              />
            )}
          />
          <br />
          <Grid container direction="row">
            {disabledDelete ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: 100 + "%",
                  height: 35,
                  paddingTop: 5,
                }}
              >
                <BeatLoader
                  color="#D1372D"
                  loading={disabledDelete}
                  size={20}
                />
              </div>
            ) : (
              <Button
                onClick={() => {
                  deletePolygon();
                }}
                startIcon={<HighlightOffIcon />}
                style={{
                  color: "white",
                  backgroundColor: "#D1372D",
                  width: 100 + "%",
                }}
              >
                Eliminar polígono
              </Button>
            )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ float: "right", margin: "auto" }}>
          {disabledEdit ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: 175,
                height: 35,
                paddingTop: 5,
              }}
            >
              <BeatLoader color="#38BC7C" loading={disabledEdit} size={20} />
            </div>
          ) : (
            <Button
              startIcon={<Save />}
              style={{ color: "white", backgroundColor: "#38BC7C" }}
              onClick={saveEdit}
            >
              Guardar Cambios
            </Button>
          )}

          <Button
            startIcon={<Close />}
            style={{ color: "white", backgroundColor: "gray" }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <br />
    </>
  );
};

export default TablePolygons;
