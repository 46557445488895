import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography, TextField, Container,
  Grid, Paper, MenuItem, InputLabel, Select, FormControl, Button,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import MaterialTable from "material-table";
import API from "../../../utilities/api"
import Toast from "../../../utilities/Notifications"
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
    },
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    minWidth: 250,
  },
  search: {
    margin: {
      margin: theme.spacing(1),
    },
  },
  colHeader: {
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
}));

const SearchOneEmployee = () => {
  const classes = useStyles();
  const [labors, setLabors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [mail, setMail] = React.useState({
    Email: ''
  });
  const [dataEmployee, setDataEmployee] = React.useState({
    id: 0,
    lastName: "",
    name: "",
    phoneNumber: "",
    secondName: "",
    user: {
      createdAt: "",
      email: "",
      id: '',
      isActive: true,
      isAdmin: false,
      password: null,
      resetPasswordToken: null,
      updatedAt: null,
    }

  });
  const handleEmail = (event) => {
    setMail({ ...mail, Email: event.target.value });
  };

  const httpGetDataEmployee = async () => {
    setLoading(true)
    await API.get(`/user/email/${mail.Email}`).then((response) => {
      if (response.data === null) {
        Toast.InfoMessage("Información", "No existe ningún usuario ligado al correo ingresado.")
        setLoading(false)
      } else {
        setLoading(false)
        setDataEmployee(response.data);
        httpGetLabors(response.data.user.id);
      }

    }).catch((e) => {
      setLoading(false)
      console.log(e);
      Toast.ErrorMessage("Algo ocurrió en el servidor", "No se logró obtener información de los empleados.");
    })
  }
  const httpGetLabors = async (ide) => {
    await API.get(`/user-labor/user/${ide}`).then((response) => {
      setLabors(response.data)
    }).catch((e) => {
      console.log(e);
      Toast.ErrorMessage("Algo ocurrió en el servidor", "No se logró obtener información de los roles.");

    })
  }
  return (
    <>
      <Container>
        <Paper elevation={1} style={{ borderRadius: 10, borderLeft: '8px solid #01579b' }}>
          <Grid
            style={{ marginBottom: 20, marginTop: 10 }}
            container
            spacing={3}
            justify="space-between"
          >
            <Grid item>
              <Typography variant="h5" style={{ marginLeft: 20 }}>
                <b>Información del empleado</b>
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                id="Email"
                label="Correo electrónico del empleado"
                color="primary"
                type="text"
                variant="outlined"
                size="small"
                value={mail.Email}
                onChange={handleEmail}
                style={{ minWidth: 300, backgroundColor: "white", marginLeft: 20, }}
              />
              {loading ? (
                <Button
                  disabled={true}
                  style={{
                    color: "white",
                    width: 100,
                    marginLeft: 20,
                    paddingTop: 12,
                    marginRight: 20,
                    height: 39,
                  }}
                >
                  <BeatLoader color="#00A4D8" loading={loading} size={17} margin={5} />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    httpGetDataEmployee()
                  }}
                  startIcon={<Search />}
                  style={{
                    color: "white",
                    backgroundColor: "#00A4D8",
                    width: 100,
                    marginLeft: 20,
                    marginRight: 20,
                    height: 39,
                  }}
                >
                  Buscar
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={1} style={{ borderRadius: 10, marginTop: 20 }}>
          <Grid className={classes.root}>
            <TextField
              id="name"
              className={classes.formControl}
              label="Nombre(s)"
              color="primary"
              disabled
              size="small"
              variant="filled"
              value={dataEmployee.name}
            />
            <TextField
              id="lastname"
              size="small"
              className={classes.formControl}
              label="Primer apellido"
              color="primary"
              disabled
              variant="filled"
              value={dataEmployee.lastName}
            />
            <TextField
              id="secondname"
              className={classes.formControl}
              label="Segundo apellido"
              color="primary"
              disabled
              variant="filled"
              size="small"
              value={dataEmployee.secondName}
            />
            <TextField
              id="phoneNumber"
              className={classes.formControl}
              label="Teléfono"
              color="primary"
              disabled
              size="small"
              variant="filled"
              type="number"
              value={dataEmployee.phoneNumber}
            />
            <TextField
              id="email"
              className={classes.formControl}
              label="E-mail"
              color="primary"
              disabled
              size="small"
              variant="filled"
              value={dataEmployee.user.email}
            />
            <FormControl
              size="small"
              variant="filled"
              className={classes.formControl}
            >
              <InputLabel id="isAdmin">Cargo</InputLabel>
              <Select
                id="isAdmin"
                labelId="isAdmin"
                color="primary"
                disabled
                value={dataEmployee.user.isAdmin}
              >
                <MenuItem value={false}>Empleado</MenuItem>
                <MenuItem value={true}>Administrador</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Paper>
        <MaterialTable
          style={{ borderRadius: 20, padding: 10, marginTop: 20 }}
          columns={[
            {
              title: (
                <Typography className={classes.colHeader} variant="subtitle2">
                  Rol
                </Typography>
              ),
              field: "labor.name",
              headerStyle: {
                WebkitBorderTopLeftRadius: 10,
                WebkitBorderTopRightRadius: 0,
              },
            },
            {
              title: (
                <Typography className={classes.colHeader} variant="subtitle2">
                  Descripción
                </Typography>
              ),
              field: "labor.description",
              headerStyle: {
                WebkitBorderTopRightRadius: 0,
              },
            },
            {
              title: (
                <Typography className={classes.colHeader} variant="subtitle2">
                  Nivel (0-3)
                </Typography>
              ),
              field: "level",
              headerStyle: {
                WebkitBorderTopRightRadius: 10,
              },
            },
          ]}
          data={labors}
          title="Roles del empleado"
          //Configuraciín de las etiquetas de la tabla
          options={{
            draggable: false,
            headerStyle: {
              fontWeight: "bold",
              backgroundColor: "#01579b",
              color: "#FFF",
            },
          }}
          localization={{
            body: {
              emptyDataSourceMessage: "No hay roles asignados aún",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Filtro",
            },
            pagination: {
              labelDisplayedRows: "{from}-{to} de {count}",
              labelRowsPerPage: "Filas por página:",
              labelRowsSelect: "Filas",
              firstAriaLabel: "Primer página",
              firstTooltip: "Primer página",
              previousAriaLabel: "Anterior",
              previousTooltip: "Anterior",
              nextAriaLabel: "Siguiente",
              nextTooltip: "Siguiente",
              lastAriaLabel: "Última",
              lastTooltip: "Última",
            },
          }}
        />
      </Container>
    </>
  );
};

export default SearchOneEmployee;
