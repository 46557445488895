import React, { useEffect } from "react";
import DateFormat from "../../../utilities/DateFormat"
import PrintIcon from '@material-ui/icons/Print';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from "../../../Images/LogoPDF.png";
import MaterialTable from "material-table";
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import { KeyboardBackspace } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import API from "../../../utilities/api"
import { Pie } from 'react-chartjs-2'
import Skeleton from "@material-ui/lab/Skeleton";
import Toast from "../../../utilities/Notifications"
import { useParams, useHistory } from "react-router-dom";
import { Place, AccountTree, EventNote, LocationCity } from "@material-ui/icons";
import {
  Grid, Typography, Icon, Dialog, DialogContent,
  Button, Paper, Avatar, List, ListItem, ListItemAvatar, ListItemText, AppBar, Toolbar,
  IconButton, Slide
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
    },
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    minWidth: 250,
  },
  search: {
    display: "flex",
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(3),
    alignItems: "center",
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  colHeader: {
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
}));

const columnsPDF = [
  {
    title: "Poligonos",
    field: 'name',
    headerStyle: {
      WebkitBorderTopLeftRadius: 10,
      WebkitBorderTopRightRadius: 0,
    },
  },

  {
    title: "Diseño",
    field: 'design',
    headerStyle: {
      WebkitBorderTopRightRadius: 0,
    },
    render: (rowData) =>
      rowData.design === null ? (
        0
      ) : (
        rowData.design
      ),
  },
  {
    title: "Digitalización",
    field: 'digitizer',
    headerStyle: {
      WebkitBorderTopRightRadius: 0,
    },
    render: (rowData) =>
      rowData.digitizer === null ? (
        0
      ) : (
        rowData.digitizer
      ),
  },
  {
    title: "Levantamiento",
    field: 'walkout',
    headerStyle: {
      WebkitBorderTopRightRadius: 0,
    },
    render: (rowData) =>
      rowData.walkout === null ? (
        0
      ) : (
        rowData.walkout
      ),
  },
  {
    title: "Documentación",
    field: 'documentation',
    headerStyle: {
      WebkitBorderTopRightRadius: 0,
    },
    render: (rowData) =>
      rowData.documentation === null ? (
        0
      ) : (
        rowData.documentation
      ),
  },
  {
    title: "Kilometros de enlace",
    field: "linkKm",
    headerStyle: {
      WebkitBorderTopRightRadius: 0,
    },
    render: (rowData) =>
      rowData.linkKm === null ? (
        0
      ) : (
        rowData.linkKm
      ),
  },
  {
    title: "Kilometros de zona",
    field: "networkKm",
    headerStyle: {
      WebkitBorderTopRightRadius: 0,
    },
    render: (rowData) =>
      rowData.networkKm === null ? (
        0
      ) : (
        rowData.networkKm
      ),
  },
  {
    title: "Total",
    field: "totalKm",
    headerStyle: {
      WebkitBorderTopRightRadius: 0,
    },
    render: (rowData) =>
      rowData.totalKm === null ? (
        0
      ) : (
        rowData.totalKm
      ),
  },
]



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Loading = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width={100 + "%"}
        height={150}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
      <Skeleton
        variant="rect"
        width={100 + "%"}
        height={320}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
    </>
  );
};

const TableIdividualReport = () => {
  const classes = useStyles();
  const { idProject } = useParams();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const downloadPdf = () => {
    const doc = new jsPDF()

    doc.setFontSize(40);
    doc.text("A D P R O", 75, 20, 'center')
    doc.addImage(Logo, 'JEPG', 15, 9, 23, 23)

    doc.setFontSize(12);
    doc.text('FIBERPROMEX S.A.S de C.V Administrador\rde Proyectos.', 42, 27);

    doc.setFontSize(10);
    doc.text('Domicilio Fiscal: Privada Xitle #343,\rColonia los volcanes. C.P.62350\rDelegación Cuernavaca, Mor.', 139, 13);

    doc.setFontSize(15);
    doc.text('Lista de Polígonos de un proyecto', 65, 45);

    doc.autoTable({
      margin: { top: 50 },
      theme: "striped",
      columns: columnsPDF.map(col => ({ ...col, dataKey: col.field })),
      body: reportList
    })
    doc.save('Reporte_Individual.pdf');
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [deltails, setDeltails] = React.useState({
    id: 0,
    createdAt: "",
    city: "",
    state: "",
    technology: "",
    networkKm: null,
    linkKm: null,
    totalKm: null,

  });

  const handleClickOpenDetails = (props) => {
    setDeltails(props);
    handleClickOpen();
  };
  const [projectData, setProjectData] = React.useState({
    id: 0,
    name: "",
  });

  const [reportList, setReportList] = React.useState([]);

  let kmZona = (deltails.networkKm)
  let kmLink = (deltails.linkKm)
  let kmTotal = (deltails.totalKm)


  const getProject = async () => {
    await API.get(`/project/${idProject}`)
      .then((response) => {
        setProjectData(response.data);
        if (response.data.length === 0) {
          Toast.InfoMessage(
            "Información",
            "Aún no hay proyectos en el sistema.",
          );
        }
      })
      .catch((error) => {
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
        console.log(error);
      });
  };

  const peticionIndividualReport = async () => {
    await API.get(`/polygon/${idProject}/report/`)
      .then((response) => {
        let upStatus = []
        response.data.map((project) => {
          project.design = project.design === null ? 0.00 : project.design.toFixed(2);
          project.digitizer = project.digitizer === null ? 0.00 : project.digitizer.toFixed(2);
          project.walkout = project.walkout === null ? 0.00 : project.walkout.toFixed(2);
          project.documentation = project.documentation === null ? 0.00 : project.documentation.toFixed(2);
          project.linkKm = project.linkKm === null ? 0.00 : project.linkKm.toFixed(2);
          project.networkKm = project.networkKm === null ? 0.00 : project.networkKm.toFixed(2);
          project.totalKm = project.totalKm === null ? 0.00 : project.totalKm.toFixed(2);
          upStatus.push(project)
        })
        setReportList(upStatus);
        if (response.data.length === 0) {
          Toast.InfoMessage(
            "Información",
            "Aún no hay poligonos en el sistema.",
          );
        }
      })
      .catch((error) => {
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
        console.log(error);
      });
  };

  useEffect(() => {
    peticionIndividualReport();
    getProject();
  }, []);

  return (
    <>
      <Container>
        <Paper elevation={1} style={{ borderRadius: 10, borderLeft: '8px solid #01579b' }}>
          <Grid
            style={{ marginBottom: 20, marginTop: 10 }}
            container
            spacing={3}
            justify="space-between"
          >
            <Grid item>
              <Typography variant="h5" style={{ marginLeft: 20 }}>
                <b>
                  Proyecto individual de:{" "}
                  {projectData.name}
                </b>
              </Typography>
              <Grid item>
                <Typography style={{ color: "#9B9A9A", marginTop: 10, marginLeft: 20, }} variant="subtitle1">
                  Listado de asignaciones
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                startIcon={<KeyboardBackspace />}
                fullWidth
                style={{
                  marginLeft: 10,
                  color: "white",
                  backgroundColor: "#f7a900",
                  width: 150,
                  height: 35,
                  marginRight: 20,
                }}
                onClick={() => {
                  history.goBack();
                }}
              >
                Regresar
              </Button>
            </Grid>
          </Grid>
        </Paper>
        {reportList.length === 0 ? (
          <Loading />
        ) : (
          <MaterialTable
            style={{ borderRadius: 20, padding: 10 }}
            columns={[
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                   Polígonos
                  </Typography>
                ),
                field: 'name',
                headerStyle: {
                  WebkitBorderTopLeftRadius: 10,
                  WebkitBorderTopRightRadius: 0,
                },
              },

              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Diseño
                  </Typography>
                ),
                field: 'design',
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
                render: (rowData) =>
                  rowData.design === null ? (
                    0.0
                  ) : (
                    rowData.design
                  ),
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Digitalización
                  </Typography>
                ),
                field: 'digitizer',
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
                render: (rowData) =>
                  rowData.digitizer === null ? (
                    0.0
                  ) : (
                    rowData.digitizer
                  ),
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                   Levantamiento
                  </Typography>
                ),
                field: 'walkout',
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
                render: (rowData) =>
                  rowData.walkout === null ? (
                    0.0
                  ) : (
                    rowData.walkout
                  ),
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                   Documentación
                  </Typography>
                ),
                field: 'documentation',
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
                render: (rowData) =>
                  rowData.documentation === null ? (
                    0.0
                  ) : (
                    rowData.documentation
                  ),
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                   Kilometros de enlace
                  </Typography>
                ),
                field: "linkKm",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
                render: (rowData) =>
                  rowData.linkKm === null ? (
                    0
                  ) : (
                    rowData.linkKm
                  ),
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                   Kilometros de zona
                  </Typography>
                ),
                field: "networkKm",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
                render: (rowData) =>
                  rowData.networkKm === null ? (
                    0
                  ) : (
                    rowData.networkKm
                  ),
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                   Total
                  </Typography>
                ),
                field: "totalKm",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
                render: (rowData) =>
                  rowData.totalKm === null ? (
                    0
                  ) : (
                    rowData.totalKm
                  ),
              },
            ]}
            data={reportList}
            title=""
            //Configuraciín de las etiquetas de la tabla
            actions={[
              {
                icon: () => <PrintIcon />,// icon export
                tooltip: "Exportar PDF",
                onClick: () => downloadPdf(),
                isFreeAction: true
              },
              (rowData) => ({
                icon: () => <Icon style={{ color: "#00A4D8" }}>more_vert</Icon>,
                tooltip: <Typography>Detalles</Typography>,
                onClick: (event, rowData) => { handleClickOpenDetails(rowData) },
              }),
            ]}
            //Configuraciín de las etiquetas de la tabla
            options={{
              draggable: false,
              actionsColumnIndex: -1, //orientación de los botonotes de Acciones
              headerStyle: {
                fontWeight: "bold",
                backgroundColor: "#01579b",
                color: "#FFF",
                WebkitBorderTopRightRadius: 10,
              },
            }}
            localization={{
              header: {
                actions: "Acciones",
              },
              body: {
                emptyDataSourceMessage: "No hay registros aún",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Filtro",
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsPerPage: "Filas por página:",
                labelRowsSelect: "Filas",
                firstAriaLabel: "Primer página",
                firstTooltip: "Primer página",
                previousAriaLabel: "Anterior",
                previousTooltip: "Anterior",
                nextAriaLabel: "Siguiente",
                nextTooltip: "Siguiente",
                lastAriaLabel: "Última",
                lastTooltip: "Última",
              },
            }}
          />
        )}
      </Container>

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} >
        <AppBar className={classes.appBar} style={{ backgroundColor: "#01579b" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Cerrar
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container direction="row" justify="space-evenly" alignItems="center">
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "#ababab" }}>
                  <EventNote />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Fecha de Creación" secondary={DateFormat.instance.setFormat(deltails.createdAt)} />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "#ababab" }}>
                  <LocationCity />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Ciudad" secondary={deltails.city} />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "#ababab" }}>
                  <Place />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Estado" secondary={deltails.state} />
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "#ababab" }}>
                  <AccountTree />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Tecnologia" secondary={deltails.technology} />
            </ListItem>
          </List>
        </Grid>
        <DialogContent dividers>

          <Container style={{ width: 600, height: 600 }}>
            <Grid container direction="row" justify="space-evenly" alignItems="center">
              <Typography variant="h5" style={{ paddingTop: 10, paddingBottom: 5 }}>
                <b>Kilometraje Realizado</b>
              </Typography>
            </Grid>
            <Pie
              width={5}
              height={5}
              data={
                {
                  labels: ['Total KM Zona', 'Total KM Enlace', 'Total'],
                  datasets: [{
                    data: [kmZona, kmLink, kmTotal],
                    backgroundColor: [
                      '#38BC7C',
                      '#FF7459',
                      '#00A4D8'],
                  },
                  ],
                }
              }
            >
            </Pie>
          </Container>
        </DialogContent>

      </Dialog>
    </>
  );
};

export default TableIdividualReport;
