import React, { useContext } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
    ExpandMore, ExpandLess, SupervisedUserCircle, Work, Folder, Assessment, ExitToApp, AccountCircle, HistoryOutlined
} from "@material-ui/icons"
import {
    Grid, Collapse, List, ListItem, ListItemIcon, ListItemText, Divider, Typography
} from "@material-ui/core"
import { LoginContext } from "../../security/LoginProvider";
import { types } from '../../security/LoginReducer';
const useStyles = makeStyles((theme) => ({
    options: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: '#555454',
        color: 'FFFFFF'
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 2),
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
        background: "#FF9A24",
    },
    nested: {
        paddingLeft: theme.spacing(11),
    },
    active: {
        background: "#868585",
        paddingLeft: theme.spacing(11),
    },
    singleNested: {

    },
    singleActive: {
        background: "#868585",
    }
}));

const AdministratorOptions = () => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const [state, dispatch] = useContext(LoginContext);
    const [employeesDesp, setEmployeesDesp] = React.useState(false);
    const [rolesDesp, setRolesDesp] = React.useState(false);
    const [projectsDesp, setProjectsDesp] = React.useState(false);
    const [reportsDesp, setReportsDesp] = React.useState(false);
    const [dataEmployee, setDataEmployee] = React.useState(JSON.parse(localStorage.getItem('profile')));

    const handleClick1 = () => {
        setEmployeesDesp(!employeesDesp);
    };
    const handleClick2 = () => {
        setRolesDesp(!rolesDesp);
    };
    const handleClick3 = () => {
        setProjectsDesp(!projectsDesp);
    };
    const handleClick4 = () => {
        setReportsDesp(!reportsDesp);
    };

    const employeeItems = [
        {
            text: "Registrar empleado",
            path: "/newEmployee"
        },
        {
            text: "Asignación de roles",
            path: "/addLaborEmployee"
        },
        {
            text: "Consulta general",
            path: "/generalInformation"
        },
        {
            text: "Consulta única",
            path: "/uniqueSearch"
        },
        {
            text: "Dar de baja",
            path: "/retireEmployee"
        },
        {
            text: "Editar datos",
            path: "/editInformation"
        },
    ]

    const rolItems = [
        {
            text: "Consulta general",
            path: "/labors"
        }
    ]

    const projectItems = [
        {
            text: "Administrar proyectos",
            path: "/projects"
        },
        {
            text: "Administrar tecnologías",
            path: "/technologies"
        },
        {
            text: "Administrar ciudades",
            path: "/cities"
        },
    ]

    const reportItems = [
        {
            text: "Proyectos generales",
            path: "/generalReport"
        },
        {
            text: "Proyecto por empleado",
            path: "/employeeReport"
        },
    ]

    return (
        <>
            {/* Lista de opciones */}
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.options}
                style={{ color: "white" }}
            >
                <div className={classes.drawerHeader}>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                        <AccountCircle style={{ color: "white", width: 32, height: 32 }} />
                    </Grid>
                    <Grid container style={{ marginLeft: -200 }} direction="row" justify="center" alignItems="flex-start">
                        <Typography style={{ color: "white", marginLeft: 5, fontSize: 13 }} variant="caption"><b>{dataEmployee?.lastName.toUpperCase() + " " + dataEmployee?.secondName.toUpperCase() + " " + dataEmployee?.name.toUpperCase()}</b></Typography>
                        <Typography style={{ color: "white", marginLeft: 5, fontSize: 13 }} variant="caption">{dataEmployee?.user.isAdmin ? "ADMINISTRADOR" : "EMPLEADO"}</Typography>
                    </Grid>
                </div>

                {/* Opcion con desplegable */}
                <ListItem button onClick={handleClick1}>
                    <ListItemIcon>
                        <SupervisedUserCircle style={{ color: "white", width: 32, height: 32 }} />
                    </ListItemIcon>
                    <ListItemText primary="Empleados" />
                    {employeesDesp ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                {/* Desplegables de una opcion */}
                <Collapse in={employeesDesp} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            employeeItems.map(item => (
                                <ListItem key={item.text} button className={location.pathname == "/homeAdministrator" + item.path ? classes.active : classes.nested} onClick={() => {
                                    history.push("/homeAdministrator" + item.path);
                                }}>
                                    <ListItemText primary={item.text} />
                                </ListItem>

                            ))
                        }
                    </List>
                </Collapse>
                <ListItem button onClick={handleClick2}>
                    <ListItemIcon>
                        <Work style={{ color: "white", width: 32, height: 32 }} />
                    </ListItemIcon>
                    <ListItemText primary="Roles" />
                    {rolesDesp ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={rolesDesp} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            rolItems.map(item => (
                                <ListItem button key={item.text} className={location.pathname == "/homeAdministrator" + item.path ? classes.active : classes.nested} onClick={() => {
                                    history.push("/homeAdministrator" + item.path);
                                }}>
                                    <ListItemText primary={item.text} />
                                </ListItem>
                            ))
                        }
                    </List>
                </Collapse>
                <ListItem button onClick={handleClick3}>
                    <ListItemIcon>
                        <Folder style={{ color: "white", width: 32, height: 32 }} />
                    </ListItemIcon>
                    <ListItemText primary="Proyectos" />
                    {projectsDesp ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={projectsDesp} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            projectItems.map(item => (
                                <ListItem button key={item.text} className={location.pathname == "/homeAdministrator" + item.path ? classes.active : classes.nested} onClick={() => {
                                    history.push("/homeAdministrator" + item.path);
                                }}>
                                    <ListItemText primary={item.text} />
                                </ListItem>
                            ))
                        }
                    </List>
                </Collapse>
                <ListItem button onClick={handleClick4}>
                    <ListItemIcon>
                        <Assessment style={{ color: "white", width: 32, height: 32 }} />
                    </ListItemIcon>
                    <ListItemText primary="Reportes" />
                    {reportsDesp ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={reportsDesp} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            reportItems.map(item => (
                                <ListItem button key={item.text} className={location.pathname == "/homeAdministrator" + item.path ? classes.active : classes.nested} onClick={() => {
                                    history.push("/homeAdministrator" + item.path);
                                }}>
                                    <ListItemText primary={item.text} />
                                </ListItem>
                            ))
                        }
                    </List>
                </Collapse>
                <Divider />
                <ListItem className={location.pathname == "/homeAdministrator/profile" ? classes.singleActive : classes.sigleNested} button onClick={() => {
                    history.push("/homeAdministrator/profile");
                }}>
                    <ListItemIcon>
                        <AccountCircle style={{ color: "white", width: 32, height: 32 }} />
                    </ListItemIcon>
                    <ListItemText primary="Perfil de usuario" />
                </ListItem>
                <ListItem button onClick={() => {
                    localStorage.clear();
                    dispatch({ type: types.authLogout });
                    history.replace("/");
                }}>
                    <ListItemIcon>
                        <ExitToApp style={{ color: "white", width: 32, height: 32 }} />
                    </ListItemIcon>
                    <ListItemText primary="Cerrar sesión" />
                </ListItem>
                <Divider />
            </List>
        </>
    )
};

export default AdministratorOptions;