import Icon from "@material-ui/core/Icon";
import Container from "@material-ui/core/Container";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Button,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
  DialogContentText,
  Paper,
} from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import API from "../../../utilities/api";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Notification from "../../../utilities/Notifications";
import { KeyboardBackspace } from "@material-ui/icons";

const EmployeeTask = () => {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(true);
  const [dis, setDis] = useState(false);
  const [sum, setSum] = useState(0);
  const [flagTwo, setFlagTwo] = useState(false);
  const [polygonName, setPolygonName] = useState("");
  const [special, setSpecial] = useState(0);
  const [task, setTask] = useState(0);
  const [tam, setTam] = useState("xs");
  const [labor, setLabor] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const [inputFields, setInputFields] = useState([
    {
      task: { id: task },
      name: "",
      kilometer: 0,
      linkKm: 0,
    },
  ]);

  const [taskHistory, setTaskHistory] = useState({
    task: { id: 0 },
    description: "",
    network_km: 0,
    linkKm: 0,
    action: "",
  });

  //Modal Levantamiento
  const [openOne, setOpenOne] = useState(false);
  const handleClickOpenOne = () => {
    setOpenOne(true);
  };

  const handleCloseOne = () => {
    setOpenOne(false);
    setPolygonName("");
    setLabor("");
    setInputFields([
      {
        task: { id: task },
        name: "",
        kilometer: 0,
        linkKm: 0,
      },
    ]);
    setTam("xs");
    setTask(0);
    setFlagTwo(false);
    setTaskHistory({
      task: { id: task },
      description: "",
      network_km: 0,
      linkKm: 0,
      action: "",
    });
    setConfirmation(false);
    setSpecial(0);
    setDis(false);
  };

  const updateFields = (property, index) => (event) => {
    let newArr = inputFields.map((item, i) => {
      if (index === i) {
        return { ...item, [property]: event.target.value };
      } else {
        return item;
      }
    });
    setInputFields(newArr);
  };

  const newField = () => {
    setInputFields([
      ...inputFields,
      {
        task: { id: task },
        name: "",
        kilometer: 0,
        linkKm: 0,
      },
    ]);
  };

  const removeField = (index) => {
    if (inputFields.length === 1) {
      console.log("");
    } else {
      const values = [...inputFields];
      values.splice(index, 1);
      setInputFields(values);
    }
  };

  const postSelectTask = async () => {
    setDis(true);
    console.log(labor);
    console.log(flagTwo);
    if (labor === "Levantamiento" && flagTwo === false) {
      postTask();
    } else if (labor === "Levantamiento" && flagTwo === true) {
      postLevantamiento();
    } else if (labor === "Diseño") {
      postDesing();
    } else if (labor === "Digitalizar" && flagTwo === false) {
      postTask();
    } else if (labor === "Digitalizar" && flagTwo === true) {
      postDigitizer();
    } else if (flagTwo === false) {
      postTask();
    } else if (flagTwo === true) {
      console.log("Generica terminada");
      API.put(`/task/updateTask`, {
        status: {
          id: 5,
        },
        id: task,
        commentary: taskHistory.description,
        deliverable: taskHistory.action,
      })
        .then(() => {
          postTask();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getDigitizer = async () => {
    console.log("ID TAREA " + task);
    const response = await API.get(`/digitizer/task/${task}`);
    return response.data.id;
  };

  const postDigitizer = async () => {
    let id = await getDigitizer();
    console.log("ID de digitalizador " + id);
    let finalLine = await parseFloat(special);
    console.log("Final de linea " + finalLine);
    await API.patch(`/digitizer/update/${id}`, {
      finalLineKm: finalLine,
    })
      .then(() => {
        API.put(`/task/updateTask`, {
          status: {
            id: 5,
          },
          id: task,
          commentary: taskHistory.description,
          deliverable: taskHistory.action,
        })
          .then(() => {
            postTask();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const postDesing = async () => {
    if (flagTwo === false) {
      await API.post(`/node-design/multisave`, { inputFields })
        .then((response) => {
          postTask();
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (flagTwo === true) {
      await API.post(`/node-design/multisave`, { inputFields })
        .then(() => {
          API.put(`/task/updateTask`, {
            status: {
              id: 5,
            },
            id: task,
            commentary: taskHistory.description,
            deliverable: taskHistory.action,
          })
            .then(() => {
              postTask();
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const postTask = async () => {
    await API.post(`/taskHistory/`, taskHistory)
      .then((response) => {
        handleCloseOne();
        console.log(response.data);
        Notification.SuccessMessage(
          "¡Avance envíado!",
          "Tus avances fueron registrados correctamente"
        );
      })
      .catch((error) => {
        console.log(error);
        Notification.ErrorMessage("¡Error!", "Algo ha salido mal");
      });
  };

  const postLevantamientoTwo = async () => {
    await API.post(`/walkout/`, {
      task: {
        id: task,
      },
      mapCount: special,
    })
      .then((response) => {
        console.log(response);
      })
      .then(() => {
        postTask();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const postLevantamiento = async () => {
    await API.put(`/task/updateTask`, {
      status: {
        id: 5,
      },
      id: task,
      commentary: taskHistory.description,
      deliverable: taskHistory.action,
    })
      .then(() => {
        postLevantamientoTwo();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addIndexZero = (property, index) => {
    let data = { id: task };
    let newArr = inputFields.map((item, i) => {
      if (index === i) {
        return { ...item, [property]: data };
      } else {
        return item;
      }
    });
    setInputFields(newArr);
  };

  const getTasks = async () => {
    let profile = await JSON.parse(localStorage.getItem("profile"));
    await API.get(
      `/task/user/${profile.user.id}/project/${location.state.idTask}`
    )
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const viewModal = (labor, polygonName, id) => {
    setTask(id);
    if (labor === "Diseño") {
      setLabor(labor);
      setPolygonName(polygonName);
      setTam("md");
      handleClickOpenOne();
      setTaskHistory((prevState) => ({
        ...prevState,
        task: { id: id },
      }));
    } else {
      setTaskHistory((prevState) => ({
        ...prevState,
        task: { id: id },
      }));
      setLabor(labor);
      setPolygonName(polygonName);
      handleClickOpenOne();
    }
  };

  useEffect(() => {
    if (sum === 2) {
      setSum(0);
      setFlagTwo(false);
    }
  }, [sum]);

  useEffect(() => {
    addIndexZero("task", 0);
  }, [task]);

  useEffect(() => {
    getTasks();
  }, [task]);

  return (
    <Container>
      <Paper
        elevation={1}
        style={{ borderRadius: 10, borderLeft: "8px solid #FF9A24" }}
      >
        <Grid
          style={{ marginBottom: 20, marginTop: 10 }}
          container
          spacing={3}
          justify="space-between"
        >
          <Grid item>
            <Typography variant="h5" style={{ marginLeft: 20 }}>
              <b>{location.state.name} » Tareas</b>
            </Typography>
            <Grid item>
              <Typography
                style={{ color: "#9B9A9A", marginTop: 10, marginLeft: 20 }}
                variant="subtitle1"
              >
                Lista de tareas asignadas
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              startIcon={<KeyboardBackspace />}
              fullWidth
              style={{
                marginLeft: 10,
                color: "white",
                backgroundColor: "#f7a900",
                width: 150,
                height: 35,
                marginRight: 20,
              }}
              onClick={() => {
                history.goBack();
              }}
            >
              Regresar
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Grid
        styel={{ marginTop: 50 }}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Dialog
          fullWidth={flag}
          maxWidth={tam}
          open={openOne}
          onClose={handleCloseOne}
        >
          <DialogTitle style={{ backgroundColor: "#555454", color: "white" }}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Icon style={{ color: "white", marginRight: 10 }}>room</Icon>
              <Typography>Evidencia de: {polygonName}</Typography>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      setFlagTwo(true);
                      setSum(sum + 1);
                    }}
                    checked={flagTwo}
                    color="primary"
                  />
                }
                label="Marcar como completada"
              />
              {labor === "Levantamiento" ? (
                <>
                  <TextField
                    size="small"
                    style={{ width: 300, marginTop: 20 }}
                    label="Link de tarea"
                    variant="filled"
                    onChange={(state) =>
                      setTaskHistory((prevState) => ({
                        ...prevState,
                        action: state.target.value,
                      }))
                    }
                  />
                  <TextField
                    size="small"
                    style={{
                      width: 300,
                      marginTop: 20,
                    }}
                    label="KM enlace"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    variant="filled"
                    onChange={(state) =>
                      setTaskHistory((prevState) => ({
                        ...prevState,
                        linkKm: state.target.value,
                      }))
                    }
                  />
                  <TextField
                    size="small"
                    style={{
                      width: 300,
                      marginTop: 20,
                    }}
                    label="KM zona"
                    InputProps={{ inputProps: { min: 0 } }}
                    type="number"
                    variant="filled"
                    onChange={(state) =>
                      setTaskHistory((prevState) => ({
                        ...prevState,
                        network_km: state.target.value,
                      }))
                    }
                  />
                  {flagTwo === true ? (
                    <TextField
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={(state) => setSpecial(state.target.value)}
                      style={{ width: 300, marginTop: 20 }}
                      label="KM total de conteo"
                      variant="filled"
                    />
                  ) : (
                    <></>
                  )}
                  <TextField
                    size="small"
                    style={{ width: 300, marginTop: 20 }}
                    label="Comentario"
                    variant="filled"
                    onChange={(state) =>
                      setTaskHistory((prevState) => ({
                        ...prevState,
                        description: state.target.value,
                      }))
                    }
                    multiline
                  />
                </>
              ) : (
                <>
                  {labor === "Diseño" ? (
                    <>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Container style={{ marginLeft: 45 }}>
                          <TextField
                            size="small"
                            style={{
                              width: 250,
                              marginTop: 20,
                              marginRight: 10,
                            }}
                            label="Link de tarea"
                            variant="filled"
                            onChange={(state) =>
                              setTaskHistory((prevState) => ({
                                ...prevState,
                                action: state.target.value,
                              }))
                            }
                          />
                          <TextField
                            size="small"
                            style={{
                              width: 120,
                              marginTop: 20,
                              marginRight: 10,
                            }}
                            label="KM enlace"
                            InputProps={{ inputProps: { min: 0 } }}
                            type="number"
                            variant="filled"
                            onChange={(state) =>
                              setTaskHistory((prevState) => ({
                                ...prevState,
                                linkKm: state.target.value,
                              }))
                            }
                          />
                          <TextField
                            size="small"
                            style={{
                              width: 120,
                              marginTop: 20,
                              marginRight: 10,
                            }}
                            label="KM zona"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            variant="filled"
                            onChange={(state) =>
                              setTaskHistory((prevState) => ({
                                ...prevState,
                                network_km: state.target.value,
                              }))
                            }
                          />
                          <TextField
                            size="small"
                            style={{ width: 250, marginTop: 20 }}
                            label="Comentario"
                            variant="filled"
                            onChange={(state) =>
                              setTaskHistory((prevState) => ({
                                ...prevState,
                                description: state.target.value,
                              }))
                            }
                            multiline
                          />
                        </Container>
                      </Grid>
                      {inputFields.map((dato, index) => {
                        return (
                          <Grid
                            container
                            key={index}
                            direction="row"
                            justify="center"
                            alignItems="center"
                          >
                            <Container style={{ marginLeft: 45 }}>
                              <TextField
                                size="small"
                                style={{
                                  width: 210,
                                  marginTop: 20,
                                  marginLeft: 10,
                                }}
                                label="Nombre de nodo"
                                variant="filled"
                                value={dato.name}
                                onChange={updateFields("name", index)}
                              />
                              <TextField
                                size="small"
                                style={{
                                  width: 210,
                                  marginTop: 20,
                                  marginLeft: 10,
                                }}
                                type="number"
                                label="KM del nodo"
                                variant="filled"
                                InputProps={{ inputProps: { min: 0 } }}
                                value={dato.kilometer}
                                onChange={updateFields("kilometer", index)}
                              />
                              <TextField
                                size="small"
                                type="number"
                                style={{
                                  width: 210,
                                  marginTop: 20,
                                  marginLeft: 10,
                                }}
                                InputProps={{ inputProps: { min: 0 } }}
                                label="KM de enlace-nodo"
                                variant="filled"
                                value={dato.linkKm}
                                onChange={updateFields("linkKm", index)}
                              />
                              <IconButton
                                style={{ marginTop: 20, marginLeft: 10 }}
                                onClick={() => newField()}
                              >
                                <Icon>add_circle</Icon>
                              </IconButton>
                              <IconButton
                                onClick={() => removeField(index)}
                                style={{ marginTop: 20, marginLeft: 10 }}
                              >
                                <Icon>remove_circle</Icon>
                              </IconButton>
                            </Container>
                          </Grid>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {labor === "Digitalizar" ? (
                        <>
                          <TextField
                            size="small"
                            style={{ width: 300, marginTop: 20 }}
                            label="Link de tarea"
                            variant="filled"
                            onChange={(state) =>
                              setTaskHistory((prevState) => ({
                                ...prevState,
                                action: state.target.value,
                              }))
                            }
                          />
                          <TextField
                            size="small"
                            style={{
                              width: 300,
                              marginTop: 20,
                            }}
                            label="KM enlace"
                            InputProps={{ inputProps: { min: 0 } }}
                            type="number"
                            variant="filled"
                            onChange={(state) =>
                              setTaskHistory((prevState) => ({
                                ...prevState,
                                linkKm: state.target.value,
                              }))
                            }
                          />
                          <TextField
                            size="small"
                            style={{
                              width: 300,
                              marginTop: 20,
                            }}
                            label="KM zona"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            variant="filled"
                            onChange={(state) =>
                              setTaskHistory((prevState) => ({
                                ...prevState,
                                network_km: state.target.value,
                              }))
                            }
                          />
                          {flagTwo === true ? (
                            <TextField
                              size="small"
                              type="number"
                              InputProps={{ inputProps: { min: 0 } }}
                              onChange={(state) =>
                                setSpecial(state.target.value)
                              }
                              style={{ width: 300, marginTop: 20 }}
                              label="KM final de linea"
                              variant="filled"
                            />
                          ) : (
                            <></>
                          )}
                          <TextField
                            size="small"
                            style={{ width: 300, marginTop: 20 }}
                            label="Comentario"
                            variant="filled"
                            onChange={(state) =>
                              setTaskHistory((prevState) => ({
                                ...prevState,
                                description: state.target.value,
                              }))
                            }
                            multiline
                          />
                        </>
                      ) : (
                        <>
                          <TextField
                            size="small"
                            style={{ width: 300, marginTop: 20 }}
                            label="Link de tarea"
                            variant="filled"
                            onChange={(state) =>
                              setTaskHistory((prevState) => ({
                                ...prevState,
                                action: state.target.value,
                              }))
                            }
                          />
                          <TextField
                            size="small"
                            style={{
                              width: 300,
                              marginTop: 20,
                            }}
                            label="KM enlace"
                            type="number"
                            InputProps={{ inputProps: { min: 0 } }}
                            variant="filled"
                            onChange={(state) =>
                              setTaskHistory((prevState) => ({
                                ...prevState,
                                linkKm: state.target.value,
                              }))
                            }
                          />
                          <TextField
                            size="small"
                            style={{
                              width: 300,
                              marginTop: 20,
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            label="KM zona"
                            type="number"
                            variant="filled"
                            onChange={(state) =>
                              setTaskHistory((prevState) => ({
                                ...prevState,
                                network_km: state.target.value,
                              }))
                            }
                          />
                          <TextField
                            size="small"
                            style={{ width: 300, marginTop: 20 }}
                            label="Comentario"
                            variant="filled"
                            onChange={(state) =>
                              setTaskHistory((prevState) => ({
                                ...prevState,
                                description: state.target.value,
                              }))
                            }
                            multiline
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmation(true)} color="primary">
              Enviar
            </Button>
            <Button onClick={handleCloseOne} color="primary" autoFocus>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={confirmation}
          maxWidth="sm"
          onClose={() => setConfirmation(false)}
        >
          <DialogTitle>{"¿Desea continuar?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Si esta seguro pulse aceptar para continuar con el proceso.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmation(false)} color="primary">
              Cancelar
            </Button>
            <Button
              disabled={dis}
              onClick={() => postSelectTask()}
              color="primary"
              autoFocus
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>

        {data.length === 0 ? (
          <>
            <Grid
              style={{ marginTop: 100 }}
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Icon style={{ fontSize: 90 }}>assignment</Icon>
              <Typography>Aún no tienes tareas asignadas.</Typography>
            </Grid>
          </>
        ) : (
          <>
            {data.map((dato) => {
              return (
                <Card
                  key={dato.id}
                  style={{
                    minWidth: 210,
                    margin: 20,
                    minHeight: 210,
                    borderRadius: 10,
                  }}
                >
                  <CardActionArea
                    onClick={() => {
                      if (dato.status.id === 5) {
                        Notification.InfoMessage(
                          "Información",
                          "Esta tarea ya se completo."
                        );
                      } else {
                        viewModal(
                          dato.labor.name,
                          dato.activity.polygon.name,
                          dato.id
                        );
                      }
                    }}
                  >
                    <CardHeader
                      style={{ backgroundColor: "#FF9A24" }}
                      subheader={
                        <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          <Icon style={{ color: "white", marginRight: 10 }}>
                            assignment
                          </Icon>
                          <Typography
                            style={{ color: "white" }}
                            variant="subtitle2"
                          >
                            {dato.name}
                          </Typography>
                        </Grid>
                      }
                    />
                    <CardContent>
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                      >
                        <Typography variant="subtitle2">
                          Kilometro de zona
                        </Typography>
                        <Typography variant="caption">
                          {dato.networkKm}
                        </Typography>
                        <Typography variant="subtitle2">
                          Kilometro de enlace
                        </Typography>
                        <Typography variant="caption">{dato.linkKm}</Typography>
                        <Typography variant="subtitle2">
                          Tipo de tarea
                        </Typography>
                        <Typography variant="caption">
                          {dato.labor.name}
                        </Typography>
                        <Typography style={{ marginTop: 5 }} variant="subtitle2">
                          Fecha de entrega
                        </Typography>
                        <Typography variant="caption">
                          {dato.deliveredAt}
                        </Typography>
                        <Typography
                          style={{ marginTop: 15 }}
                          variant="subtitle2"
                        >
                          ESTATUS:
                        </Typography>
                        {dato.status.name === "Retraso" ? (
                          <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                          >
                            <Typography variant="caption">Retraso</Typography>
                            <Icon style={{ color: "#ef5350", fontSize: 60 }}>
                              fiber_manual_record
                            </Icon>
                          </Grid>
                        ) : (
                          <div>
                            {dato.status.name === "Entregada" ? (
                              <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                              >
                                <Typography variant="caption">
                                  Entregada
                                </Typography>
                                <Icon
                                  style={{ color: "#00A4D8", fontSize: 60 }}
                                >
                                  fiber_manual_record
                                </Icon>
                              </Grid>
                            ) : (
                              <div>
                                {dato.status.name === "Proceso" ? (
                                  <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                  >
                                    <Typography variant="caption">
                                      Proceso
                                    </Typography>
                                    <Icon
                                      style={{ color: "#38BC7C", fontSize: 60 }}
                                    >
                                      fiber_manual_record
                                    </Icon>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      onClick={() => {
                        history.push({
                          pathname: "/homeEmployee/taskDetails",
                          state: { idTaskDetail: dato.id },
                        });
                      }}
                      color="primary">VER HISTORIAL</Button>
                  </Grid>
                </Card>
              );
            })}
          </>
        )}
      </Grid>
    </Container>
  );
};
export default EmployeeTask;
