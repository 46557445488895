import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Drawer, CssBaseline, AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LogoFiber from "../Images/logo.png";
import StartScreenAdmin from "../components/administrator/StartScreenAdmin"
import AdministratorOptions from "./AdministratorOptions";
import Labors from "../components/administrator/labors/TableLabors"
import Technologies from "../components/administrator/technologies/TableTechnologies"
import Cities from "../components/administrator/cities/TableCities"
import Profile from "./profile"
import AddEmployee from "../components/administrator/employees/AddEmployee"
import TableEmployee from "../components/administrator/employees/TableEmployees"
import SearchOneEmployee from "../components/administrator/employees/SearchOneEmployee"
import DeleteEmployee from "../components/administrator/employees/DeleteEmployee"
import UpdateEmployee from "../components/administrator/employees/UpdateEmployee"
import AddLaborEmployee from "../components/administrator/employees/AddLabors"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import TableProjects from "../components/administrator/projects/TableProjects";
import TablePolygons from "../components/administrator/polygons/TablePolygons";
import TableActivities from "../components/administrator/activities/TableActivities";
import AddTask from "../components/administrator/tasks/AddTask";
import TableEmployeesLabor from "../components/administrator/labors/TableEmployeesLabor"
import TablePolygonsTechnology from "../components/administrator/technologies/TablePolygonsTechnology";
import TableTasks from "../components/administrator/tasks/TableTasks";
import TableGeneralReport from "../components/administrator/GeneralReports/TableGeneralReport";
import TableIndividualReport from "../components/administrator/GeneralReports/TableIndividualReport";
import TableEmployeeReport from "../components/administrator/GeneralReports/TableEmployeeReport";
import SearchEmployee from "../components/administrator/GeneralReports/SearchEmployee";
import TaskDeltails from "../components/administrator/tasks/TaskDeltails";
import { LoginContext } from "../../security/LoginProvider";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#555454",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    background: "#FF9A24",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    backgroundColor: '#f5f5f5'
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbar: theme.mixins.toolbar,
}));

const HomeAdministrator = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [state, dispatch] = useContext(LoginContext);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const { logged, isAdmin } = state;
  return (
    <div className={classes.root} >
      <CssBaseline />
      <AppBar
        style={{ backgroundColor: "#555454" }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography style={{ flexGrow: 1 }}></Typography>
          <Typography variant="subtitle1">
            {" "}
            Fiberpromex S.A.S. de C.V.
          </Typography>
          <img
            src={LogoFiber}
            alt="logo"
            width="32"
            style={{ marginLeft: 10 }}
          />
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* Boton que cierra el drawer */}
        <div>
          <IconButton onClick={handleDrawerClose} style={{ marginTop: 8 }}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon style={{ color: "white" }} />
            ) : (
              <ChevronRightIcon style={{ color: "white" }} />
            )}
          </IconButton>
        </div>
        {logged && isAdmin ? <AdministratorOptions /> : <Redirect to="/" />}

      </Drawer>
      <main className={clsx(classes.content, { [classes.contentShift]: open })} >
        <div className={classes.toolbar} /> {/* fix del toolbar NO QUITAR*/}
        {/* Dentro de esta etiqueta main van los componentes que se van a mostrar con router */}
        <Switch>
          <Route path="/homeAdministrator/projects/polygons/:idProject" component={TablePolygons} />
          <Route path="/homeAdministrator/generalReport/individualReport/:idProject" component={TableIndividualReport} />
          <Route path="/homeAdministrator/employeeReport/report/:idEmployee" component={TableEmployeeReport} />
          <Route path="/homeAdministrator/projects/activities/:idPolygon" component={TableActivities} />
          <Route path="/homeAdministrator/projects/task/:idActivity" component={TableTasks} />
          <Route path="/homeAdministrator/task/deltails/:idTask" component={TaskDeltails} />
          <Route path="/homeAdministrator/labors/employees/:idLabor" component={TableEmployeesLabor} />
          <Route path="/homeAdministrator/technologies/polygons/:idTechnology" component={TablePolygonsTechnology} />
          <Route path="/homeAdministrator/task/addtask/:idActivity" component={AddTask} />
          <Route path="/homeAdministrator/startScreenAdmin" component={StartScreenAdmin} />
          <Route path="/homeAdministrator/newEmployee" component={AddEmployee} />
          <Route path="/homeAdministrator/addLaborEmployee" component={AddLaborEmployee} />
          <Route path="/homeAdministrator/generalInformation" component={TableEmployee} />
          <Route path="/homeAdministrator/uniqueSearch" component={SearchOneEmployee} />
          <Route path="/homeAdministrator/retireEmployee" component={DeleteEmployee} />
          <Route path="/homeAdministrator/editInformation" component={UpdateEmployee} />
          <Route path="/homeAdministrator/labors" component={Labors} />
          <Route path="/homeAdministrator/projects" component={TableProjects} />
          <Route path="/homeAdministrator/technologies" component={Technologies} />
          <Route path="/homeAdministrator/cities" component={Cities} />
          <Route path="/homeAdministrator/profile" component={Profile} />
          <Route path="/homeAdministrator/generalReport" component={TableGeneralReport} />
          <Route path="/homeAdministrator/employeeReport" component={SearchEmployee} />
        </Switch>
      </main>
    </div>
  );
};
export default HomeAdministrator;
