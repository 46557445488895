import axios from 'axios';
const axiosService = axios.create({
  baseURL: 'https://adpro-no-cors.herokuapp.com/https://testing-adpro.herokuapp.com/adpro/api',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
  },
});

const handleErrorResponse = (error) => {
  console.log('error.', error)
  const code = error.response.status
  console.log(code)
  if (code == 403)
    localStorage.clear();
}
// Add a request interceptor
axiosService.interceptors.request.use(async (config) => {
  const userToken = await localStorage.getItem('token')
  if (userToken) {
    config.headers["Authorization"] = `Bearer ${userToken}`
  }
  return config
}, (error) => {
  return error
})

// Add a response interceptor
axiosService.interceptors.response.use(res => {
  //console.log("res", res)
  return res
}, handleErrorResponse)

export default axiosService;