import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography, TextField, Container, Grid,
  Paper, MenuItem, InputLabel, Select, FormControl, Button,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import API from "../../../utilities/api";
import Toast from "../../../utilities/Notifications";
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
    },
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    minWidth: 250,
  },
  board: {
    textAlign: "center",
    borderRadius: 20,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const AddEmployee = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = React.useState({
    user: {
      email: "",
      password: "",
      isAdmin: false,
      isActive: true,
    },
    name: "",
    lastName: "",
    secondName: "",
    phoneNumber: "",
  });
  const handleChangeValues = (prop) => (event) => {
    setData({ ...data, [prop]: event.target.value });
  };

  const handleChangeInternValues = (prop) => (event) => {
    setData({ ...data, user: { ...data.user, [prop]: event.target.value } });
  };

  const httpPostEmployee = async () => {
    await API.post("/profile/", data)
      .then((response) => {
        Toast.SuccessMessage(
          "¡Inserción Realizada!",
          "Se han guardado los datos correctamente."
        );
        setLoading(false);
        setData({
          user: {
            email: "",
            password: "",
            isAdmin: false,
            isActive: true,
          },
          name: "",
          lastName: "",
          secondName: "",
          phoneNumber: "",
        });
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        Toast.ErrorMessage("Algo ocurrió en el servidor", "No se logró guardar la información.");

      });
  };

  const compare = () => {
    setLoading(true)
    if (
      data.user.email === "" ||
      data.name === "" ||
      data.lastName === "" ||
      data.secondName === "" ||
      data.phoneNumber === "") {

      Toast.InfoMessage("Sugerencia", "Debes llenar todos los campos.");
      setLoading(false);
    } else {
      verifyEmail();
    }
  }

  const verifyEmail = async () => {
    await API.get(`/user/email/${data.user.email}`)
      .then((response) => {
        if (response.data === null) {
          httpPostEmployee();
        } else if (response.data.user.email === data.user.email) {
          Toast.InfoMessage("Información", "Al parecer ya existe un empleado con ese correo, por favor ingresa uno diferente.");
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        Toast.ErrorMessage("Algo ocurrió en el servidor", "No se logró verificar el correo.");

      })
  }

  return (
    <>
      <Container>
        <Paper elevation={1} style={{ borderRadius: 10, borderLeft: '8px solid #38BC7C' }}>
          <Grid
            style={{ marginBottom: 20, marginTop: 10, }}
            container
            spacing={3}
            justify="space-between"
          >
            <Grid item>
              <Typography variant="h5" style={{ marginLeft: 20, }}>
                <b>Registra un nuevo empleado</b>
              </Typography>
              <Grid item>
                {
                  loading ? (
                    <div style={{
                      display: "flex",
                      justifyContent: "center",
                      width: 200,
                      marginLeft: 20,
                      marginRight: 10,
                      marginTop: 10
                    }}>
                      <BeatLoader color="#38BC7C" loading={loading} size={21} margin={7} />
                    </div>
                  ) : (
                    <Button
                      startIcon={<Save />}
                      fullWidth
                      style={{
                        color: "white",
                        backgroundColor: "#38BC7C",
                        width: 200,
                        height: 39,
                        marginLeft: 20,
                        marginRight: 10,
                        marginTop: 10
                      }}
                      onClick={compare}
                    >
                      Guardar
                    </Button>
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper elevation={1} style={{ borderRadius: 10, marginBottom: 20 }}>
          <Grid className={classes.root}>
            <TextField
              id="name"
              className={classes.formControl}
              label="Nombre(s)"
              size="small"
              color="primary"
              variant="filled"
              value={data.name}
              onChange={handleChangeValues("name")}
            />
            <TextField
              id="lastname"
              className={classes.formControl}
              label="Primer apellido"
              color="primary"
              size="small"
              variant="filled"
              value={data.lastName}
              onChange={handleChangeValues("lastName")}
            />
            <TextField
              id="secondname"
              className={classes.formControl}
              label="Segundo apellido"
              color="primary"
              size="small"
              variant="filled"
              value={data.secondName}
              onChange={handleChangeValues("secondName")}
            />
            <TextField
              id="phoneNumber"
              className={classes.formControl}
              label="Teléfono"
              color="primary"
              size="small"
              variant="filled"
              type="number"
              inputProps={{ min: 0, max: 10 }}
              value={data.phoneNumber}
              onChange={handleChangeValues("phoneNumber")}
            />
            <TextField
              id="email"
              className={classes.formControl}
              label="E-mail"
              size="small"
              color="primary"
              variant="filled"
              value={data.user.email}
              onChange={handleChangeInternValues("email")}
            />
            <FormControl
              size="small"
              variant="filled"
              className={classes.formControl}
            >
              <InputLabel id="isAdmin">Cargo</InputLabel>
              <Select
                id="isAdmin"
                labelId="isAdmin"
                color="primary"
                value={data.user.isAdmin}
                onChange={handleChangeInternValues("isAdmin")}
              >
                <MenuItem value={false}>Empleado</MenuItem>
                <MenuItem value={true}>Administrador</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default AddEmployee;
