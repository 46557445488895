import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Container,
  Grid,
  Paper,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  Button,
  FormHelperText,
  Icon,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import deLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
import TableEmployee from "./TableEmployee";
import {
  Save,
  KeyboardBackspace,
  Close,
  CheckCircle,
} from "@material-ui/icons";
import API from "../../../utilities/api";
import { useParams, useHistory } from "react-router-dom";
import Toast from "../../../utilities/Notifications";
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
    },
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    minWidth: 250,
  },
}));

const AddTask = () => {
  const { idActivity } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabledUser, setDisabledUser] = useState(true);
  const [profileData, setProfileData] = useState({
    id: null,
    name: "",
    lastName: "",
  });
  const [laborData, setLaborData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [task, setTask] = useState({
    id: null,
    user: { id: null },
    status: { id: 1 },
    activity: { id: idActivity },
    name: "",
    labor: { id: "", name: "Ninguno" },
    commentary: "",
    description: "",
    networkKm: 0,
    linkKm: 0,
    totalKm: 0,
    deliverable: "",
    createdAt: "",
    updatedAt: "",
    workedAt: "",
    deliveredAt: "",
  });
  const [taskDigitizer, setTaskDigitizer] = useState({
    id: null,
    task: { id: task.id },
    proposedKm: "",
    finalLineKm: 0,
  });
  const [activityData, setActivityData] = useState({
    id: 1,
    polygon: {
      id: 0,
      project: {
        id: 0,
        isCompleted: false,
      },
    },
    name: "",
    description: "",
    createdAt: "",
    updatedAt: "",
    isCompleted: false,
  });

  const [errorFlagName, setErrorFlagName] = useState(false);
  const [textErrorName, setTextErrorName] = useState("");
  const [errorFlagDescription, setErrorFlagDescription] = useState(false);
  const [textErrorDescription, setTextErrorDescription] = useState("");
  const [errorFlagSelectLabor, setErrorFlagSelectLabor] = useState(false);
  const [textErrorSelectLabor, setTextErrorSelectLabor] = useState("");
  const [errorFlagSelectUser, setErrorFlagSelectUser] = useState(false);
  const [textErrorSelectUser, setTextErrorSelectUser] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);
  const [textError, setTextError] = useState("");

  const [openEnd, setOpenEnd] = useState(false);
  const [confirmEnd, setConfirmEnd] = useState("");
  const [matchName, setMatchName] = useState(true);
  const [isDigitizer, setIsDigitizer] = useState(false);

  const handleCloseEnd = () => {
    setOpenEnd(false);
  };

  const handleChangeEnd = (e) => {
    const { name, value } = e.target;
    setConfirmEnd(value);
    if (value.toLowerCase() === "guardar") {
      setMatchName(false);
    } else {
      setMatchName(true);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setTask((prevState) => ({
      ...prevState,
      deliveredAt: date,
    }));
  };

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    setTask((prevState) => ({
      ...prevState,
      id: null,
      deliveredAt: selectedDate,
      [name]: value,
    }));
    if (name === "labor") {
      getEmployee(value);
    }
  };

  const handleChangeKm = (e) => {
    const { name, value } = e.target;
    setTaskDigitizer((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const cleanErrors = () => {
    setErrorFlagName(false);
    setTextErrorName("");
    setErrorFlagDescription(false);
    setTextErrorDescription("");
    setErrorFlagSelectLabor(false);
    setTextErrorSelectLabor("");
    setErrorFlagSelectUser(false);
    setTextErrorSelectUser("");
    setErrorFlag(false);
    setTextError("");
  };

  const cleanText = () => {
    const emptytask = {
      id: null,
      user: { id: null },
      status: { id: 2 },
      activity: { id: idActivity },
      name: "",
      labor: { id: "", name: "Ninguno" },
      commentary: "",
      networkKm: 0,
      description: "",
      linkKm: 0,
      totalKm: 0,
      deliverable: "",
      createdAt: "",
      updatedAt: "",
      workedAt: "",
      deliveredAt: "",
    };
    setTask(emptytask);
    const emptytaskdigitizer = {
      id: null,
      task: { id: null },
      proposedKm: "",
      finalLineKm: 0,
    };
    setDisabledUser(true);
    setTaskDigitizer(emptytaskdigitizer);
    setSelectedDate(new Date());
    setConfirmEnd("");
  };

  const validarGeneric = () => {
    if (task.name.trim() === "" || task.name == null) {
      setErrorFlagName(true);
      setTextErrorName("Campo requerido");
    } else if (!task.name.match(/^[[Ññíóáéú/_0-9 a-zA-Z\-]+$/)) {
      setErrorFlagName(true);
      setTextErrorName("Sólo se permiten letras, números, guiones y diagonal");
    } else if (
      !task.description.match(/^[[Ññíóáéú/_0-9 a-zA-Z\-]+$/) &&
      task.description.trim() != ""
    ) {
      setErrorFlagDescription(true);
      setTextErrorDescription(
        "Sólo se permiten letras, números, guiones y diagonal"
      );
    } else if (task.labor.name === "Ninguno") {
      setErrorFlagSelectLabor(true);
      setTextErrorSelectLabor("Selecciona un rol válido");
    } else if (task.user.id == null) {
      setErrorFlagSelectUser(true);
      setTextErrorSelectUser("Selecciona un empleado válido");
    } else {
      return false;
    }
    return true;
  };

  const validarDigitizer = () => {
    if (taskDigitizer.proposedKm === "") {
      setErrorFlag(true);
      setTextError("Campo requerido");
    } else if (taskDigitizer.proposedKm <= 0) {
      setErrorFlag(true);
      setTextError("No se aceptan valores menores o igual que 0");
    } else {
      return false;
    }
    return true;
  };

  const saveGenericData = async () => {
    setLoading(true);
    setMatchName(true);
    handleCloseEnd();
    await API.post("/task/", task)
      .then((response) => {
        cleanErrors();
        setOpenEnd(false);
        Toast.SuccessMessage(
          "¡Registro realizado!",
          "Datos guardados correctamente"
        );
        cleanText();
        setMatchName(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setOpenEnd(false);
        setMatchName(true);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudo completar el envío"
        );
      });
  };

  const saveDigitizerData = async () => {
    setLoading(true);
    setMatchName(true);
    handleCloseEnd();
    await API.post("/task/", task)
      .then((response) => {
        cleanErrors();
        const digitizer = {
          id: null,
          task: { id: response.data.id },
          proposedKm: taskDigitizer.proposedKm,
          finalLineKm: 0,
        };
        API.post("/digitizer/", digitizer)
          .then((response) => {
            cleanText();
            setOpenEnd(false);
            Toast.SuccessMessage(
              "¡Registro realizado!",
              "Datos guardados correctamente"
            );
            cleanErrors();
            setMatchName(true);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            setOpenEnd(false);
            setMatchName(true);
            Toast.ErrorMessage(
              "¡Ha ocurrido un error!",
              "Los sentimos, no se pudo completar el envío"
            );
          });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setOpenEnd(false);
        setMatchName(true);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudo completar el envío"
        );
      });
  };

  const saveNewTask = () => {
    cleanErrors();
    setMatchName(true);
    if (task.labor.name != "Digitalizar") {
      if (!validarGeneric()) {
        getProfile();
        setOpenEnd(true);
      }
    } else if (!validarGeneric() && !validarDigitizer()) {
      getProfile();
      setOpenEnd(true);
      setIsDigitizer(true);
    }
  };

  const getActivity = async () => {
    await API.get(`/activity/${idActivity}`)
      .then((response) => {
        setActivityData(response.data);
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
      });
  };

  const getProfile = async () => {
    await API.get(`/profile/user/${task.user.id}`)
      .then((response) => {
        setProfileData(response.data);
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudo recuperar el perfil de empleado"
        );
      });
  };

  const getLabor = async () => {
    setDisabledUser(true);
    await API.get("/labor/")
      .then((response) => {
        var filtrados = response.data.filter((item) => {
          return item.status;
        });
        if (filtrados.length == 0) {
          Toast.InfoMessage(
            "Información",
            "No hay roles activos.",
            "bottom-right"
          );
        } else {
          setLaborData(filtrados);
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
      });
  };

  const getEmployee = async (laborSelect) => {
    await API.get(`/user-labor/labor/${laborSelect.id}`)
      .then((response) => {
        const data = response.data;
        if (!data.length == 0) {
          var filtrados = response.data.filter((item) => {
            return !item.profile.user.isAdmin && item.profile.user.isActive && item.userLabor.status;
          });
          if (filtrados.length == 0) {
            Toast.InfoMessage(
              "Información",
              "Aún no hay empleados con ese rol.",
              "bottom-right"
            );
          } else {
            setEmployeeData(filtrados);
            setDisabledUser(false);
          }
        } else {
          Toast.InfoMessage(
            "Información",
            "Aún no hay empleados con ese rol.",
            "bottom-right"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los empleados"
        );
      });
  };

  useEffect(() => {
    getLabor();
    getActivity();
    cleanText();
    cleanErrors();
  }, []);

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
        <Container>
          <Paper
            elevation={1}
            style={{ borderRadius: 10, borderLeft: "8px solid #38BC7C" }}
          >
            <Grid
              style={{ marginBottom: 20, marginTop: 10 }}
              container
              spacing={3}
              justify="space-between"
            >
              <Grid item>
                <Typography variant="h5" style={{ marginLeft: 20 }}>
                  <b>Agrega tareas para la actividad: {activityData.name}</b>
                </Typography>
              </Grid>
              <Grid item>
                {loading ? (
                  <Button
                    disabled={true}
                    style={{
                      width: 150,
                      height: 35,
                      marginLeft: 20,
                      marginRight: 10,
                      paddingTop: 15,
                    }}
                  >
                    <BeatLoader
                      color="#38BC7C"
                      loading={loading}
                      size={11}
                      margin={5}
                    />
                  </Button>
                ) : (
                  <Button
                    startIcon={<Save />}
                    fullWidth
                    style={{
                      color: "white",
                      backgroundColor: "#38BC7C",
                      width: 150,
                      height: 35,
                      marginLeft: 20,
                      marginRight: 10,
                    }}
                    onClick={saveNewTask}
                  >
                    Guardar
                  </Button>
                )}
                <Button
                  startIcon={<KeyboardBackspace />}
                  fullWidth
                  style={{
                    marginLeft: 10,
                    color: "white",
                    backgroundColor: "#f7a900",
                    width: 150,
                    height: 35,
                    marginRight: 20,
                  }}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Regresar
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={1} style={{ borderRadius: 10 }}>
            <Grid className={classes.root}>
              <TextField
                id="name"
                className={classes.formControl}
                label="Nombre de la tarea"
                color="primary"
                size="small"
                required
                error={errorFlagName}
                helperText={textErrorName}
                name="name"
                onChange={handleChangeAdd}
                value={task && task.name}
                variant="filled"
              />
              <FormControl
                size="small"
                required
                variant="filled"
                className={classes.formControl}
                error={errorFlagSelectLabor}
              >
                <InputLabel id="role">Roles</InputLabel>
                <Select
                  id="role"
                  labelId="role"
                  color="primary"
                  name="labor"
                  value={task && task.labor}
                  onChange={handleChangeAdd}
                >
                  <MenuItem value={task.labor}>
                    <em>{task.labor.name}</em>
                  </MenuItem>
                  {laborData.map((item, i) => (
                    <MenuItem key={i} value={item}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{textErrorSelectLabor}</FormHelperText>
              </FormControl>
              <FormControl
                size="small"
                variant="filled"
                required
                className={classes.formControl}
                error={errorFlagSelectUser}
              >
                <InputLabel id="employee">Asignar empleado</InputLabel>
                <Select
                  id="employee"
                  labelId="employee"
                  color="primary"
                  name="user"
                  value={task && task.user}
                  onChange={handleChangeAdd}
                  disabled={disabledUser}
                >
                  <MenuItem value={task.user}>
                    <em>Ninguno</em>
                  </MenuItem>
                  {employeeData.map((item, i) => (
                    <MenuItem key={i} value={item.userLabor.user}>
                      {item.profile.name + " " + item.profile.lastName}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{textErrorSelectUser}</FormHelperText>
              </FormControl>
              <TextField
                id="description"
                className={classes.formControl}
                label="Descripción"
                color="primary"
                multiline
                value={task && task.description}
                rows={1}
                name="description"
                onChange={handleChangeAdd}
                error={errorFlagDescription}
                helperText={textErrorDescription}
                size="small"
                variant="filled"
              />
              <KeyboardDatePicker
                className={classes.formControl}
                margin="normal"
                required
                id="date-picker-dialog"
                label="Fecha de entrega"
                minDate={new Date()}
                maxDate={activityData.polygon.project.tentativeAt}
                minDateMessage="Fecha fuera de rango"
                maxDateMessage="Fecha fuera de rango"
                format="dd/MM/yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              {task.labor.name === "Digitalizar" ? (
                <TextField
                  className={classes.formControl}
                  id="proposedKm"
                  label="Kilómetros propuestos"
                  color="primary"
                  size="small"
                  required
                  error={errorFlag}
                  helperText={textError}
                  type="number"
                  name="proposedKm"
                  onChange={handleChangeKm}
                  variant="filled"
                />
              ) : (
                ""
              )}
            </Grid>
          </Paper>
        </Container>
        <TableEmployee />
        {/**Confirmación guardar */}
        <Dialog
          onClose={handleCloseEnd}
          aria-labelledby="Guardar tarea"
          open={openEnd}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle
            id="addActivity"
            onClose={handleCloseEnd}
            style={{ color: "white", backgroundColor: "#555454" }}
          >
            <Icon
              style={{ float: "left", marginTop: "4px", marginRight: "5px" }}
            >
              save
            </Icon>
            Guardar tarea
          </DialogTitle>
          <DialogContent dividers style={{ textAlign: "center" }}>
            <Typography paragraph variant="h6">
              ¿Estás seguro de asignar la tarea al empleado{" "}
              <b style={{ color: "gray" }}>
                {profileData.name + " " + profileData.lastName}
              </b>
              ?
            </Typography>
            <Typography variant="overline">
              Escribe <em>GUARDAR</em> para confirmar
            </Typography>
            <TextField
              id="confirm"
              color="primary"
              value={confirmEnd}
              name={"confirm"}
              fullWidth
              onChange={handleChangeEnd}
              size="small"
              variant="filled"
            />
            <DialogContentText style={{ marginTop: 10, marginBottom: 0 }}>
              Una vez que guardada no podrás cambiar la asignacion del empleado
              ni el rol
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ float: "right", margin: "auto" }}>
            {matchName ? (
              <Button startIcon={<CheckCircle />} disabled={matchName}>
                Sí, guardar
              </Button>
            ) : (
              <Button
                startIcon={<CheckCircle />}
                disabled={matchName}
                style={{ color: "white", backgroundColor: "#38BC7C" }}
                onClick={isDigitizer ? saveDigitizerData : saveGenericData}
              >
                Sí, guardar
              </Button>
            )}

            <Button
              startIcon={<Close />}
              style={{ color: "white", backgroundColor: "gray" }}
              onClick={handleCloseEnd}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default AddTask;
