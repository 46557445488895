import HomeAdministrator from './views/root/HomeAdministrator';
import HomeEmployee from './views/root/HomeEmployee';
import NotFound404 from './views/root/NotFound404';
import Login from './views/root/Login';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import LoginProvider from './security/LoginProvider';
const App = () => {
  return (
    <LoginProvider>
      <div className="app-container">
        <ReactNotification />
        <Router>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route path="/homeAdministrator">
              <HomeAdministrator />
            </Route>
            <Route path="/homeEmployee">
              <HomeEmployee />
            </Route>
            <Route path="*">
              <NotFound404 />
            </Route>
          </Switch>
        </Router>
      </div>
    </LoginProvider>

  );
}

export default App;