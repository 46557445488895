import { useReducer } from "react";
import { createContext } from "react";
import LoginReducer, { initialState } from "./LoginReducer";

//Inicializamos un contexto(variable) global donde el usuario no esta logeado
const LoginContext = createContext();

const LoginProvider = ({ children }) => {
    const [state, dispatch] = useReducer(LoginReducer, initialState);
    return (
        <LoginContext.Provider value={[state, dispatch]}>
            {children}
        </LoginContext.Provider>
    )
}
export { LoginContext }
export default LoginProvider;