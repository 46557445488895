import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography, TextField, Container, Grid, Paper,
  MenuItem, InputLabel, Select, FormControl, Button, Dialog, DialogTitle, DialogActions, Icon,
  DialogContent, DialogContentText
} from "@material-ui/core";
import { Search, Close } from "@material-ui/icons";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import API from "../../../utilities/api"
import Toast from "../../../utilities/Notifications"
import BeatLoader from "react-spinners/BeatLoader";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
    },
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    minWidth: 250,
  },
  search: {
    margin: {
      margin: theme.spacing(1),
    },
  },
}));

const DeleteEmployee = () => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [loading1, setLoading1] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [mail, setMail] = React.useState({
    Email: ''
  });
  const [dataEmployee, setDataEmployee] = React.useState({
    id: 0,
    lastName: "",
    name: "",
    phoneNumber: "",
    secondName: "",
    user: {
      createdAt: "",
      email: "",
      id: '',
      isActive: true,
      isAdmin: false,
      password: null,
      resetPasswordToken: null,
      updatedAt: null,
    }
  });

  const handleClickOpen = () => {
    if (mail.Email === '' || dataEmployee.name === '') {
      Toast.WarningMessage("¡Atención!", "Debes de buscar un empleado primero.");
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEmail = (event) => {
    setMail({ ...mail, Email: event.target.value });
  };
  const httpGetDataEmployee = async () => {
    setLoading(true);
    await API.get(`/user/email/${mail.Email}`).then((response) => {
      if (response.data === null) {
        Toast.InfoMessage("Información", "No existe ningún usuario ligado al correo ingresado.")
        setLoading(false);
      } else {
        setDataEmployee(response.data);
        setLoading(false);
      }
    }).catch((e) => {
      setLoading(false);
      console.log(e);
    })
  }

  const httpSendData = async () => {
    setLoading1(true);
    await API.patch(`/user/${dataEmployee.user.id}`, { isActive: false }).then((response) => {
      handleClose();
      Toast.SuccessMessage("¡Baja Temporal Realizada!", "Se ha dado de baja al empleado correctamente.");
      setLoading1(false);
      setDataEmployee({
        id: 0,
        lastName: "",
        name: "",
        phoneNumber: "",
        secondName: "",
        user: {
          createdAt: "",
          email: "",
          id: '',
          isActive: true,
          isAdmin: false,
          password: null,
          resetPasswordToken: null,
          updatedAt: null,
        }
      })
    }).catch((e) => {
      setLoading1(false);
      console.log(e);
    })
  }
  return (
    <>
      <Container>
        <Paper elevation={1} style={{ borderRadius: 10, borderLeft: '8px solid #D1372D' }}>
          <Grid
            style={{ marginBottom: 20, marginTop: 10 }}
            container
            spacing={3}
            justify="space-between"
          >
            <Grid item>
              <Typography variant="h5" style={{ marginLeft: 20 }}>
                <b>Información del empleado</b>
              </Typography>
              {
                loading1 ? (
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    width: 200,
                    marginLeft: 20,
                    marginRight: 10,
                    marginTop: 10
                  }}>
                    <BeatLoader color="#D1372D" loading={loading1} size={21} margin={7} />
                  </div>
                ) : (
                  <Button
                    onClick={() => {
                      handleClickOpen();
                    }}
                    startIcon={<HighlightOffIcon />}
                    style={{
                      color: "white",
                      backgroundColor: "#D1372D",
                      width: 200,
                      height: 39,
                      marginLeft: 20,
                      marginRight: 10,
                      marginTop: 10
                    }}
                  >
                    Dar de baja
                  </Button>
                )
              }
            </Grid>
            <Grid item>
              <TextField
                id="search"
                label="Correo electrónico del empleado"
                color="primary"
                type="text"
                variant="outlined"
                size="small"
                value={mail.Email}
                onChange={handleEmail}
                style={{ minWidth: 300, backgroundColor: "white", marginLeft: 10, }}
              />
              {loading ? (
                <Button
                  disabled={true}
                  style={{
                    color: "white",
                    width: 100,
                    marginLeft: 10,
                    paddingTop: 12,
                    marginRight: 20,
                    height: 39,
                  }}
                >
                  <BeatLoader color="#00A4D8" loading={loading} size={17} margin={5} />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    httpGetDataEmployee()
                  }}
                  startIcon={<Search />}
                  style={{
                    color: "white",
                    backgroundColor: "#00A4D8",
                    width: 100,
                    marginLeft: 10,
                    marginRight: 20,
                    height: 39,
                  }}
                >
                  Buscar
                </Button>
              )}



            </Grid>
          </Grid>
        </Paper>
        <Paper elevation={1} style={{ borderRadius: 10, marginTop: 20 }}>
          <Grid className={classes.root}>
            <TextField
              id="name"
              className={classes.formControl}
              label="Nombre(s)"
              color="primary"
              disabled
              size="small"
              variant="filled"
              value={dataEmployee.name}
            />
            <TextField
              id="lastname"
              size="small"
              className={classes.formControl}
              label="Primer apellido"
              color="primary"
              disabled
              variant="filled"
              value={dataEmployee.lastName}
            />
            <TextField
              id="secondname"
              className={classes.formControl}
              label="Segundo apellido"
              color="primary"
              disabled
              variant="filled"
              size="small"
              value={dataEmployee.secondName}
            />
            <TextField
              id="phoneNumber"
              className={classes.formControl}
              label="Teléfono"
              color="primary"
              disabled
              size="small"
              variant="filled"
              type="number"
              value={dataEmployee.phoneNumber}
            />
            <TextField
              id="email"
              className={classes.formControl}
              label="E-mail"
              color="primary"
              disabled
              size="small"
              variant="filled"
              value={dataEmployee.user.email}
            />
            <FormControl
              size="small"
              variant="filled"
              className={classes.formControl}
            >
              <InputLabel id="isAdmin">Cargo</InputLabel>
              <Select
                id="isAdmin"
                labelId="isAdmin"
                color="primary"
                disabled
                value={dataEmployee.user.isAdmin}
              >
                <MenuItem value={false}>Empleado</MenuItem>
                <MenuItem value={true}>Administrador</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Paper>
      </Container>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          id="addActivity"
          onClose={handleClose}
          style={{ color: "white", backgroundColor: "#555454" }}
        >
          <HighlightOffIcon style={{ float: "left", marginTop: "4px", marginRight: "5px" }} />
          Dar de baja
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            ¿Estás seguro de que quieres dar de baja al empleado {dataEmployee.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ float: "right", margin: "auto" }}>
          <Button
            startIcon={<HighlightOffIcon />}
            style={{ color: "white", backgroundColor: "#D1372D" }}
            onClick={httpSendData}
          >
            Sí, dar de baja
          </Button>
          <Button
            startIcon={<Close />}
            style={{ color: "white", backgroundColor: "gray" }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteEmployee;
