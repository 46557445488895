import Icon from "@material-ui/core/Icon";
import Container from "@material-ui/core/Container";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  Paper,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import API from "../../../utilities/api";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const EmployeeProjects = () => {
  const history = useHistory();
  const [dataCompleted, setDataCompleted] = useState([]);
  const [state, setState] = useState(false);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  let token = localStorage.getItem("token");

  const getProjects = async () => {
    let profile = await JSON.parse(localStorage.getItem("profile"));
    await API.get(`/user/${profile.user.id}/project/`)
      .then((response) => {
        response.data.map((obj, i) => {
          if (obj.project.isCompleted === true) {
            console.log(`Proyecto terminado num${i}`);
            console.log(obj);
            setDataCompleted((arrayAnterior) => [...arrayAnterior, obj]);
          } else if (obj.project.isStarted === true) {
            console.log(`Proyecto en proceso num${i}`);
            console.log(obj);
            setData((arrayAnterior) => [...arrayAnterior, obj]);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTask = (id, proyecto) => {
    history.push({
      pathname: "/homeEmployee/task",
      state: { idTask: id, name: proyecto },
    });
  };

  useEffect(async () => {
    if (data.length === 0 && dataCompleted.length === 0) {
      getProjects();
    } else {
      console.log("Cargando...");
    }
  }, [data, dataCompleted]);

  useEffect(() => {
    if (count === 2) {
      setCount(0);
      setState(false);
    }
  }, [count]);

  useEffect(async () => {
    if (!token) {
      history.replace("/");
      localStorage.clear();
    }
  }, [token]);

  return (
    <Container>
      <Paper
        elevation={1}
        style={{ borderRadius: 10, borderLeft: "8px solid #FF9A24" }}
      >
        <Grid
          style={{ marginBottom: 20, marginTop: 10 }}
          container
          spacing={3}
          justify="space-between"
        >
          <Grid item>
            <Typography variant="h5" style={{ marginLeft: 20 }}>
              <b>Lista de proyectos asignados</b>
            </Typography>
            <Grid item>
              <FormControlLabel
                style={{ marginTop: 10, marginLeft: 10 }}
                control={
                  <Switch
                    checked={state}
                    onChange={() => {
                      setState(true);
                      setCount(count + 1);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Mostrar proyectos finalizados"
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        {state === true ? (
          <>
            {dataCompleted.length === 0 ? (
              <>
                <Grid
                  style={{ marginTop: 100 }}
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Icon style={{ fontSize: 90 }}>work</Icon>
                  <Typography>Aún no tienes proyectos terminados.</Typography>
                </Grid>
              </>
            ) : (
              <>
                {dataCompleted.map((dato) => {
                  return (
                    <Card
                      key={dato.id}
                      style={{
                        minWidth: 210,
                        marginTop: 20,
                        minHeight: 210,
                        borderRadius: 10,
                      }}
                    >
                      <CardActionArea
                        onClick={() =>
                          getTask(dato.project.id, dato.project.name)
                        }
                      >
                        <CardHeader
                          style={{ backgroundColor: "#FF9A24" }}
                          subheader={
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="flex-start"
                            >
                              <Icon style={{ color: "white", marginRight: 10 }}>
                                map
                              </Icon>
                              <Typography
                                style={{
                                  color: "white",
                                  marginLeft: 25,
                                  marginRight: 25,
                                }}
                                variant="subtitle2"
                              >
                                {dato.project.name}
                              </Typography>
                            </Grid>
                          }
                        />
                        <CardContent>
                          <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                          >
                            <Typography variant="subtitle2">
                              Kilometraje limite:
                            </Typography>
                            <Typography variant="caption">
                              {dato.project.limitKm}
                            </Typography>
                            <Typography
                              style={{ marginTop: 15 }}
                              variant="subtitle2"
                            >
                              Estatus:
                            </Typography>
                            <Grid
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                            >
                              <Typography variant="caption">
                                Finalizado
                              </Typography>
                              <Icon style={{ color: "#00A4D8", fontSize: 60 }}>
                                fiber_manual_record
                              </Icon>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  );
                })}
              </>
            )}
          </>
        ) : (
          <>
            {data.length === 0 ? (
              <>
                <Grid
                  style={{ marginTop: 100 }}
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Icon style={{ fontSize: 90 }}>work</Icon>
                  <Typography>Aún no tienes proyectos asignados.</Typography>
                </Grid>
              </>
            ) : (
              <>
                {data.map((dato) => {
                  return (
                    <Card
                      key={dato.id}
                      style={{
                        minWidth: 210,
                        marginTop: 20,
                        minHeight: 210,
                        borderRadius: 10,
                      }}
                    >
                      <CardActionArea
                        onClick={() =>
                          getTask(dato.project.id, dato.project.name)
                        }
                      >
                        <CardHeader
                          style={{ backgroundColor: "#FF9A24" }}
                          subheader={
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="flex-start"
                            >
                              <Icon style={{ color: "white", marginRight: 10 }}>
                                map
                              </Icon>
                              <Typography
                                style={{
                                  color: "white",
                                  marginLeft: 25,
                                  marginRight: 25,
                                }}
                                variant="subtitle2"
                              >
                                {dato.project.name}
                              </Typography>
                            </Grid>
                          }
                        />
                        <CardContent>
                          <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                          >
                            <Typography variant="subtitle2">
                              Kilometraje limite:
                            </Typography>
                            <Typography variant="caption">
                              {dato.project.limitKm}
                            </Typography>
                            <Typography
                              style={{ marginTop: 15 }}
                              variant="subtitle2"
                            >
                              Estatus:
                            </Typography>
                            <Grid
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                            >
                              <Typography variant="caption">
                                En proceso
                              </Typography>
                              <Icon style={{ color: "#38BC7C", fontSize: 60 }}>
                                fiber_manual_record
                              </Icon>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  );
                })}
              </>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
};

export default EmployeeProjects;
