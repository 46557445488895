import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import {
  Typography,
  Icon,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  InputAdornment,
  Container,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Paper,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { AddCircle, Save, Close } from "@material-ui/icons";
import API from "../../../utilities/api";
import Toast from "../../../utilities/Notifications";
import { makeStyles } from "@material-ui/core/styles";
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles({
  colHeader: {
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
});

const Loading = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width={100 + "%"}
        height={150}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
      <Skeleton
        variant="rect"
        width={100 + "%"}
        height={320}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
    </>
  );
};

const TableCities = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [errorFlagSelect, setErrorFlagSelect] = useState(false);
  const [textError, setTextError] = useState("");
  const [textErrorSelect, setTextErrorSelect] = useState("");
  const [statesList, setStatesList] = useState([]);
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const [city, setCity] = useState({
    id: 0,
    name: "",
    state: [
      {
        id: 0,
        name: "Ninguno",
        abbreviation: "",
      },
    ],
  });

  const handleClickOpen = (cityRowData) => {
    setCity(cityRowData);
    peticionGetStates();
    cleanErrors();
    setOpen(true);
    setDisabledEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenAdd = () => {
    const emptyCity = {
      id: 0,
      name: "",
      state: { id: 0, name: "Ninguno" },
    };
    setCity(emptyCity);
    cleanErrors();
    peticionGetStates();
    setOpenAdd(true);
    setDisabledSave(false);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    setCity((prevState) => ({
      ...prevState,
      id: null,
      [name]: value,
    }));
  };

  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setCity((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const cleanErrors = () => {
    setErrorFlag(false);
    setTextError("");
    setErrorFlagSelect(false);
    setTextErrorSelect("");
  };

  const validar = () => {
    if (city.name === "" || city.name == null) {
      setErrorFlag(true);
      setTextError("Campo requerido");
    } else if (!city.name.match(/^[Ññíóáéú a-zA-Z]+$/)) {
      setErrorFlag(true);
      setTextError("Sólo se permiten letras");
    } else if (city.state.name === "Ninguno") {
      setErrorFlagSelect(true);
      setTextErrorSelect("Selecciona un estado válido");
    } else {
      return false;
    }
    return true;
  };

  const saveCity = async () => {
    setDisabledEdit(true);
    cleanErrors();
    if (validar()) {
      setDisabledEdit(false);
    } else {
      setErrorFlag(false);
      setTextError("");
      await API.put("/city/", city)
        .then((response) => {
          Toast.SuccessMessage(
            "¡Modificación realizada!",
            "Los datos han sido modificados correctamente"
          );
          peticionGet();
          setOpen(false);
        })
        .catch((error) => {
          console.log(error);
          Toast.ErrorMessage(
            "¡Ha ocurrido un error!",
            "Los sentimos, no se pudo completar el envío"
          );
        });
    }
  };

  const saveNewCity = async () => {
    setDisabledSave(true);
    cleanErrors();
    if (validar()) {
      setDisabledSave(false);
    } else {
      await API.post("/city/", city)
        .then((response) => {
          Toast.SuccessMessage(
            "¡Registro realizado!",
            "Datos guardados correctamente"
          );
          peticionGet();
          setOpenAdd(false);
        })
        .catch((error) => {
          console.log(error);
          Toast.ErrorMessage(
            "¡Ha ocurrido un error!",
            "Los sentimos, no se pudo completar el envío"
          );
        });
    }
  };

  const peticionGetStates = async () => {
    await API.get("/state/")
      .then((response) => {
        setStatesList(response.data);
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
      });
  };

  const peticionGet = async () => {
    await API.get("/city/")
      .then((response) => {
        setData(response.data);
        if (response.data.length === 0) {
          Toast.InfoMessage(
            "Información",
            "Aún no hay Ciudades en el sistema.",
            "bottom-right"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
      });
  };

  useEffect(() => {
    peticionGet();
  }, []);

  return (
    <>
      {/* Componente del botón y dialog de agregar */}
      <Container>
        <Paper
          elevation={1}
          style={{ borderRadius: 10, borderLeft: "8px solid #38BC7C" }}
        >
          <Grid
            style={{ marginBottom: 20, marginTop: 10 }}
            container
            spacing={3}
            justify="space-between"
          >
            <Grid item>
              <Typography variant="h5" style={{ marginLeft: 20 }}>
                <b>Administra ciudades utilizadas en los polígonos</b>
              </Typography>
              <Grid item>
                <Typography
                  style={{ color: "#9B9A9A", marginTop: 10, marginLeft: 20 }}
                  variant="subtitle1"
                >
                  Listado de ciudades existentes
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                startIcon={<AddCircle />}
                fullWidth
                style={{
                  color: "white",
                  backgroundColor: "#38BC7C",
                  width: 150,
                  height: 35,
                  marginRight: 20,
                }}
                onClick={handleClickOpenAdd}
              >
                Nuevo
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>

      {/**Agrgar */}
      <Dialog
        onClose={handleCloseAdd}
        aria-labelledby="Añadir actividad"
        open={openAdd}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          id="addActivity"
          onClose={handleCloseAdd}
          style={{ color: "white", backgroundColor: "#555454" }}
        >
          <Icon style={{ float: "left", marginTop: "4px", marginRight: "5px" }}>
            add_circle
          </Icon>
          Agregar
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            Agrega una nueva ciudad a un estado en específico
          </DialogContentText>
          <TextField
            id="name"
            label="Nombre"
            color="primary"
            fullWidth
            error={errorFlag}
            helperText={textError}
            required
            size="small"
            name="name"
            onChange={handleChangeAdd}
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon style={{ color: "gray" }}>location_city</Icon>
                </InputAdornment>
              ),
            }}
          />
          <br />
          <br />
          <FormControl
            error={errorFlagSelect}
            required
            size="small"
            variant="filled"
            fullWidth
          >
            <InputLabel id="state">Estado</InputLabel>
            <Select
              id="state"
              labelId="state"
              label="Estado"
              color="primary"
              name="state"
              value={city && city.state}
              onChange={handleChangeAdd}
              startAdornment={
                <InputAdornment position="start">
                  <Icon style={{ color: "gray" }}>room</Icon>
                </InputAdornment>
              }
            >
              <MenuItem value={city.state}>
                <em>{city.state.name}</em>
              </MenuItem>
              {statesList.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{textErrorSelect}</FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions style={{ float: "right", margin: "auto" }}>
          {disabledSave ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: 108,
                height: 35,
                paddingTop: 5,
              }}
            >
              <BeatLoader color="#38BC7C" loading={disabledSave} size={20} />
            </div>
          ) : (
            <Button
              startIcon={<Save />}
              style={{ color: "white", backgroundColor: "#38BC7C" }}
              onClick={saveNewCity}
            >
              Guardar
            </Button>
          )}
          <Button
            startIcon={<Close />}
            style={{ color: "white", backgroundColor: "gray" }}
            onClick={handleCloseAdd}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Container>
        {data.length === 0 ? (
          <Loading />
        ) : (
          <MaterialTable
            style={{ padding: 10, borderRadius: 10 }}
            columns={[
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Nombre
                  </Typography>
                ),
                field: "name",
                headerStyle: {
                  WebkitBorderTopLeftRadius: 10,
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Estado
                  </Typography>
                ),
                field: "state.name",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Abreviación
                  </Typography>
                ),
                field: "state.abbreviation",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
            ]}
            data={data}
            title=""
            actions={[
              //Botones de la tabla
              (rowData) => ({
                icon: () => <Icon style={{ color: "#f7a900" }}>edit</Icon>,
                tooltip: <Typography>Editar ciudad</Typography>,
                onClick: (event, rowData) => handleClickOpen(rowData),
              }),
            ]}
            //Configuraciín de las etiquetas de la tabla
            options={{
              draggable: false,
              actionsColumnIndex: -1, //orientación de los botonotes de Acciones
              headerStyle: {
                fontWeight: "bold",
                backgroundColor: "#01579b",
                color: "#FFF",
                WebkitBorderTopRightRadius: 10,
              },
            }}
            localization={{
              header: {
                actions: "Editar",
              },
              body: {
                emptyDataSourceMessage: "No hay registros aún",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Filtro",
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsPerPage: "Filas por página:",
                labelRowsSelect: "Filas",
                firstAriaLabel: "Primer página",
                firstTooltip: "Primer página",
                previousAriaLabel: "Anterior",
                previousTooltip: "Anterior",
                nextAriaLabel: "Siguiente",
                nextTooltip: "Siguiente",
                lastAriaLabel: "Última",
                lastTooltip: "Última",
              },
            }}
          />
        )}
      </Container>

      {/* Dialog de editar actividad */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="Editar actividad"
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          id="addActivity"
          onClose={handleClose}
          style={{ color: "white", backgroundColor: "#555454" }}
        >
          <Icon style={{ float: "left", marginTop: "4px", marginRight: "5px" }}>
            edit
          </Icon>
          Editar
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            Edita la información de la ciudad seleccionada
          </DialogContentText>
          <TextField
            id="name"
            label="Nombre"
            color="primary"
            fullWidth
            required
            error={errorFlag}
            helperText={textError}
            name="name"
            value={city && city.name}
            onChange={handleChangeEdit}
            size="small"
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon style={{ color: "gray" }}>location_city</Icon>
                </InputAdornment>
              ),
            }}
          />
          <br />
          <br />
          <FormControl required size="small" variant="filled" fullWidth>
            <InputLabel id="state">Estado</InputLabel>
            <Select
              id="state"
              labelId="state"
              label="Estado"
              color="primary"
              name="state"
              value={city && city.state}
              onChange={handleChangeEdit}
              startAdornment={
                <InputAdornment position="start">
                  <Icon style={{ color: "gray" }}>room</Icon>
                </InputAdornment>
              }
            >
              <MenuItem value={city.state}>{city.state.name}</MenuItem>
              {statesList.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions style={{ float: "right", margin: "auto" }}>
          {disabledEdit ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: 175,
                height: 35,
                paddingTop: 5,
              }}
            >
              <BeatLoader color="#38BC7C" loading={disabledEdit} size={20} />
            </div>
          ) : (
            <Button
              startIcon={<Save />}
              style={{ color: "white", backgroundColor: "#38BC7C" }}
              onClick={saveCity}
            >
              Guardar Cambios
            </Button>
          )}
          <Button
            startIcon={<Close />}
            style={{ color: "white", backgroundColor: "gray" }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <br />
    </>
  );
};

export default TableCities;
