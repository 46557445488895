import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  Button,
  Grid,
  Container,
  Switch,
  FormControlLabel,
  Paper,
} from "@material-ui/core";
import { AddCircle, Save, Close } from "@material-ui/icons";
import API from "../../../utilities/api";
import { useHistory } from "react-router-dom";
import Toast from "../../../utilities/Notifications";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles({
  colHeader: {
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
});

const Loading = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width={100 + "%"}
        height={150}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
      <Skeleton
        variant="rect"
        width={100 + "%"}
        height={320}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
    </>
  );
};

const TableLabors = () => {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [labor, setLabor] = useState({
    id: 0,
    name: "",
    description: "",
    status: true,
  });
  const [statusSelect, setStatusSelect] = useState({
    checkedStatus: true,
  });

  const handleClickOpen = (laborRowData) => {
    setLabor(laborRowData);
    setStatusSelect({
      ...statusSelect,
      checkedStatus: laborRowData.status,
    });
    setOpen(true);
    setDisabledEdit(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeStatus = (event) => {
    setStatusSelect({
      ...statusSelect,
      [event.target.name]: event.target.checked,
    });
  };

  const goToEmployees = (laborRowData) => {
    history.push(`/homeAdministrator/labors/employees/${laborRowData.id}`);
  };

  const saveLaborStatus = async () => {
    setDisabledEdit(true);
    const nuevo = {
      status: statusSelect.checkedStatus,
    };
    await API.patch(`/labor/${labor.id}`, nuevo)
      .then((response) => {
        Toast.SuccessMessage(
          "¡Modificación realizada!",
          "Estatus modificado correctamente"
        );
        peticionGet();
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudo completar el envío"
        );
      });
  };

  const peticionGet = async () => {
    await API.get("/labor/")
      .then((response) => {
        setData(response.data);
        if (response.data.length === 0) {
          Toast.InfoMessage(
            "Información",
            "Aún no hay roles en el sistema.",
            "bottom-right"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
      });
  };

  useEffect(() => {
    peticionGet();
  }, []);

  return (
    <>
      <Container>
        <Paper
          elevation={1}
          style={{ borderRadius: 10, borderLeft: "8px solid #01579b" }}
        >
          <Grid
            style={{ marginBottom: 20, marginTop: 10 }}
            container
            spacing={3}
          >
            <Grid item>
              <Typography variant="h5" style={{ marginLeft: 20 }}>
                <b>Consulta general de roles</b>
              </Typography>
              <Grid item>
                <Typography
                  style={{ color: "#9B9A9A", marginTop: 10, marginLeft: 20 }}
                  variant="subtitle1"
                >
                  Listado de roles existentes
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>

      <Container>
        {data.length === 0 ? (
          <Loading />
        ) : (
          <MaterialTable
            style={{ borderRadius: 20, padding: 10 }}
            columns={[
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Nombre
                  </Typography>
                ),
                field: "name",
                headerStyle: {
                  WebkitBorderTopLeftRadius: 10,
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Descripción
                  </Typography>
                ),
                field: "description",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Estatus
                  </Typography>
                ),
                field: "status",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
                render: (rowData) =>
                  rowData.status === true ? (
                    <Typography>Activo</Typography>
                  ) : (
                    <Typography>Inactivo</Typography>
                  ),
              },
            ]}
            data={data}
            title=""
            actions={[
              //Botones de la tabla
              (rowData) => ({
                icon: () => <Icon style={{ color: "#f7a900" }}>edit</Icon>,
                tooltip: <Typography>Editar estatus</Typography>,
                onClick: (event, rowData) => handleClickOpen(rowData),
              }),

              (rowData) => ({
                icon: () => (
                  <Icon style={{ color: "#00A4D8" }}>remove_red_eye</Icon>
                ),
                tooltip: <Typography>Ver Empleados</Typography>,
                onClick: (event, rowData) => goToEmployees(rowData),
              }),
            ]}
            //Configuraciín de las etiquetas de la tabla
            options={{
              draggable: false,
              actionsColumnIndex: -1, //orientación de los botonotes de Acciones
              headerStyle: {
                fontWeight: "bold",
                backgroundColor: "#01579b",
                color: "#FFF",
                WebkitBorderTopRightRadius: 10,
              },
            }}
            localization={{
              header: {
                actions: "Acciones",
              },
              body: {
                emptyDataSourceMessage: "No hay registros aún",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Filtro",
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsPerPage: "Filas por página:",
                labelRowsSelect: "Filas",
                firstAriaLabel: "Primer página",
                firstTooltip: "Primer página",
                previousAriaLabel: "Anterior",
                previousTooltip: "Anterior",
                nextAriaLabel: "Siguiente",
                nextTooltip: "Siguiente",
                lastAriaLabel: "Última",
                lastTooltip: "Última",
              },
            }}
          />
        )}
      </Container>

      {/* Dialog de editar*/}
      <Dialog
        onClose={handleClose}
        aria-labelledby="Editar actividad"
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          id="addActivity"
          onClose={handleClose}
          style={{ color: "white", backgroundColor: "#555454" }}
        >
          <Icon style={{ float: "left", marginTop: "4px", marginRight: "5px" }}>
            edit
          </Icon>
          Editar
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            Edita el estatus del rol selccionado
          </DialogContentText>
          <Grid>
            <FormControlLabel
              control={
                <Switch
                  checked={statusSelect.checkedStatus}
                  onChange={handleChangeStatus}
                  name="checkedStatus"
                  color="primary"
                />
              }
              label="Estatus"
            />
          </Grid>
        </DialogContent>
        <DialogActions style={{ float: "right", margin: "auto" }}>
          {disabledEdit ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: 175,
                height: 35,
                paddingTop: 5,
              }}
            >
              <BeatLoader color="#38BC7C" loading={disabledEdit} size={20} />
            </div>
          ) : (
            <Button
              startIcon={<Save />}
              style={{ color: "white", backgroundColor: "#38BC7C" }}
              onClick={saveLaborStatus}
            >
              Guardar Cambios
            </Button>
          )}
          <Button
            startIcon={<Close />}
            style={{ color: "white", backgroundColor: "gray" }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <br />
    </>
  );
};

export default TableLabors;
