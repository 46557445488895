import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import DateFnsUtils from "@date-io/date-fns";
import {
  Typography, Icon, Button, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, TextField, InputAdornment, Container, Grid, Paper,
} from "@material-ui/core";
import { AddCircle, Save, Close, CheckCircle } from "@material-ui/icons";
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers";
import deLocale from "date-fns/locale/es";
import API from "../../../utilities/api";
import { useHistory } from "react-router-dom";
import Toast from "../../../utilities/Notifications";
import Skeleton from "@material-ui/lab/Skeleton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { makeStyles } from "@material-ui/core/styles";
import BeatLoader from "react-spinners/BeatLoader";

const useStyles = makeStyles({
  colHeader: {
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
});

const Loading = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width={100 + "%"}
        height={150}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
      <Skeleton
        variant="rect"
        width={100 + "%"}
        height={320}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
    </>
  );
};

const TableProjects = () => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openAdd, setOpenAdd] = useState(false);
  const [disabledDelete, setDisabledDelete] = useState(false);
  const [disabledEdit, setDisabledEdit] = useState(false);
  const [disabledSave, setDisabledSave] = useState(false);
  const [selectedDateAdd, setSelectedDateAdd] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [openEnd, setOpenEnd] = useState(false);
  const [project, setProject] = useState({
    id: 0,
    name: "",
    isStarted: true,
    isCompleted: false,
    createdAt: "",
    updatedAt: "",
    limitKm: 0,
    tentativeAt: "",
  });
  const history = useHistory();

  const [errorFlagName, setErrorFlagName] = useState(false);
  const [textErrorName, setTextErrorName] = useState("");
  const [errorFlagKm, setErrorFlagKm] = useState(false);
  const [textErrorKm, setTextErrorKm] = useState("");

  const [confirmEnd, setConfirmEnd] = useState("");
  const [matchName, setMatchName] = useState(true); // true = dismatch, false = match

  const cleanErrors = () => {
    setErrorFlagName(false);
    setTextErrorName("");
    setErrorFlagKm(false);
    setTextErrorKm("");
  };

  const handleClickOpen = (projectRowData) => {
    cleanErrors();
    setProject(projectRowData);
    setOpen(true);
    setDisabledDelete(false);
    setDisabledEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenEnd = (projectRowData) => {
    setProject(projectRowData);
    setOpenEnd(true);
    setMatchName(true);
  };

  const handleCloseEnd = () => {
    setOpenEnd(false);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setProject((prevState) => ({
      ...prevState,
      tentativeAt: date,
    }));
  };

  const handleChangeEdit = (e) => {
    const { name, value } = e.target;
    setProject((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    setProject((prevState) => ({
      ...prevState,
      id: null,
      tentativeAt: selectedDateAdd,
      [name]: value,
    }));
  };

  const handleChangeEnd = (e) => {
    const { name, value } = e.target;
    setConfirmEnd(value);
    if (value === project.name) {
      setMatchName(false);
    } else {
      setMatchName(true);
    }
  };

  const handleClickOpenAdd = () => {
    setDisabledSave(false);
    const empty = {
      id: 0,
      name: "",
      isStarted: true,
      isCompleted: false,
      createdAt: "",
      updatedAt: null,
      limitKm: "",
      tentativeAt: "",
    };
    setProject(empty);
    cleanErrors();
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleDateChangeAdd = (date) => {
    setSelectedDateAdd(date);
    setProject((prevState) => ({
      ...prevState,
      id: null,
      tentativeAt: date,
    }));
  };

  const goToPolygons = (projectRowData) => {
    history.push(`/homeAdministrator/projects/polygons/${projectRowData.id}`);
  };

  const validar = () => {
    if (project.name.trim() === "" || project.name == null) {
      setErrorFlagName(true);
      setTextErrorName("Campo requerido");
    } else if (
      !project.name.match(/^[[Ññíóáéú/_0-9 a-zA-Z\-]+$/) &&
      project.name.trim() != ""
    ) {
      setErrorFlagName(true);
      setTextErrorName("Sólo se permiten letras, números, guiones y diagonal");
    } else if (project.limitKm == null || project.limitKm == "") {
      setErrorFlagKm(true);
      setTextErrorKm("Campo requerido");
    } else if (project.limitKm <= 0) {
      setErrorFlagKm(true);
      setTextErrorKm("No se admiten valores menores o igual a 0");
    } else {
      return false;
    }
    return true;
  };

  const saveEdit = async () => {
    cleanErrors();
    setDisabledEdit(true);
    setDisabledDelete(true);
    if (validar()) {
      setDisabledEdit(false);
      setDisabledDelete(false);
    } else {
      await API.put("/project/", project)
        .then((response) => {
          peticionGet();
          Toast.SuccessMessage(
            "¡Modificación realizada!",
            "Los datos han sido modificados correctamente"
          );
          setOpen(false);
        })
        .catch((error) => {
          setOpen(false);
          Toast.ErrorMessage(
            "¡Ha ocurrido un error!",
            "Los sentimos, no se pudo completar el envío"
          );
          console.log(error);
        });
    }
  };

  const endProject = async () => {
    handleCloseEnd();
    setMatchName(true);
    const nuevo = {
      isCompleted: true,
    };
    await API.patch(`/project/${project.id}`, nuevo)
      .then((response) => {
        peticionGet();
        Toast.SuccessMessage(
          "¡Modificación realizada!",
          "Se ha concluido el proyecto correctamente"
        );
        setOpenEnd(false);
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudo completar el envío"
        );
      });
  };

  const saveNewProject = async () => {
    setDisabledSave(true);
    handleCloseAdd();
    cleanErrors();
    if (validar()) {
      setDisabledSave(false);
    } else {
      await API.post("/project/", project)
        .then((response) => {
          peticionGet();
          Toast.SuccessMessage(
            "¡Registro realizado!",
            "Datos guardados correctamente"
          );
          setOpenAdd(false);
        })
        .catch((error) => {
          console.log(error);
          setOpenAdd(false);
          Toast.ErrorMessage(
            "¡Ha ocurrido un error!",
            "Los sentimos, no se pudo completar el envío"
          );
        });
    }
  };

  const deleteProject = async () => {
    setDisabledDelete(true);
    setDisabledEdit(true);
    handleClose();
    await API.delete(`/project/${project.id}`)
      .then((response) => {
        if (response === undefined) {
          Toast.WarningMessage(
            "¡Atención!",
            "No se pueden eliminar proyectos con polígonos."
          );
        } else {
          Toast.SuccessMessage(
            "¡Eliminación Realizada!",
            "Se eliminó el proyecto correctamente."
          );
          peticionGet();
        }
      })
      .catch((e) => {
        console.log(e);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "No se logró eliminar el proyecto"
        );
      });
  };

  const peticionGet = async () => {
    await API.get("/project/")
      .then((response) => {
        setData(response.data);
        if (response.data.length === 0) {
          Toast.InfoMessage(
            "Información",
            "Aún no hay proyectos en el sistema.",
            "bottom-right"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
      });
  };

  useEffect(() => {
    peticionGet();
  }, []);

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
        <Container>
          <Paper
            elevation={1}
            style={{ borderRadius: 10, borderLeft: "8px solid #38BC7C" }}
          >
            <Grid
              style={{ marginBottom: 20, marginTop: 10 }}
              container
              spacing={3}
              justify="space-between"
            >
              <Grid item>
                <Typography variant="h5" style={{ marginLeft: 20 }}>
                  <b>Administra proyectos y su contenido</b>
                </Typography>
                <Grid item>
                  <Typography
                    style={{ color: "#9B9A9A", marginTop: 10, marginLeft: 20 }}
                    variant="subtitle1"
                  >
                    Listado de proyectos existentes
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<AddCircle />}
                  fullWidth
                  style={{
                    color: "white",
                    backgroundColor: "#38BC7C",
                    width: 150,
                    height: 35,
                    marginRight: 20,
                  }}
                  onClick={handleClickOpenAdd}
                >
                  Nuevo
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Container>

        {/**Añadir proyecto */}
        <Dialog
          onClose={handleCloseAdd}
          aria-labelledby="Añadir proyecto"
          open={openAdd}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle
            id="addProject"
            onClose={handleCloseAdd}
            style={{ color: "white", backgroundColor: "#555454" }}
          >
            <Icon
              style={{ float: "left", marginTop: "4px", marginRight: "5px" }}
            >
              add_circle
            </Icon>
            Agregar
          </DialogTitle>

          <DialogContent dividers>
            <DialogContentText>Agrega los datos del proyecto</DialogContentText>
            <TextField
              id="name"
              label="Nombre"
              color="primary"
              fullWidth
              error={errorFlagName}
              helperText={textErrorName}
              required
              name={"name"}
              onChange={handleChangeAdd}
              size="small"
              variant="filled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon style={{ color: "gray" }}>font_download</Icon>
                  </InputAdornment>
                ),
              }}
            />
            <br />
            <br />
            <TextField
              id="km"
              label="Kilometraje"
              color="primary"
              fullWidth
              required
              type="number"
              name={"limitKm"}
              error={errorFlagKm}
              helperText={textErrorKm}
              onChange={handleChangeAdd}
              size="small"
              variant="filled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon style={{ color: "gray" }}>map</Icon>
                  </InputAdornment>
                ),
              }}
              inputProps={{ min: 0 }}
            />

            <KeyboardDatePicker
              margin="normal"
              required
              id="date-picker-dialog"
              label="Fecha tentativa de termino"
              format="dd/MM/yyyy"
              minDate={new Date()}
              minDateMessage="Fecha fuera de rango"
              value={selectedDateAdd}
              onChange={handleDateChangeAdd}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />

            <br />
            <br />
          </DialogContent>
          <DialogActions style={{ float: "right", margin: "auto" }}>
            {disabledSave ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: 108,
                  height: 35,
                  paddingTop: 5,
                }}
              >
                <BeatLoader color="#38BC7C" loading={disabledSave} size={20} />
              </div>
            ) : (
              <Button
                startIcon={<Save />}
                style={{ color: "white", backgroundColor: "#38BC7C" }}
                onClick={saveNewProject}
              >
                Guardar
              </Button>
            )}
            <Button
              startIcon={<Close />}
              style={{ color: "white", backgroundColor: "gray" }}
              onClick={handleCloseAdd}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>

        {/**Tabla de proyectos */}
        <Container>
          {data.length === 0 ? (
            <Loading />
          ) : (
            <MaterialTable
              style={{ padding: 10, borderRadius: 10 }}
              columns={[
                {
                  title: (
                    <Typography
                      className={classes.colHeader}
                      variant="subtitle2"
                    >
                      Nombre
                    </Typography>
                  ),
                  field: "name",
                  headerStyle: {
                    WebkitBorderTopLeftRadius: 10,
                    WebkitBorderTopRightRadius: 0,
                  },
                },
                {
                  title: (
                    <Typography
                      className={classes.colHeader}
                      variant="subtitle2"
                    >
                      Kilómetros
                    </Typography>
                  ),
                  field: "limitKm",
                  headerStyle: {
                    WebkitBorderTopRightRadius: 0,
                  },
                },
                {
                  title: (
                    <Typography
                      className={classes.colHeader}
                      variant="subtitle2"
                    >
                      Estatus
                    </Typography>
                  ),
                  field: "isCompleted",
                  headerStyle: {
                    WebkitBorderTopRightRadius: 0,
                  },
                  render: (rowData) =>
                    rowData.isCompleted === true ? (
                      <Typography>Terminado</Typography>
                    ) : (
                      <Typography>En proceso</Typography>
                    ),
                },
                {
                  title: (
                    <Typography
                      className={classes.colHeader}
                      variant="subtitle2"
                    >
                      Fecha de tentativa
                    </Typography>
                  ),
                  field: "tentativeAt",
                  headerStyle: {
                    WebkitBorderTopRightRadius: 0,
                  },
                  type: "date",
                },
              ]}
              data={data}
              title=""
              actions={[
                //Botones de la tabla
                (rowData) => ({
                  icon: () =>
                    rowData.isCompleted === false ? (
                      <Icon style={{ color: "#f7a900" }}>edit</Icon>
                    ) : (
                      <Icon style={{ color: "gray" }}>edit</Icon>
                    ),
                  tooltip:
                    rowData.isCompleted === false ? (
                      <Typography>Editar proyecto</Typography>
                    ) : (
                      <Typography>Ya concluido</Typography>
                    ),
                  disabled: rowData.isCompleted,
                  onClick: (event, rowData) => handleClickOpen(rowData),
                }),
                (rowData) => ({
                  icon: () =>
                    rowData.isCompleted === false ? (
                      <Icon style={{ color: "#38BC7C" }}>check_circle</Icon>
                    ) : (
                      <Icon style={{ color: "gray" }}>check_circle</Icon>
                    ),
                  tooltip:
                    rowData.isCompleted === false ? (
                      <Typography>Concluir</Typography>
                    ) : (
                      <Typography>Ya concluido</Typography>
                    ),
                  onClick: (event, rowData) => handleClickOpenEnd(rowData),
                  disabled: rowData.isCompleted,
                }),
                (rowData) => ({
                  icon: () =>
                    rowData.isCompleted === false ? (
                      <Icon style={{ color: "#00A4D8" }}>add_circle</Icon>
                    ) : (
                      <Icon style={{ color: "#00A4D8" }}>remove_red_eye</Icon>
                    ),
                  tooltip:
                    rowData.isCompleted === false ? (
                      <Typography>Agregar poligono</Typography>
                    ) : (
                      <Typography>Ver polígonos</Typography>
                    ),
                  onClick: (event, rowData) => goToPolygons(rowData),
                }),
              ]}
              //Configuraciín de las etiquetas de la tabla
              options={{
                draggable: false,
                actionsColumnIndex: -1, //orientación de los botonotes de Acciones
                headerStyle: {
                  fontWeight: "bold",
                  backgroundColor: "#01579b",
                  color: "#FFF",
                  WebkitBorderTopRightRadius: 10,
                },
              }}
              localization={{
                header: {
                  actions: "Acciones",
                },
                body: {
                  emptyDataSourceMessage: "No hay registros aún",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Filtro",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsPerPage: "Filas por página:",
                  labelRowsSelect: "Filas",
                  firstAriaLabel: "Primer página",
                  firstTooltip: "Primer página",
                  previousAriaLabel: "Anterior",
                  previousTooltip: "Anterior",
                  nextAriaLabel: "Siguiente",
                  nextTooltip: "Siguiente",
                  lastAriaLabel: "Última",
                  lastTooltip: "Última",
                },
              }}
            />
          )}
        </Container>

        {/**Editar proyecto */}
        <Dialog
          onClose={handleClose}
          aria-labelledby="Editar actividad"
          open={open}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle
            id="addActivity"
            onClose={handleClose}
            style={{ color: "white", backgroundColor: "#555454" }}
          >
            <Icon
              style={{ float: "left", marginTop: "4px", marginRight: "5px" }}
            >
              edit
            </Icon>
            Editar
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              Edita la información del proyecto seleccionado
            </DialogContentText>
            <TextField
              id="name"
              required
              label="Nombre"
              color="primary"
              name={"name"}
              error={errorFlagName}
              helperText={textErrorName}
              value={project && project.name}
              onChange={handleChangeEdit}
              fullWidth
              size="small"
              variant="filled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon style={{ color: "gray" }}>font_download</Icon>
                  </InputAdornment>
                ),
              }}
            />
            <br />
            <br />
            <TextField
              id="km"
              label="Kilometraje"
              type="number"
              color="primary"
              fullWidth
              required
              inputProps={{ min: 0 }}
              error={errorFlagKm}
              helperText={textErrorKm}
              name={"limitKm"}
              value={project && project.limitKm}
              onChange={handleChangeEdit}
              size="small"
              variant="filled"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon style={{ color: "gray" }}>map</Icon>
                  </InputAdornment>
                ),
              }}
            />
            <br />
            <br />
            <KeyboardDatePicker
              margin="normal"
              id="tentativeAt"
              required
              label="Fecha tentativa de termino"
              format="dd/MM/yyyy"
              minDate={new Date()}
              minDateMessage="Fecha fuera de rango"
              value={project && project.tentativeAt}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <br />
            <br />
            <Grid container direction="row">
              {disabledDelete ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: 100 + "%",
                    height: 35,
                    paddingTop: 5,
                  }}
                >
                  <BeatLoader
                    color="#D1372D"
                    loading={disabledDelete}
                    size={20}
                  />
                </div>
              ) : (
                <Button
                  onClick={() => {
                    deleteProject();
                  }}
                  startIcon={<HighlightOffIcon />}
                  style={{
                    color: "white",
                    backgroundColor: "#D1372D",
                    width: 100 + "%",
                  }}
                >
                  Eliminar proyecto
                </Button>
              )}
            </Grid>
          </DialogContent>
          <DialogActions style={{ float: "right", margin: "auto" }}>
            {disabledEdit ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: 175,
                  height: 35,
                  paddingTop: 5,
                }}
              >
                <BeatLoader color="#38BC7C" loading={disabledEdit} size={20} />
              </div>
            ) : (
              <Button
                startIcon={<Save />}
                style={{ color: "white", backgroundColor: "#38BC7C" }}
                onClick={saveEdit}
              >
                Guardar Cambios
              </Button>
            )}
            <Button
              startIcon={<Close />}
              style={{ color: "white", backgroundColor: "gray" }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>

        {/**Concluir proyecto */}
        <Dialog
          onClose={handleCloseEnd}
          aria-labelledby="Finalizar proyecto"
          open={openEnd}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle
            id="addActivity"
            onClose={handleCloseEnd}
            style={{ color: "white", backgroundColor: "#555454" }}
          >
            <Icon
              style={{ float: "left", marginTop: "4px", marginRight: "5px" }}
            >
              check_circle
            </Icon>
            Concluir proyecto
          </DialogTitle>
          <DialogContent dividers style={{ textAlign: "center" }}>
            <Typography paragraph variant="h6">
              ¿Estás seguro de finalizar el proyecto{" "}
              <b style={{ color: "gray" }}>{project && project.name}</b>?
            </Typography>
            <Typography variant="overline">
              Escribe el nombre del proyecto para confirmar
            </Typography>
            <TextField
              id="confirm"
              color="primary"
              name={"confirm"}
              fullWidth
              onChange={handleChangeEnd}
              size="small"
              variant="filled"
            />
            <DialogContentText style={{ marginTop: 10, marginBottom: 0 }}>
              Una vez que finalices el proyecto no podrás cambiar su status
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ float: "right", margin: "auto" }}>
            {matchName ? (
              <Button startIcon={<CheckCircle />} disabled={matchName}>
                Finalizar
              </Button>
            ) : (
              <Button
                startIcon={<CheckCircle />}
                disabled={matchName}
                style={{ color: "white", backgroundColor: "#38BC7C" }}
                onClick={endProject}
              >
                Finalizar
              </Button>
            )}

            <Button
              startIcon={<Close />}
              style={{ color: "white", backgroundColor: "gray" }}
              onClick={handleCloseEnd}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
        <br />
      </MuiPickersUtilsProvider>
    </>
  );
};
export default TableProjects;
