import { React, useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
    Grid, Typography, TextField, InputAdornment,
    Icon, Button, createMuiTheme, Link, Dialog, DialogActions,
    DialogContent, DialogTitle,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Logo from "../Images/logo.png";
import Toast from "../utilities/Notifications"
import API from "../utilities/api";
import { Close } from '@material-ui/icons/';
import BeatLoader from "react-spinners/BeatLoader";
import { LoginContext } from "../../security/LoginProvider";
import { types } from "../../security/LoginReducer";

const theme = createMuiTheme();
theme.typography.h3 = {
    fontSize: "2.5rem",
    "@media (min-width:600px)": {
        fontSize: "2.5rem",
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "2rem",
    },
};

const useStyles = makeStyles((theme) => ({
    cardStyle: {
        width: 450,
        height: 600,
        borderRadius: 15,
        backgroundColor: "#FFFFFF",
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        boxShadow: " 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    },
    textField: {
        width: 80 + "%",
        color: "#FF9A24",
        marginTop: 20,
    },
    header: {
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        backgroundColor: "#555454",
    },
    btn: {
        marginTop: 50,
        backgroundColor: "#FF9A24",
        color: "WHITE",
        width: 80 + "%",
        padding: 10,
        "&:hover": {
            backgroundColor: "#12A36F",
            color: "WHITE",
        },
    },
}));

const Login = () => {
    const classes = useStyles();
    const history = useHistory();

    const [state, dispatch] = useContext(LoginContext);

    const [resetOpen, setResetOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [mail, setEmail] = useState({
        email: ''
    });
    const [values, setValues] = useState({
        email: "",
        password: "",
        showPassword: false,
    });

    const handleOpenResetPassword = () => {
        setResetOpen(true);
    }
    const handleCloseResetPassword = () => {
        setResetOpen(false);
    }

    const validationProfile = async () => {
        setLoading(true);
        await API.post(`/auth/authenticate`, {
            email: values.email,
            password: values.password,
        })
            .then((response) => {
                if (!response) {
                    Toast.WarningMessage("¡Atención!", "El usuario o contraseña incorrectos, revise sus datos.")
                    localStorage.clear();
                    setLoading(false);
                } else {
                    setLoading(false);
                    localStorage.setItem("token", response.data.jwt);
                    localStorage.setItem("profile", JSON.stringify(response.data.profile));
                    handleLogin();
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChangeMail = (event) => {
        setEmail({ ...mail, email: event.target.value });
    }
    const handleLogin = async () => {
        let usuario = await JSON.parse(localStorage.getItem("profile"));
        if (usuario.user.isAdmin == true && usuario.user.isActive == true) {
            dispatch({ type: types.authLogin });
            history.push("/homeAdministrator/startScreenAdmin");
        } else if (usuario.user.isAdmin == false && usuario.user.isActive == true) {
            dispatch({ type: types.authLoginAdmin });
            history.push("/homeEmployee/startScreenEmployee");
        }
    };

    const handleResetPassword = async () => {
        setLoading2(true);
        await API.post("/user/reset_password", mail)
            .then((response) => {
                setLoading2(false);
                Toast.SuccessMessage("¡Envio Exitoso!", "Revisa tu bandeja de entrada.")
                handleCloseResetPassword();
            }).catch((e) => {
                setLoading2(false);
                console.log(e);
                Toast.ErrorMessage("Algo inesperado ocurrío...", "Falló la conexión con el host")
            })
    }

    useEffect(() => {
        localStorage.clear();
    }, []);

    return (
        <div style={{ backgroundColor: "#C4C4C4" }}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                style={{ minHeight: "100vh" }}
            >
                <div className={classes.cardStyle}>
                    <div className={classes.header}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            style={{ padding: 10 }}
                        >
                            <img src={Logo} width="120" height="120" alt="logo" />
                            <Typography style={{ color: "WHITE" }} variant="h4" gutterBottom>
                                <b>Fiberpromex</b>
                            </Typography>
                            <Typography
                                style={{ color: "WHITE", fontSize: 25 }}
                                variant="body1"
                                gutterBottom
                            >
                                Iniciar Sesión
                            </Typography>
                        </Grid>
                    </div>
                    <div style={{ paddingTop: 30 }}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <TextField
                                className={classes.textField}
                                id="email"
                                label="E-mail"
                                variant="filled"
                                value={values.email}
                                onChange={handleChange("email")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon style={{ color: "#9B9A9A" }}>email</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                className={classes.textField}
                                id="password"
                                label="Password"
                                variant="filled"
                                type={values.showPassword ? "text" : "password"}
                                value={values.password}
                                onChange={handleChange("password")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {values.showPassword ? (
                                                    <Visibility style={{ color: "#9B9A9A" }} />
                                                ) : (
                                                    <VisibilityOff style={{ color: "#9B9A9A" }} />
                                                )}
                                            </Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {
                                loading ? (
                                    <Button
                                        disabled={true}
                                        style={{
                                            height: 35,
                                            width: 80 + "%",
                                            marginTop: 50,
                                            paddingTop: 30,
                                            paddingBottom: 14
                                        }}>
                                        <BeatLoader color="#38BC7C" loading={loading} size={30} margin={7} />
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        className={classes.btn}
                                        onClick={validationProfile}
                                    >
                                        Acceder
                                    </Button>
                                )
                            }
                            <Link
                                component="button"
                                variant="body2"
                                style={{ paddingTop: 15 }}
                                onClick={() => {
                                    handleOpenResetPassword()
                                }}
                            >
                                Olvidé mi contraseña
                            </Link>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <Dialog
                onClose={handleCloseResetPassword}
                aria-labelledby="Finalizar proyecto"
                open={resetOpen}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle
                    onClose={handleCloseResetPassword}
                    style={{ color: "white", backgroundColor: "#555454" }}
                >
                    <Icon
                        style={{ float: "left", marginTop: "4px", marginRight: "5px" }}
                    >
                        email
                    </Icon>
                    Recuperación de contraseña
                </DialogTitle>
                <DialogContent dividers style={{ textAlign: "center" }}>
                    <Typography paragraph variant="h6" style={{ fontSize: 15 }}>
                        Ingrese su correo electrónico asignado por la empresa
                    </Typography>
                    <TextField
                        className={classes.textField}
                        id="email"
                        label="E-mail"
                        variant="filled"
                        value={mail.email}
                        onChange={handleChangeMail}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon style={{ color: "#9B9A9A" }}>email</Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                </DialogContent>
                <DialogActions style={{ float: "right", margin: "auto" }}>
                    {
                        loading2 ? (
                            <Button
                                disabled={true}
                                style={{
                                    height: 37,
                                    paddingTop: 12
                                }}>
                                <BeatLoader color="#38BC7C" loading={loading2} size={5} margin={4} />
                            </Button>
                        ) : (
                            <Button
                                startIcon={<Icon>send</Icon>}
                                style={{ color: "white", backgroundColor: "#38BC7C" }}
                                onClick={() => {
                                    handleResetPassword();
                                    setEmail({ email: '' });
                                }}
                            >
                                Enviar
                            </Button>
                        )
                    }

                    <Button
                        startIcon={<Close />}
                        style={{ color: "white", backgroundColor: "gray" }}
                        onClick={handleCloseResetPassword}
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default Login;