class DateFormat {
    static instance = new DateFormat();
  
    setFormat = myDate => {
      let date = '' + myDate;
      date = date.split('');
      let aux = '';
      date.every(function (e) {
        if (e == 'T') {
          date = aux;
          return false;
        } else {
          aux += e;
          return true;
        }
      });
      return date;
    };
  
    setTimeFormat = myTime => {
      myTime =  myTime.split("").reverse().join("")
      return (this.setFormat(myTime)).split("").reverse().join("")
    }
  }
  
  export default DateFormat;