const initialState = {
    logged: false,
    isAdmin: false,
}

const types = {
    authLogin: 'auth - login',
    authLoginAdmin: 'auth - loginAdmin',
    authLogout: 'auth - logout'
}

const LoginReducer = (state, action) => {
    switch (action.type) {
        case types.authLoginAdmin:
            return {
                ...state,
                logged: true,
            }
        case types.authLogin:
            return {
                ...state,
                logged: true,
                isAdmin: true
            }
        case types.authLogout:
            return {
                ...state,
                logged: false,
                isAdmin: false
            }
        default:
            return state;
    }
}
export { initialState, types }
export default LoginReducer;