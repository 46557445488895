import Icon from "@material-ui/core/Icon";
import Container from "@material-ui/core/Container";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Grid, Typography, Card, CardHeader, CardContent } from "@material-ui/core";
import Logo from "../../Images/logo.png";

const theme = createMuiTheme();
theme.typography.h4 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};
const useStyles = makeStyles((theme) => ({
  shadowCard: {
    maxWidth: 300,
    height: 200,
    marginTop: 30,
    borderRadius: 15,
  }
}));
const StartScreenEmployee = () => {
  const classes = useStyles();
  const dataCards = [
    {
      text: "Consulta los proyectos que tienes asignados para poder ver tus tareas asignadas.",
      icon: <Icon style={{ color: 'white', marginRight: 10 }}>assignment</Icon>,
      title: "Gestión de tareas"
    },
    {
      text: "Recuerda que la evidencia debe ser enviada mediante un enlace propio.",
      icon: <Icon style={{ color: 'white', marginRight: 10 }}>link</Icon>,
      title: "Envío de envidencia"
    },
    {
      text: "Marca las tareas como 'finalizado' cuando la hayas concluido por completo.",
      icon: <Icon style={{ color: 'white', marginRight: 10 }}>beenhere</Icon>,
      title: "Estatus"
    },
  ];
  return (
    <>
      <Grid container direction="column" justify="center" alignItems="center">
        <img src={Logo} width="150" />
        <ThemeProvider theme={theme}>
          <Typography variant="h4" style={{ paddingTop: 16 }}>Bienvenido Empleado</Typography>
        </ThemeProvider>
        <Typography style={{ color: "#9B9A9A", paddingTop: 5 }} variant="subtitle1" >
          FIBERPROMEX S.A.S de C.V. Administrador de proyectos
        </Typography>
      </Grid>

      <Container style={{ marginTop: 5 }}>
        <Grid container direction="row" justify="space-around" alignItems="center">
          {
            dataCards.map(item => (
              <Card key={item.title} className={classes.shadowCard}>
                <CardHeader style={{ backgroundColor: "#075E71", borderTopLeftRadius: 15, borderTopRightRadius: 15 }} subheader={
                  <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                    {item.icon}
                    <Typography style={{ color: "white" }} variant="subtitle1">{item.title}</Typography>
                  </Grid>
                } />
                <CardContent>
                  <Typography variant="body1" align="left">
                    {item.text}
                  </Typography>
                </CardContent>
              </Card>
            ))
          }
        </Grid>
      </Container>
    </>
  );
};

export default StartScreenEmployee;

