import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { Typography, Container, Grid, Button, Paper } from "@material-ui/core";
import API from "../../../utilities/api";
import { useParams, useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { KeyboardBackspace } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Toast from "../../../utilities/Notifications";

const useStyles = makeStyles({
  colHeader: {
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
});

const Loading = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width={100 + "%"}
        height={150}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
      <Skeleton
        variant="rect"
        width={100 + "%"}
        height={320}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
    </>
  );
};

const TableEmployeesLabor = () => {
  const history = useHistory();
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const { idLabor } = useParams();
  const [laborData, setLaborData] = React.useState({
    id: 0,
    name: "",
    description: "",
    status: true,
  });

  const getLabor = async () => {
    await API.get(`/labor/${idLabor}`)
      .then((response) => {
        setLaborData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const peticionGet = async () => {
    await API.get(`/user-labor/labor/${idLabor}`)
      .then((response) => {
        const data = response.data;
        if (!data.length == 0) {
          var filtrados = response.data.filter((item) => {
            return item.userLabor.status;
          });
          if (filtrados.length == 0) {
            Toast.InfoMessage(
              "Información",
              "Aún no hay empleados con ese rol.",
              "bottom-right"
            );
          } else {
            setData(filtrados);
          }
        } else {
          Toast.InfoMessage(
            "Información",
            "Aún no hay empleados con ese rol.",
            "bottom-right"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
      });
  };

  useEffect(() => {
    peticionGet();
    getLabor();
  }, []);
  return (
    <>
      <Container>
        <Paper
          elevation={1}
          style={{ borderRadius: 10, borderLeft: "8px solid #01579b" }}
        >
          <Grid
            style={{ marginBottom: 20, marginTop: 10 }}
            container
            spacing={3}
            justify="space-between"
          >
            <Grid item>
              <Typography variant="h5" style={{ marginLeft: 20 }}>
                <b>Empleados asignados al rol de: {laborData.name}</b>
              </Typography>
              <Grid item>
                <Typography
                  style={{ color: "#9B9A9A", marginTop: 10, marginLeft: 20 }}
                  variant="subtitle1"
                >
                  Listado de asignaciones
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                startIcon={<KeyboardBackspace />}
                fullWidth
                style={{
                  marginLeft: 10,
                  color: "white",
                  backgroundColor: "#f7a900",
                  width: 150,
                  height: 35,
                  marginRight: 20,
                }}
                onClick={() => {
                  history.goBack();
                }}
              >
                Regresar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Container>
        {data.length === 0 ? (
          <Loading />
        ) : (
          <MaterialTable
            style={{ borderRadius: 20, padding: 10 }}
            columns={[
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Nombre(s)
                  </Typography>
                ),
                field: "profile.name",
                headerStyle: {
                  WebkitBorderTopLeftRadius: 10,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Primer apelido
                  </Typography>
                ),
                field: "profile.lastName",
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Segundo apelido
                  </Typography>
                ),
                field: "profile.secondName",
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Correo electrónico
                  </Typography>
                ),
                field: "profile.user.email",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Estatus
                  </Typography>
                ),
                field: "profile.user.isActive",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
                render: (rowData) =>
                  rowData.profile.user.isActive ? (
                    <Typography>Activo</Typography>
                  ) : (
                    <Typography>Inactivo</Typography>
                  ),
                headerStyle: {
                  WebkitBorderTopRightRadius: 10,
                },
              },
            ]}
            data={data}
            title=""
            //Configuraciín de las etiquetas de la tabla
            options={{
              draggable: false,
              headerStyle: {
                fontWeight: "bold",
                backgroundColor: "#01579b",
                color: "#FFF",
              },
            }}
            localization={{
              body: {
                emptyDataSourceMessage:
                  "No hay empleados asignados a este rol aún",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Filtro",
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsPerPage: "Filas por página:",
                labelRowsSelect: "Filas",
                firstAriaLabel: "Primer página",
                firstTooltip: "Primer página",
                previousAriaLabel: "Anterior",
                previousTooltip: "Anterior",
                nextAriaLabel: "Siguiente",
                nextTooltip: "Siguiente",
                lastAriaLabel: "Última",
                lastTooltip: "Última",
              },
            }}
          />
        )}
      </Container>
    </>
  );
};

export default TableEmployeesLabor;
