import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { Typography, Container, Grid, Button, Paper } from "@material-ui/core";
import API from "../../../utilities/api";
import { useParams, useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { KeyboardBackspace } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Toast from "../../../utilities/Notifications";

const useStyles = makeStyles({
  colHeader: {
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
});

const Loading = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width={100 + "%"}
        height={150}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
      <Skeleton
        variant="rect"
        width={100 + "%"}
        height={320}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
    </>
  );
};

const TablePolygonsTechnology = () => {
  const history = useHistory();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [technologyData, setTechnologyData] = React.useState({});
  const { idTechnology } = useParams(); //{ idProject: 1 };

  const getTechnology = async () => {
    await API.get(`/technology/${idTechnology}`)
      .then((response) => {
        setTechnologyData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const peticionGet = async () => {
    await API.get(`/polygon/technology/${idTechnology}`)
      .then((response) => {
        setData(response.data);
        if (response.data.length === 0) {
          Toast.InfoMessage(
            "Información",
            "Aún no hay políginos relacionados a la tecnología.",
            "bottom-right"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
      });
  };

  useEffect(() => {
    peticionGet();
    getTechnology();
  }, []);

  return (
    <>
      {/* Componente del botón y dialog de agregar */}
      <Container>
        <Paper elevation={1} style={{ borderRadius: 10, borderLeft: '8px solid #01579b' }}>
          <Grid
            style={{ marginBottom: 20, marginTop: 10 }}
            container
            spacing={3}
            justify="space-between"
          >
            <Grid item>
              <Typography variant="h5" style={{ marginLeft: 20 }}>
                <b>
                  Polígonos asignados con tecnología:{" "}
                  {technologyData.name}
                </b>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                startIcon={<KeyboardBackspace />}
                fullWidth
                style={{
                  color: "white",
                  backgroundColor: "#f7a900",
                  width: 150,
                  height: 35,
                  marginRight: 20,
                }}
                onClick={() => {
                  history.goBack();
                }}
              >
                Regresar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>

      <Container>
        {data.length === 0 ? (
          <Loading />
        ) : (
          <MaterialTable
            style={{ borderRadius: 20, padding: 10 }}
            columns={[
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Nombre
                  </Typography>
                ),
                field: "name",
                headerStyle: {
                  WebkitBorderTopLeftRadius: 10,
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Kilómetros
                  </Typography>
                ),
                field: "kilometers",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Tipo
                  </Typography>
                ),
                field: "type.name",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Ciudad
                  </Typography>
                ),
                field: "city.name",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Proyecto
                  </Typography>
                ),
                field: "project.name",
                headerStyle: {
                  WebkitBorderTopRightRadius: 10,
                },
              },
            ]}
            data={data}
            title=""
            //Configuraciín de las etiquetas de la tabla
            options={{
              draggable: false,
              actionsColumnIndex: -1, //orientación de los botonotes de Acciones
              headerStyle: {
                fontWeight: "bold",
                backgroundColor: "#01579b",
                color: "#FFF",
                WebkitBorderTopRightRadius: 10,
              },
            }}
            localization={{
              header: {
                actions: "Acciones",
              },
              body: {
                emptyDataSourceMessage: "No hay registros aún",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Filtro",
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsPerPage: "Filas por página:",
                labelRowsSelect: "Filas",
                firstAriaLabel: "Primer página",
                firstTooltip: "Primer página",
                previousAriaLabel: "Anterior",
                previousTooltip: "Anterior",
                nextAriaLabel: "Siguiente",
                nextTooltip: "Siguiente",
                lastAriaLabel: "Última",
                lastTooltip: "Última",
              },
            }}
          />
        )}
      </Container>
    </>
  );
};

export default TablePolygonsTechnology;
