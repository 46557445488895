import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  Paper,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { store } from "react-notifications-component";
import API from "../utilities/api";
import Toast from "../utilities/Notifications";
import BeatLoader from "react-spinners/BeatLoader";

const Profile = () => {
  const history = useHistory();
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [validation, setValidation] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [disabledConfirm, setDisabledConfirm] = useState(true);

  const [user, setUser] = useState({
    ID: 0,
    IdUser: 0,
    Name: "",
    FirstName: "",
    SecondName: "",
    Phone: "",
    Email: "",
    Labor: false,
  });
  let token = localStorage.getItem("token");

  const getProfile = async () => {
    var usuario = await JSON.parse(localStorage.getItem("profile"));
    setUser((prevState) => ({
      ...prevState,
      ID: usuario.id,
      IdUser: usuario.user.id,
      Name: usuario.name,
      FirstName: usuario.lastName,
      SecondName: usuario.secondName,
      Phone: usuario.phoneNumber,
      Email: usuario.user.email,
      Labor: usuario.user.isAdmin,
    }));
  };

  const updateProfile = async () => {
    setLoading1(true);
    await API.put(`/profile/`, {
      name: user.Name,
      lastName: user.FirstName,
      phoneNumber: user.Phone,
      secondName: user.SecondName,
      id: user.ID,
      user: {
        id: user.IdUser,
      },
    })
      .then((response) => {
        Toast.SuccessMessage(
          "¡Actualización Realizada!",
          "Se han actualizado tu perfil correctamente."
        );
        setLoading1(false);
      })
      .catch((error) => {
        setLoading1(false);
        console.log(error);
        Toast.ErrorMessage(
          "¡Algo inesperado ocurrió!",
          "Ocurrio un error interno."
        );
      });
  };

  const updatePassword = async () => {
    setLoading(true);
    setDisabledConfirm(true);
    if (newPass === validation) {
      await API.put(`/auth/changePassword/`, {
        email: user.Email,
        password: newPass,
        currentPassword: currentPass,
      })
        .then((response) => {
          setLoading(false);
          Toast.SuccessMessage(
            "¡Actualización Realizada!",
            "Se cambió la contraseña correctamente."
          );
          setCurrentPass("");
          setNewPass("");
          setValidation("");
        })
        .catch((error) => {
          setDisabledConfirm(true);
          setLoading(false);
          console.log(error);
          Toast.ErrorMessage(
            "¡Algo inesperado ocurrió!",
            "Ocurrio un error interno."
          );
        });
    } else {
      store.addNotification({
        title: "¡Atención!",
        message:
          "Ambas contraseñas deben ser iguales y debes ingresar tu contraseña actual",
        type: "warning",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      setLoading(false);
    }
  };

  useEffect(async () => {
    if (!token) {
      history.replace("/");
      localStorage.clear();
    }
    await getProfile();
  }, [token]);

  return (
    <Container>
      <Paper
        elevation={1}
        style={{ borderRadius: 10, borderLeft: "8px solid #FF9A24" }}
      >
        <Grid
          style={{ marginBottom: 20, marginTop: 10 }}
          container
          spacing={3}
          justify="space-between"
        >
          <Grid item>
            <Typography variant="h5" style={{ marginLeft: 20 }}>
              <b>Perfil de usuario</b>
            </Typography>
            <Grid item>
              <Typography
                style={{ color: "#9B9A9A", marginTop: 10, marginLeft: 20 }}
                variant="subtitle1"
              >
                Editar datos de perfil
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Grid container direction="column" justify="center" alignItems="center">
        <Card style={{ width: 900, borderRadius: 15 }}>
          <CardContent>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <TextField
                size="small"
                style={{ width: 200, marginRight: 20, marginLeft: 20 }}
                label="Nombres (s)"
                onChange={(state) =>
                  setUser((prevState) => ({
                    ...prevState,
                    Name: state.target.value,
                  }))
                }
                value={user.Name}
                variant="filled"
              />
              <TextField
                size="small"
                onChange={(state) =>
                  setUser((prevState) => ({
                    ...prevState,
                    FirstName: state.target.value,
                  }))
                }
                style={{ width: 200, marginRight: 20, marginLeft: 20 }}
                value={user.FirstName}
                label="Primer apellido"
                variant="filled"
              />
              <TextField
                size="small"
                value={user.SecondName}
                onChange={(state) =>
                  setUser((prevState) => ({
                    ...prevState,
                    SecondName: state.target.value,
                  }))
                }
                style={{ width: 200, marginRight: 20, marginLeft: 20 }}
                label="Segundo apellido"
                variant="filled"
              />
            </Grid>
            <Grid
              style={{ marginTop: 60 }}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <TextField
                size="small"
                value={user.Phone}
                onChange={(state) =>
                  setUser((prevState) => ({
                    ...prevState,
                    Phone: state.target.value,
                  }))
                }
                style={{ width: 200, marginRight: 20, marginLeft: 20 }}
                label="Teléfono"
                variant="filled"
              />
              <TextField
                size="small"
                disabled
                value={user.Email}
                style={{ width: 200, marginRight: 20, marginLeft: 20 }}
                label="E-mail"
                variant="filled"
              />
              {user.Labor ? (
                <TextField
                  size="small"
                  disabled
                  style={{ width: 200, marginRight: 20, marginLeft: 20 }}
                  label="Cargo"
                  value="Administrador"
                  variant="filled"
                />
              ) : (
                <TextField
                  size="small"
                  disabled
                  style={{ width: 200, marginRight: 20, marginLeft: 20 }}
                  label="Cargo"
                  value="Empleado"
                  variant="filled"
                />
              )}
            </Grid>

            <Grid
              style={{ marginTop: 20, marginLeft: -25 }}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              {loading1 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 12,
                  }}
                >
                  <BeatLoader
                    color="#FF9A24"
                    loading={loading1}
                    size={12}
                    margin={4}
                  />
                </div>
              ) : (
                <Button
                  startIcon={<SaveIcon />}
                  onClick={updateProfile}
                  style={{ backgroundColor: "#FF9A24", color: "white" }}
                  variant="contained"
                >
                  Guardar cambio de perfil
                </Button>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {/*Segundo Card */}
      <Grid
        direction="column"
        container
        style={{ marginTop: 30 }}
        justify="center"
        alignItems="center"
      >
        <Card style={{ width: 900, borderRadius: 15 }}>
          <CardContent>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <TextField
                size="small"
                value={currentPass}
                onChange={(state) => setCurrentPass(state.target.value)}
                style={{ width: 200, marginRight: 20, marginLeft: 20 }}
                label="Contraseña actual"
                variant="filled"
              />
              <TextField
                size="small"
                value={newPass}
                onChange={(state) => {
                  setNewPass(state.target.value);
                  if (state.target.value.length >= 8) {
                    setDisabledConfirm(false);
                  } else {
                    setDisabledConfirm(true);
                  }
                }}
                style={{ width: 200, marginRight: 20, marginLeft: 20 }}
                label="Nueva Contraseña"
                variant="filled"
              />
              <TextField
                disabled={disabledConfirm}
                size="small"
                value={validation}
                onChange={(state) => setValidation(state.target.value)}
                style={{ width: 200, marginRight: 20, marginLeft: 20 }}
                label="Confirmar Contraseña"
                variant="filled"
              />
            </Grid>

            <Grid
              style={{ marginTop: 20, marginLeft: -25 }}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: 12,
                  }}
                >
                  <BeatLoader
                    color="#38BC7C"
                    loading={loading}
                    size={12}
                    margin={4}
                  />
                </div>
              ) : (
                <Button
                  startIcon={<SaveIcon />}
                  onClick={updatePassword}
                  style={{ backgroundColor: "#38BC7C", color: "white" }}
                  variant="contained"
                >
                  Guardar cambio de contraseña
                </Button>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Container>
  );
};

export default Profile;
