import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { Container, Typography } from "@material-ui/core";
import API from "../../../utilities/api";
import Toast from "../../../utilities/Notifications";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  colHeader: {
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
});

const Loading = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width={100 + "%"}
        height={150}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
      <Skeleton
        variant="rect"
        width={100 + "%"}
        height={320}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
    </>
  );
};

const TableEmployee = () => {
  const [data, setData] = useState([]);
  const classes = useStyles();

  const peticionGet = async () => {
    let conditions = {
      isActive: true,
      isAdmin: false,
    };
    await API.post("/profile/findBy/", conditions)
      .then((response) => {
        setData(response.data);
        if (response.data.length === 0) {
          Toast.InfoMessage(
            "Información",
            "No empleados activos en el sistema.",
            "bottom-right"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
      });
  };

  useEffect(() => {
    peticionGet();
  }, []);

  return (
    <>
      <Container>
        {data.length === 0 ? (
          <Loading />
        ) : (
          <MaterialTable
            style={{ borderRadius: 10, padding: 10, marginTop: 20 }}
            columns={[
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Nombre(s)
                  </Typography>
                ),
                field: "name",
                headerStyle: {
                  WebkitBorderTopLeftRadius: 10,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Primer apelido
                  </Typography>
                ),
                field: "lastName",
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Segundo apelido
                  </Typography>
                ),
                field: "secondName",
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Correo electrónico
                  </Typography>
                ),
                field: "user.email",

                headerStyle: {
                  WebkitBorderTopRightRadius: 10,
                },
              },
            ]}
            data={data}
            title={
              <Typography
                variant="h6"
                style={{ color: "gray", paddingLeft: 0 }}
              >
                Empleados activos
              </Typography>
            }
            //Configuraciín de las etiquetas de la tabla
            options={{
              draggable: false,
              headerStyle: {
                fontWeight: "bold",
                backgroundColor: "#01579b",
                color: "#FFF",
              },
            }}
            localization={{
              body: {
                emptyDataSourceMessage: "No hay registros aún",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Filtro",
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsPerPage: "Filas por página:",
                labelRowsSelect: "Filas",
                firstAriaLabel: "Primer página",
                firstTooltip: "Primer página",
                previousAriaLabel: "Anterior",
                previousTooltip: "Anterior",
                nextAriaLabel: "Siguiente",
                nextTooltip: "Siguiente",
                lastAriaLabel: "Última",
                lastTooltip: "Última",
              },
            }}
          />
        )}
      </Container>
    </>
  );
};

export default TableEmployee;
