import React, { useEffect, useState } from "react";
import { Pie } from 'react-chartjs-2'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import es from "date-fns/locale/es";
import PrintIcon from '@material-ui/icons/Print';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from "../../../Images/LogoPDF.png";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Skeleton from "@material-ui/lab/Skeleton";
import MaterialTable from "material-table";
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import { KeyboardBackspace } from "@material-ui/icons";
import API from "../../../utilities/api"
import Toast from "../../../utilities/Notifications"
import { useParams, useHistory } from "react-router-dom";
import {
  Grid, Typography, Icon, Dialog, DialogContent,
  Button, Paper, AppBar, Toolbar,
  IconButton, Slide
} from "@material-ui/core";
import { Search, Cached } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
    },
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    minWidth: 250,
  },
  search: {
    display: "flex",
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(3),
    alignItems: "center",
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  colHeader: {
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Loading = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width={100 + "%"}
        height={150}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
      <Skeleton
        variant="rect"
        width={100 + "%"}
        height={320}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
    </>
  );
};

const TableEmployeeReport = () => {
  const classes = useStyles();
  const { idEmployee } = useParams();
  const history = useHistory();
  const [openDetails, setOpenDetails] = React.useState(false);
  const [selectedDateStart, setSelectedDateStart] = useState(new Date());
  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date());
  const [graphList, setGraphList] = React.useState([]);
  const [reportList, setReportList] = React.useState([]);


  const columnsPDF = [
    {
      title: "Proyecto",
      field: 'project',
      headerStyle: {
        WebkitBorderTopLeftRadius: 10,
        WebkitBorderTopRightRadius: 0,
      }, render: (rowData) =>
        rowData.project === null ? (
          "S/N"
        ) : (
          rowData.project
        ),
    },
    {
      title: "Diseño",
      field: 'design',
      headerStyle: {
        WebkitBorderTopRightRadius: 0,
      },
    },
    {
      title: "Digitalización",
      field: 'digitizer',
      headerStyle: {
        WebkitBorderTopRightRadius: 0,
      },
    },
    {
      title: "Levantamiento",
      field: 'walkout',
      headerStyle: {
        WebkitBorderTopRightRadius: 0,
      },
    },
    {
      title: "Documentación",
      field: 'documentation',
      headerStyle: {
        WebkitBorderTopRightRadius: 0,
      },
    },
    {
      title: "Total KM enlace",
      field: "linkKm",
      headerStyle: {
        WebkitBorderTopRightRadius: 0,
      },
    },
    {
      title: "Total KM zona",
      field: "networkKm",
      headerStyle: {
        WebkitBorderTopRightRadius: 0,
      },
    },

    {
      title: "Total",
      field: "totalKm",
      headerStyle: {
        WebkitBorderTopRightRadius: 0,
      },
    },
  ]

  const downloadPdf = () => {
    const doc = new jsPDF()

    doc.setFontSize(40);
    doc.text("A D P R O", 75, 20, 'center')
    doc.addImage(Logo, 'JEPG', 15, 9, 23, 23)

    doc.setFontSize(12);
    doc.text('FIBERPROMEX S.A.S de C.V Administrador\rde Proyectos.', 42, 27);

    doc.setFontSize(10);
    doc.text('Domicilio Fiscal: Privada Xitle #343,\rColonia los volcanes. C.P.62350\rDelegación Cuernavaca, Mor.', 139, 13);

    doc.setFontSize(15);
    doc.text('Lista de Proyectos de un empleado', 64, 45);

    doc.autoTable({
      margin: { top: 50 },
      theme: "striped",
      columns: columnsPDF.map(col => ({ ...col, dataKey: col.field })),
      body: reportList
    })
    doc.save('Reporte_Empleado.pdf');
  }

  const handleClickOpenDetails = (projectRowData) => {
    peticionGraficaEmployee(projectRowData.projectId);
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  const [projectData, setProjectData] = React.useState({
    id: 0,
    name: "",
    lastName: "",

  });

  const handleDateChangeStart = (date) => {
    setSelectedDateStart(date);
  };

  const handleDateChangeEnd = (date) => {
    setSelectedDateEnd(date);
  };

  //GRAFICA
  const peticionGraficaEmployee = async (projectRowData) => {
    await API.get(`/user/history/${idEmployee}/project/${projectRowData}/`).then((response) => {
      setGraphList(response.data)
    }).catch((e) => {
      console.log(e);
      Toast.ErrorMessage("Algo ocurrió en el servidor", "No se logró obtener información de las graficas.");
    })
  };

  let entregada = (graphList.hasOwnProperty('Entregada') ? graphList.Entregada : 0)
  let retraso = (graphList.hasOwnProperty('Retraso') ? graphList.Retraso : 0)
  let enproceso = (graphList.hasOwnProperty('Proceso') ? graphList.Proceso : 0)


  const dateRange = async () => {
    let isNullP = [];
    const rangeDate = {
      min: selectedDateStart,
      max: selectedDateEnd
    }
    await API.post(`/user/${idEmployee}/report/`, rangeDate)
      .then((response) => {
        if (selectedDateStart > selectedDateEnd) {
          Toast.ErrorMessage("Información", "No se puede buscar con fecha mayor a la inicial.")
        } else {
          response.data.map((item) => {
            if (item.projectId === 0) {
              Toast.InfoMessage(
                "Información",
                "Aún no hay proyectos registrados.",
              );
            } else {
              item.design = item.design === null ? 0.00 : item.design.toFixed(2);
              item.digitizer = item.digitizer === null ? 0.00 : item.digitizer.toFixed(2);
              item.walkout = item.walkout === null ? 0.00 : item.walkout.toFixed(2);
              item.documentation = item.documentation === null ? 0.00 : item.documentation.toFixed(2);
              item.linkKm = item.linkKm === null ? 0.00 : item.linkKm.toFixed(2);
              item.networkKm = item.networkKm === null ? 0.00 : item.networkKm.toFixed(2);
              item.totalKm = item.totalKm === null ? 0.00 : item.totalKm.toFixed(2);
              isNullP.push(item)
            }
          })
          setReportList(isNullP);
          Toast.InfoMessage(
            "Consulta Realizada",
            "Revisa los nuevos datos.",
          );
        }
        if (response.data.length === null) {
          Toast.InfoMessage(
            "Información",
            "No se encontro proyectos en esas fechas.",
          );
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudo completar el envío"
        );
      });
  };

  const getProject = async () => {
    await API.get(`/profile/${idEmployee}`)
      .then((response) => {
        setProjectData(response.data);
      })
      .catch((error) => {
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
        console.log(error);
      });
  };

  const peticionEmployeeReport = async () => {
    let isNullP = [];
    await API.get(`/user/${idEmployee}/report/`)
      .then((response) => {
        response.data.map((item) => {
          if (item.projectId === 0) {
            Toast.InfoMessage(
              "Información",
              "Aún no hay proyectos registrados.",
            );
          } else {
            item.design = item.design === null ? 0.00 : item.design.toFixed(2);
            item.digitizer = item.digitizer === null ? 0.00 : item.digitizer.toFixed(2);
            item.walkout = item.walkout === null ? 0.00 : item.walkout.toFixed(2);
            item.documentation = item.documentation === null ? 0.00 : item.documentation.toFixed(2);
            item.linkKm = item.linkKm === null ? 0.00 : item.linkKm.toFixed(2);
            item.networkKm = item.networkKm === null ? 0.00 : item.networkKm.toFixed(2);
            item.totalKm = item.totalKm === null ? 0.00 : item.totalKm.toFixed(2);
            isNullP.push(item)
          }
        })
        setReportList(isNullP);
        if (response.data.length === 0) {
          Toast.InfoMessage(
            "Información",
            "Aún no hay proyectos registrados.",
          );
        } else if (response.data.length > 0) {
          Toast.SuccessMessage("¡Datos refrescados!", "Revisa si es que hay algo nuevo.")
        }
      })
      .catch((error) => {
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
        console.log(error);
      });
  };

  useEffect(() => {
    getProject();
    peticionEmployeeReport();
  }, []);

  return (
    <>

      <Container>
        <Paper elevation={1} style={{ borderRadius: 10, borderLeft: '8px solid #01579b' }}>
          <Grid
            style={{ marginBottom: 20, marginTop: 10 }}
            container
            spacing={3}
            justify="space-between"
          >
            <Grid item>
              <Typography variant="h5" style={{ marginLeft: 20 }}>
                <b>
                  Proyectos que participó el empleado:{" "}
                  {projectData.name}{" "}{projectData.lastName}
                </b>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper elevation={1} style={{ borderRadius: 10, borderLeft: '8px solid #01579b' }}>
          <Grid
            style={{ marginBottom: 20, marginTop: 10 }}
            container
            spacing={3}
            justify="space-between"
          >
            <Grid item>
              <Typography variant="h5" style={{ marginLeft: 20 }}>
                <b>Consulta en un rango de fechas</b>
              </Typography>
              <Grid >
                <Button
                  onClick={() => {
                    peticionEmployeeReport()
                  }}
                  startIcon={<Cached />}
                  style={{
                    color: "white",
                    backgroundColor: "#00A4D8",
                    width: 200,
                    height: 39,
                    marginLeft: 20,
                    marginRight: 10,
                    marginTop: 10
                  }}
                >
                  Refrescar datos
                </Button>

              </Grid>
              <Grid >
                <Button
                  startIcon={<KeyboardBackspace />}
                  fullWidth
                  style={{
                    marginLeft: 240,
                    marginTop: -61,
                    color: "white",
                    backgroundColor: "#f7a900",
                    width: 150,
                    height: 39,
                    marginRight: 20,
                  }}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Regresar
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                <form noValidate autoComplete="off">
                  <div>
                    <KeyboardDatePicker
                      registerLocale="es"
                      format="yyyy/MM/dd"
                      margin="normal"
                      id="date-picker-inline"
                      label="Fecha de Inicio"
                      value={selectedDateStart}
                      onChange={handleDateChangeStart}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      style={{ marginRight: 10, marginTop: 0 }}
                    />

                    <KeyboardDatePicker
                      format="yyyy/MM/dd"
                      margin="normal"
                      id="date-picker-inline"
                      label="Fecha de Fin"
                      value={selectedDateEnd}
                      onChange={handleDateChangeEnd}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      style={{ marginTop: 0 }}
                    />

                    <Button
                      startIcon={<Search />}
                      style={{ marginTop: 12, marginRight: 20, marginLeft: 10, color: "white", backgroundColor: "#38BC7C" }}
                      onClick={dateRange}
                    >
                      Consultar
                    </Button>
                  </div>
                </form>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Paper>
        {reportList.length === 0 ? (
          <Loading />
        ) : (
          <MaterialTable
            style={{ borderRadius: 20, padding: 10 }}
            columns={[
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Proyecto
                  </Typography>
                ),
                field: 'project',
                headerStyle: {
                  WebkitBorderTopLeftRadius: 10,
                  WebkitBorderTopRightRadius: 0,
                }, render: (rowData) =>
                  rowData.project === null ? (
                    "S/N"
                  ) : (
                    rowData.project
                  ),
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Diseño
                  </Typography>
                ),
                field: 'design',
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Digitalización
                  </Typography>
                ),
                field: 'digitizer',
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Levantamiento
                  </Typography>
                ),
                field: 'walkout',
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Documentación
                  </Typography>
                ),
                field: 'documentation',
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Kilometros de enlace
                  </Typography>
                ),
                field: "linkKm",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Kilometros
                    de zona
                  </Typography>
                ),
                field: "networkKm",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },

              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Total
                  </Typography>
                ),
                field: "totalKm",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
            ]}
            data={reportList}
            title=""
            //Configuraciín de las etiquetas de la tabla
            actions={[
              {
                icon: () => <PrintIcon />,// icon export
                tooltip: "Exportar PDF",
                onClick: () => downloadPdf(),
                isFreeAction: true
              },
              (rowData) => ({
                icon: () => <Icon style={{ color: "#00A4D8" }}>more_vert</Icon>,
                tooltip: <Typography>Detalles</Typography>,
                onClick: (event, rowData) => { handleClickOpenDetails(rowData) },
              }),
            ]}
            //Configuraciín de las etiquetas de la tabla
            options={{
              draggable: false,
              actionsColumnIndex: -1, //orientación de los botonotes de Acciones
              headerStyle: {
                fontWeight: "bold",
                backgroundColor: "#01579b",
                color: "#FFF",
                WebkitBorderTopRightRadius: 10,
              },
            }}
            localization={{
              header: {
                actions: "Acciones",
              },
              body: {
                emptyDataSourceMessage: "No hay registros aún",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Filtro",
                exportTitle: "Imprimir",
                exportPDFName: "Exportar a PDF",
                exportCSVName: "Exportar a CSV",
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsPerPage: "Filas por página:",
                labelRowsSelect: "Filas",
                firstAriaLabel: "Primer página",
                firstTooltip: "Primer página",
                previousAriaLabel: "Anterior",
                previousTooltip: "Anterior",
                nextAriaLabel: "Siguiente",
                nextTooltip: "Siguiente",
                lastAriaLabel: "Última",
                lastTooltip: "Última",
              },
            }}
          />
        )}
        <Dialog
          fullScreen open={openDetails} onClose={handleCloseDetails} TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar} style={{ backgroundColor: "#01579b" }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleCloseDetails} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Cerrar
              </Typography>
            </Toolbar>
          </AppBar>

          <DialogContent dividers>

            <Container style={{ width: 600, height: 600 }}>
              <Grid container direction="row" justify="space-evenly" alignItems="center">
                <Typography variant="h5" style={{ paddingTop: 10, paddingBottom: 5 }}>
                  <b>Historial del usuario</b>
                </Typography>
              </Grid>
              <Pie
                width={5}
                height={5}
                data={
                  {
                    labels: ['Entregada', 'Retraso', 'Proceso'],
                    datasets: [{
                      data: [entregada, retraso, enproceso],
                      backgroundColor: [
                        '#38BC7C',
                        '#FF7459',
                        '#00A4D8'],
                    },
                    ],
                  }
                }
              >
              </Pie>
            </Container>
          </DialogContent>
        </Dialog>
      </Container>
    </>
  );
};

export default TableEmployeeReport;
