import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Drawer, CssBaseline, AppBar, Toolbar, Typography, IconButton, Container } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LogoFiber from "../Images/logo.png";
import StartScreenEmployee from "../components/employee/StartScreenEmployee";
import EmployeeOptions from "./EmployeeOptions";
import EmployeeProjects from "../components/employee/tasks/EmployeeProjects";
import EmployeeTaskDetails from "../components/employee/tasks/TaskDeltails";
import Profile from "./profile"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import EmployeeTask from "../components/employee/tasks/EmployeeTask";
import { LoginContext } from "../../security/LoginProvider";
const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        background: "#555454",
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 2),
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
        background: "#FF9A24",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(100),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        backgroundColor: '#f5f5f5',
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    toolbar: theme.mixins.toolbar,
}));

const HomeEmployee = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [state, dispatch] = useContext(LoginContext);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const { logged, isAdmin } = state;
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                style={{ backgroundColor: "#555454" }}
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography style={{ flexGrow: 1 }}></Typography>
                    <Typography variant="subtitle1"> Fiberpromex S.A.S. de C.V.</Typography>
                    <img src={LogoFiber} alt="logo" width="32" style={{ marginLeft: 10 }} />
                </Toolbar>
            </AppBar>

            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                {/* Boton que cierra el drawer */}
                <div>
                    <IconButton onClick={handleDrawerClose} style={{ marginTop: 8 }}>
                        {theme.direction === "ltr" ? (
                            <ChevronLeftIcon style={{ color: "white" }} />
                        ) : (
                            <ChevronRightIcon style={{ color: "white" }} />
                        )}
                    </IconButton>
                </div>
                {logged && !isAdmin ? <EmployeeOptions /> : <Redirect to="/" />}
            </Drawer>
            <main className={clsx(classes.content, { [classes.contentShift]: open })}>
                <div className={classes.toolbar} /> {/* fix del toolbar NO QUITAR*/}
                <Switch>
                    <Route path="/homeEmployee/startScreenEmployee" component={StartScreenEmployee} />
                    <Route path="/homeEmployee/projects" component={EmployeeProjects} />
                    <Route path="/homeEmployee/task" component={EmployeeTask} />
                    <Route path="/homeEmployee/taskDetails" component={EmployeeTaskDetails} />
                    <Route path="/homeEmployee/profile" component={Profile} />
                    <Route component={StartScreenEmployee} />
                </Switch>
            </main>
        </div>
    )
}
export default HomeEmployee;