import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography, TextField, Container, Grid, Paper, MenuItem,
  DialogContent, DialogContentText, DialogTitle, InputLabel,
  Select, FormControl, AppBar, Toolbar, Dialog,
  IconButton, FormControlLabel, Switch, DialogActions, Button, Card, Icon, Slide,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { Save, Search, Close } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import Toast from "../../../utilities/Notifications";
import BeatLoader from "react-spinners/BeatLoader";
import API from "../../../utilities/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
    },
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    minWidth: 250,
  },
  search: {
    margin: {
      margin: theme.spacing(1),
    },
  },
  board: {
    textAlign: "center",
    borderRadius: 20,
  },
  appBar: {
    position: "relative",
    backgroundColor: "#555454",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateEmployee = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [emailExist, setEmailExist] = React.useState(false);
  const [labors, setLabors] = React.useState([]);
  const [data, setData] = React.useState([]);

  const [value, setValue] = useState("");
  const countRef = useRef(0);

  ////////////////
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openStatus, setOpenStatus] = React.useState(false);
  const [uniqueLabor, setUniqueLabor] = React.useState(1);
  const [levelLabor, setLevelLabor] = React.useState(0);
  const [httpLabors, setHttpLabors] = React.useState([]);
  const [httpData, setHttpData] = React.useState([]);
  const [httpSendLabors, setHttpSendLabors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loading1, setLoading1] = React.useState(false);
  const [laborEmployee, setLaborEmployee] = React.useState({
    id: 0,
    user: { id: 0 },
    status: true,
    level: 0,
  });

  const [statusSelect, setStatusSelect] = React.useState({
    checkedStatus: false,
  });

  const [statusEmployeeSelect, setEmployeeStatusSelect] = React.useState({
    checkedStatus: false,
  });

  const handleChangeStatus = (event) => {
    setStatusSelect({
      ...statusSelect,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeEmployeeStatus = (event) => {
    setEmployeeStatusSelect({
      ...statusEmployeeSelect,
      [event.target.name]: event.target.checked,
    });
  };

  const [labor, setLabor] = React.useState({
    user: {
      id: "",
    },
  });

  let objLabors = {
    user: {
      id: labor.user.id,
    },
    labor: {
      id: uniqueLabor,
      name: "",
    },
    status: true,
    level: levelLabor,
  };

  const [mail, setMail] = React.useState({
    Email: "",
  });
  const [dataEmployee, setDataEmployee] = React.useState({
    id: 0,
    lastName: "",
    name: "",
    phoneNumber: "",
    secondName: "",
    user: {
      createdAt: "",
      email: "",
      id: "",
      isActive: true,
      isAdmin: false,
      password: null,
      resetPasswordToken: null,
      updatedAt: null,
    },
  });

  const handleChange = (event) => {
    setUniqueLabor(event.target.value);
  };

  const handleChangeLevel = (event) => {
    setLevelLabor(event.target.value);
  };

  const handleDelete = (id) => {
    const newList = httpSendLabors.filter((item) => item.labor.id !== id);
    setHttpSendLabors(newList);
  };

  const handleClickOpen = (ed) => {
    labor.user.id = ed;
    setLabors({ ...labor, user: { id: ed } });
    setHttpSendLabors(labors);
    httpGetLaborsAux();
    setOpen(true);
  };

  const handleClickOpenStatus = (laborRowData) => {
    setLaborEmployee(laborRowData);
    setOpenStatus(true);
    setStatusSelect({
      ...statusSelect,
      checkedStatus: laborRowData.status,
    });
  };

  const changeLaborStatus = async () => {
    const nuevo = {
      status: statusSelect.checkedStatus,
    };
    await API.patch(`/user-labor/${laborEmployee.id}`, nuevo)
      .then((response) => {
        Toast.SuccessMessage(
          "¡Modificación realizada!",
          "Estatus modificado correctamente"
        );
        peticionGet();
        setOpenStatus(false);
        httpGetLabors(laborEmployee.user.id);
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudo completar el envío"
        );
      });
  };

  const handleChangeEmployeeLevel = async (laborRowData) => {
    setLaborEmployee(laborRowData);
    setOpenEdit(true);
  };

  const saveLevel = async () => {
    const level = {
      level: laborEmployee.level,
    };

    // alert("LABOR EMPLY" + laborEmployee.id);
    await API.patch(`/user-labor/${laborEmployee.id}`, level)
      .then((response) => {
        Toast.SuccessMessage(
          "¡Modificación realizada!",
          "Nivel modificado correctamente"
        );
        peticionGet();
        setOpenEdit(false);
        httpGetLabors(laborEmployee.user.id);
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudo completar el envío"
        );
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseWithouSaving = () => {
    setOpen(false);
    setLevelLabor(0);
    setUniqueLabor(1);
    setHttpSendLabors([]);
    setHttpLabors([]);
    setLaborEmployee([]);
    httpGetDataEmployee();
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCloseDisable = () => {
    setOpenStatus(false);
  };

  const handleChangeValues = (prop) => (event) => {
    setDataEmployee({ ...dataEmployee, [prop]: event.target.value });
  };

  const handleChangeValuesLabor = (prop) => (event) => {
    setLaborEmployee({ ...laborEmployee, [prop]: event.target.value });
  };

  const httpPostEmployee = async () => {
    setLoading1(true)
    if (emailExist == false) {
      Toast.WarningMessage(
        "¡Atención!",
        "Debes de buscar un empleado primero."
      );
      setLoading1(false)
    } else {
      await API.put("/profile/", dataEmployee)
        .then((response) => {
          const status = {
            isActive: statusEmployeeSelect.checkedStatus,
          };
          API.patch(`/user/${dataEmployee.id}`, status)
            .then((response) => {
              Toast.SuccessMessage(
                "¡Modificación Realizada!",
                "Los datos han sido modificados correctamente."
              );
              history.push("/homeAdministrator/generalInformation");
              setLoading1(false)
            })
            .catch((e) => {
              setLoading1(false)
              console.log(e);
            });
        })
        .catch((e) => {
          setLoading1(false)
          console.log(e);
        });
    }
  };

  const peticionGet = async () => {
    await API.get("/user-labor/")
      .then((response) => {
        setData(response.data);
        if (response.data.length === 0) {
          Toast.InfoMessage(
            "Información",
            "Aún no hay roles en el sistema.",
            "bottom-right"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudieron recuperar los datos"
        );
      });
  };

  const httpSendData = async () => {
    if (httpSendLabors.length === 0) {
      Toast.WarningMessage(
        "¡Atención!",
        "Debes agregar al menos un rol al empleado."
      );
    } else {
      await API.post("/user-labor/multisave", httpSendLabors)
        .then((response) => {
          Toast.SuccessMessage(
            "¡Inserción Realizada!",
            "Se han guardado los datos correctamente."
          );
          httpGetLabors(dataEmployee.user.id);
          handleClose();
        })
        .catch((e) => {
          console.log(e);
          Toast.ErrorMessage(
            "Algo ocurrió en el servidor",
            "No se logró obtener información de los roles."
          );
        });
    }
  };

  const handleEmail = (event) => {
    setMail({ ...mail, Email: event.target.value });
  };

  const handleChangeIsAdmin = (event) => {
    setIsAdmin(event.target.value);
  };

  const httpGetDataEmployee = async () => {
    setLoading(true);
    await API.get(`/user/any-email/${mail.Email}`)
      .then((response) => {
        if (!response.data) {
          Toast.WarningMessage(
            "¡Atención!",
            "Correo electrónico no encontrado, verifique sus datos."
          );
          setLoading(false);
        } else {
          setDataEmployee(response.data);
          setEmailExist(true);
          httpGetLabors(response.data.user.id);
          setEmployeeStatusSelect({
            ...statusEmployeeSelect,
            checkedStatus: response.data.user.isActive,
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const httpGetLabors = async (ide) => {
    await API.get(`/user-labor/any-user/${ide}`)
      .then((response) => {
        setLabors(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //testing

  const httpGetLaborsAux = async () => {
    await API.get("/labor/")
      .then((response) => {
        setHttpLabors(response.data);
      })
      .catch((e) => {
        console.log(e);
        Toast.ErrorMessage(
          "Algo ocurrió en el servidor",
          "No se logró obtener información de los roles."
        );
      });
  };

  useEffect(() => {
    if (countRef === 0) {
      httpGetDataEmployee();
    }
    httpGetLaborsAux();
  }, [httpLabors, labors]);

  return (
    <>
      <Container>
        <Paper
          elevation={1}
          style={{ borderRadius: 10, borderLeft: "8px solid #38BC7C" }}
        >
          <Grid
            style={{ marginBottom: 20, marginTop: 10 }}
            container
            spacing={3}
            justify="space-between"
          >
            <Grid item>
              <Grid item>
                <Typography variant="h5" style={{ marginLeft: 20 }}>
                  <b>Editar datos</b>
                </Typography>
                <Grid item>
                  {
                    loading1 ? (
                      <div style={{
                        display: "flex",
                        justifyContent: "center",
                        width: 200,
                        height: 39,
                        marginLeft: 20,
                        marginRight: 10,
                        marginTop: 10,
                      }}>
                        <BeatLoader color="#38BC7C" loading={loading1} size={21} margin={7} />
                      </div>
                    ) : (
                      <Button
                        onClick={httpPostEmployee}
                        startIcon={<Save />}
                        fullWidth
                        style={{
                          color: "white",
                          backgroundColor: "#38BC7C",
                          width: 200,
                          height: 39,
                          marginLeft: 20,
                          marginRight: 10,
                          marginTop: 10,
                        }}
                      >
                        Guardar cambios
                      </Button>
                    )
                  }
                </Grid>
              </Grid>

            </Grid>
            <Grid item>
              <TextField
                id="search"
                label="Correo electrónico del empleado"
                color="primary"
                type="text"
                variant="outlined"
                size="small"
                value={mail.Email}
                onChange={handleEmail}
                style={{
                  minWidth: 300,
                  backgroundColor: "white",
                  marginLeft: 10,
                }}
              />

              {
                loading ? (
                  <Button
                    disabled={true}
                    style={{
                      color: "white",
                      width: 100,
                      marginLeft: 10,
                      paddingTop: 12,
                      marginRight: 20,
                      height: 39,
                    }}
                  >
                    <BeatLoader color="#00A4D8" loading={loading} size={17} margin={5} />
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      httpGetDataEmployee();
                    }}
                    startIcon={<Search />}
                    style={{
                      color: "white",
                      backgroundColor: "#00A4D8",
                      width: 100,
                      marginLeft: 10,
                      marginRight: 20,
                      height: 39,
                    }}
                  >
                    Buscar
                  </Button>
                )
              }
            </Grid>
          </Grid>
        </Paper>

        <Paper
          elevation={1}
          style={{ borderRadius: 20, marginTop: 20, marginBottom: 20 }}
        >
          <Grid className={classes.root}>
            <TextField
              id="name"
              className={classes.formControl}
              label="Nombre(s)"
              color="primary"
              size="small"
              variant="filled"
              value={dataEmployee.name}
              onChange={handleChangeValues("name")}
            />
            <TextField
              id="lastname"
              size="small"
              className={classes.formControl}
              label="Primer apellido"
              color="primary"
              variant="filled"
              value={dataEmployee.lastName}
              onChange={handleChangeValues("lastName")}
            />
            <TextField
              id="secondname"
              className={classes.formControl}
              label="Segundo apellido"
              color="primary"
              variant="filled"
              size="small"
              value={dataEmployee.secondName}
              onChange={handleChangeValues("secondName")}
            />
            <TextField
              id="phoneNumber"
              className={classes.formControl}
              label="Teléfono"
              color="primary"
              size="small"
              variant="filled"
              type="number"
              value={dataEmployee.phoneNumber}
              onChange={handleChangeValues("phoneNumber")}
            />
            <TextField
              id="email"
              className={classes.formControl}
              label="Correo Electrónico"
              color="primary"
              size="small"
              disabled
              variant="filled"
              value={dataEmployee.user.email}
            />

            <FormControl
              size="small"
              variant="filled"
              className={classes.formControl}
            >
              <InputLabel id="isAdmin">Cargo</InputLabel>
              <Select
                id="isAdmin"
                labelId="isAdmin"
                color="primary"
                value={isAdmin}
                disabled
                onChange={handleChangeIsAdmin}
              >
                <MenuItem value={false}>Empleado</MenuItem>
                <MenuItem value={true}>Administrador</MenuItem>
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Switch
                  checked={statusEmployeeSelect.checkedStatus}
                  onChange={handleChangeEmployeeStatus}
                  name="checkedStatus"
                  color="primary"
                />
              }
              label="Estatus"
            />
          </Grid>
        </Paper>

        <MaterialTable
          style={{ borderRadius: 20, padding: 10, marginTop: 20 }}
          columns={[
            {
              title: "Rol",
              field: "labor.name",
              headerStyle: {
                WebkitBorderTopLeftRadius: 10,
                WebkitBorderTopRightRadius: 0,
              },
            },
            {
              title: "Nivel (0-3)",
              field: "level",
              headerStyle: {
                WebkitBorderTopRightRadius: 0,
              },
            },
            {
              title: (
                <Typography className={classes.colHeader} variant="subtitle2">
                  Estatus
                </Typography>
              ),
              field: "status",
              headerStyle: {
                WebkitBorderTopRightRadius: 0,
              },
              render: (rowData) =>
                rowData.status === true ? (
                  <Typography>Activo</Typography>
                ) : (
                  <Typography>Inactivo</Typography>
                ),
            },
          ]}
          data={labors}
          title="Roles del empleado"
          //Configuracónn de las etiquetas de la tabla
          actions={[
            //Botones de la tabla
            (rowData) => ({
              icon: () =>
                labors.length >= 4 ? (
                  <Icon style={{ color: "gray" }}>add_circle</Icon>
                ) : (
                  <Icon style={{ color: "#38BC7C" }}>add_circle</Icon>
                ),
              tooltip:
                labors.length >= 4 ? (
                  <Typography>Sin más roles disponibles</Typography>
                ) : (
                  <Typography>Añadir rol</Typography>
                ),

              disabled: labors.length >= 4,

              onClick: (event, rowData) => handleClickOpen(rowData.user.id),
            }),
            (rowData) => ({
              icon: () => <Icon style={{ color: "#FD7900" }}>edit</Icon>,
              tooltip: <Typography>Modificar Nivel</Typography>,
              //pendiente en back
              onClick: (event, rowData) => handleChangeEmployeeLevel(rowData),
            }),
            (rowData) => ({
              icon: () => <Icon style={{ color: "#D1372D" }}>cancel</Icon>,
              tooltip: <Typography>Desactivar Rol</Typography>,
              onClick: (event, rowData) => handleClickOpenStatus(rowData),
            }),
          ]}
          options={{
            paging: false,
            draggable: false,
            actionsColumnIndex: -1, //orientación de los botonotes de Acciones
            headerStyle: {
              fontWeight: "bold",
              backgroundColor: "#01579b",
              color: "#FFF",
              WebkitBorderTopRightRadius: 10,
            },
          }}
          localization={{
            header: {
              actions: "Acciones",
            },
            body: {
              emptyDataSourceMessage: "No hay roles asignados aún",
            },
            toolbar: {
              searchTooltip: "Busdar",
              searchPlaceholder: "Filtro",
            },
          }}
        />
      </Container>

      {/* Dialog modificar nivel */}
      <Dialog
        onClose={handleCloseEdit}
        aria-labelledby="Editar actividad"
        open={openEdit}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          id="addActivity"
          onClose={handleCloseEdit}
          style={{ color: "white", backgroundColor: "#555454" }}
        >
          <Icon style={{ float: "left", marginTop: "4px", marginRight: "5px" }}>
            edit
          </Icon>
          Editar
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>Edita el nivel del empleado</DialogContentText>
          <Select
            style={{ width: 20 + "%", marginBottom: 20, marginLeft: 20 }}
            labelId="level"
            id="level"
            value={laborEmployee.level}
            onChange={handleChangeValuesLabor("level")}
          >
            <MenuItem value={3}>Nivel 3</MenuItem>
            <MenuItem value={2}>Nivel 2</MenuItem>
            <MenuItem value={1}>Nivel 1</MenuItem>
            <MenuItem value={0}>Nivel 0</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions style={{ float: "right", margin: "auto" }}>
          <Button
            startIcon={<Save />}
            style={{ color: "white", backgroundColor: "#38BC7C" }}
            onClick={saveLevel}
          >
            Guardar Cambios
          </Button>
          <Button
            startIcon={<Close />}
            style={{ color: "white", backgroundColor: "gray" }}
            onClick={handleCloseEdit}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog desactivar rol */}

      <Dialog
        onClose={handleCloseDisable}
        aria-labelledby="Editar actividad"
        open={openStatus}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          id="addActivity"
          onClose={handleCloseDisable}
          style={{ color: "white", backgroundColor: "#555454" }}
        >
          <Icon style={{ float: "left", marginTop: "4px", marginRight: "5px" }}>
            cancel
          </Icon>
          Desactivar Rol
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            Edita el estatus del rol seleccionado en el empleado
          </DialogContentText>
          <Grid>
            <FormControlLabel
              control={
                <Switch
                  checked={statusSelect.checkedStatus}
                  onChange={handleChangeStatus}
                  name="checkedStatus"
                  color="primary"
                />
              }
              label="Estatus"
            />
          </Grid>
        </DialogContent>
        <DialogActions style={{ float: "right", margin: "auto" }}>
          <Button
            startIcon={<Save />}
            style={{ color: "white", backgroundColor: "#38BC7C" }}
            onClick={changeLaborStatus}
          >
            Guardar Cambios
          </Button>
          <Button
            startIcon={<Close />}
            style={{ color: "white", backgroundColor: "gray" }}
            onClick={handleCloseDisable}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de añadir roles */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseWithouSaving}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Cerrar sin guardar
            </Typography>
            <Button
              autoFocus
              color="inherit"
              style={{ backgroundColor: "#38BC7C" }}
              onClick={() => {
                httpSendData();
              }}
            >
              <Save style={{ marginRight: 10 }} />
              Guardar Asignación
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.toolbar} />
        <Container>
          <Card elevation={1} style={{ borderRadius: 20 }}>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Typography style={{ marginTop: 15 }} variant="h6">
                Sólo se considerarán los roles del sistema
              </Typography>
              <Typography
                style={{ color: "#9B9A9A", marginBottom: 15 }}
                variant="subtitle1"
              >
                <b>Selecciona el rol y el nivel</b>
              </Typography>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Select
                  style={{ width: 20 + "%", marginBottom: 20, marginLeft: 20 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={uniqueLabor}
                  onChange={handleChange}
                >
                  {httpLabors.map((item) => (
                    <MenuItem key={item.id} value={item.id}>{item.name}
                    </MenuItem>

                  ))}
                </Select>

                <Select
                  style={{ width: 20 + "%", marginBottom: 20, marginLeft: 20 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={levelLabor}
                  onChange={handleChangeLevel}
                >
                  <MenuItem value={3}>Nivel 3</MenuItem>
                  <MenuItem value={2}>Nivel 2</MenuItem>
                  <MenuItem value={1}>Nivel 1</MenuItem>
                  <MenuItem value={0}>Nivel 0</MenuItem>
                </Select>
              </Grid>
              <Button
                variant="outlined"
                style={{
                  color: "white",
                  backgroundColor: "#38BC7C",
                  marginBottom: 20,
                }}
                onClick={() => {
                  if (levelLabor === null || uniqueLabor === null) {
                    Toast.InfoMessage(
                      "Sugerencia",
                      "Debes seleccionar un rol y un nivel"
                    );
                  } else {
                    let flag = false;
                    httpLabors.map((item) => {
                      if (item.id == uniqueLabor) {
                        objLabors.labor.name = item.name;
                      }
                    });
                    if (httpSendLabors.length === 0) {
                      setHttpSendLabors((prevArray) => [
                        ...prevArray,
                        objLabors,
                      ]);
                    } else if (httpSendLabors.length > 0) {
                      for (let i = 0; i < httpSendLabors.length; i++) {
                        if (httpSendLabors[i].labor.id === objLabors.labor.id) {
                          Toast.WarningMessage(
                            "¡Atención!",
                            "El rol seleccionado ya está en la tabla."
                          );
                          flag = true;
                          break;
                        }
                      }
                      if (flag) {
                      } else {
                        setHttpSendLabors((prevArray) => [
                          ...prevArray,
                          objLabors,
                        ]);
                        flag = false;
                      }
                    }
                  }
                }}
              >
                Añadir la selección a la tabla
              </Button>
            </Grid>
          </Card>
          <br />
          <MaterialTable
            style={{ borderRadius: 20, padding: 10, marginTop: 20 }}
            columns={[
              {
                title: "Rol",
                field: "labor.name",
                headerStyle: {
                  WebkitBorderTopLeftRadius: 10,
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: "Nivel (0-3)",
                field: "level",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
            ]}
            data={httpSendLabors}
            actions={[

            ]}
            title="Roles del empleado"
            //Configuración de las etiquetas de la tabla
            options={{
              paging: false,
              actionsColumnIndex: -1,
              headerStyle: {
                fontWeight: "bold",
                backgroundColor: "#01579b",
                color: "#FFF",
                paddingRight: 10,
                WebkitBorderTopRightRadius: 10,
              },
            }}
            localization={{
              header: {
                actions: "Quitar",
              },
              body: {
                emptyDataSourceMessage: "No hay roles asignados aún",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Filtro",
              },
            }}
          />
        </Container>
      </Dialog>
    </>
  );
};
export default UpdateEmployee;
