import Icon from "@material-ui/core/Icon";
import Container from "@material-ui/core/Container";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Grid, Typography, CardHeader, CardContent, Card } from "@material-ui/core";
import Logo from "../../Images/logo.png";

const theme = createMuiTheme();
theme.typography.h4 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2.4rem',
    },
};
const useStyles = makeStyles((theme) => ({
    shadowCard: {
        maxWidth: 300,
        height: 200,
        marginTop: 30,
        borderRadius: 15,
    }
}));

const StartSreenAdmin = () => {
    const classes = useStyles();

    const dataCards = [
        {
            text: "Gestiona los roles que un empleado puede realizar, todas aquellas habilidades o competencias en las que se puede desempeñar.",
            icon: <Icon style={{ color: 'white', marginRight: 10 }}>work</Icon>,
            title: "Gestión de roles"
        },
        {
            text: "Maneja todos los datos de los usuarios del sistema.",
            icon: <Icon style={{ color: 'white', marginRight: 10 }}>supervised_user_circle</Icon>,
            title: "Gestión de empleados"
        },
        {
            text: "Administra los proyectos del sistema, puedes asignar sus polígonos, las actividades, tareas y asignar empleados.",
            icon: <Icon style={{ color: 'white', marginRight: 10 }}>folder</Icon>,
            title: "Gestión de proyectos"
        },
    ];
    return (
        <>
            <Grid container direction="column" justify="center" alignItems="center">
                <img src={Logo} width="150" />
                <ThemeProvider theme={theme}>
                    <Typography variant="h4" style={{ paddingTop: 16 }}>Bienvenido Administrador</Typography>
                </ThemeProvider>
                <Typography style={{ color: "#9B9A9A", paddingTop: 5 }} variant="subtitle1" >
                    FIBERPROMEX S.A.S de C.V. Administrador de proyectos
                </Typography>
            </Grid>
            <Container style={{ marginTop: 5 }}>
                <Grid container direction="row" justify="space-around" alignItems="center">
                    {
                        dataCards.map(item => (
                            <Card key={item.title} className={classes.shadowCard}>
                                <CardHeader style={{ backgroundColor: "#075E71", borderTopLeftRadius: 15, borderTopRightRadius: 15 }} subheader={
                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                        {item.icon}
                                        <Typography style={{ color: "white" }} variant="subtitle1">{item.title}</Typography>
                                    </Grid>
                                } />
                                <CardContent>
                                    <Typography variant="body1" align="left">
                                        {item.text}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))
                    }
                </Grid>
            </Container>
            <Container style={{ marginTop: 60 }}>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                    <Icon style={{ fontSize: 120, color: '#FF9A24' }}>assessment</Icon>
                    <Grid style={{ marginLeft: 5, marginTop: 21 }}>
                        <Typography variant="h5" style={{ color: '#FF9A24' }}>Reportes</Typography>
                        <Typography variant="subtitle2" align="left">
                            Puedes visualizar de una manera organizada los diferentes datos
                        </Typography>
                        <Typography variant="subtitle2">
                            del sistema, consulta los 6 diferentes tipos de reportes.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
};
export default StartSreenAdmin;