import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import DateFormat from "../../../utilities/DateFormat"
import PrintIcon from '@material-ui/icons/Print';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from "../../../Images/LogoPDF.png";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import es from "date-fns/locale/es";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Skeleton from "@material-ui/lab/Skeleton";
import MaterialTable from "material-table";
import Container from "@material-ui/core/Container";
import API from "../../../utilities/api"
import { useHistory } from "react-router-dom";
import Toast from "../../../utilities/Notifications";
import { Search, Cached } from "@material-ui/icons";

import {
  Grid, Typography, Icon, Button, Paper
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
    },
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    minWidth: 250,
  },
  search: {
    display: "flex",
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(3),
    alignItems: "center",
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  colHeader: {
    color: "white",
    "&:hover": {
      color: "white",
    },
  },
}));

const columnsPDF = [
  {
    title: "Proyecto",
    field: 'name',
    headerStyle: {
      WebkitBorderTopLeftRadius: 10,
      WebkitBorderTopRightRadius: 0,
    },
  },
  {
    title: "Limite KM",
    field: 'limitKm',
    headerStyle: {
      WebkitBorderTopRightRadius: 0,
    },
  },
 /* {
    title: " Fecha de creación",
    field: 'createdAt',
    type: "date",
    headerStyle: {
      WebkitBorderTopRightRadius: 0,
    },
  },*/
  {
    title: "Fecha final tentativa",
    field: 'tentativeAt',
    headerStyle: {
      WebkitBorderTopRightRadius: 0,
    },
  },
  {
    title: "Estatus",
    field: 'isCompleted',
    headerStyle: {
      WebkitBorderTopRightRadius: 0,
    },
    render: (rowData) =>
      rowData.isCompleted === true ? (
        <Typography>Finalizado</Typography>
      ) : (
        <Typography>Proceso</Typography>
      ),
  },
  {
    title: "Total KM enlace",
    field: 'linkKm',
    headerStyle: {
      WebkitBorderTopRightRadius: 0,
    },
  },
  {
    title: "Total KM zona",
    field: 'networkKm',
    headerStyle: {
      WebkitBorderTopRightRadius: 0,
    },
  },
  {
    title: "Total",
    field: 'totalKm',
    headerStyle: {
      WebkitBorderTopRightRadius: 0,
    },
  },
]

const Loading = () => {
  return (
    <>
      <Skeleton
        variant="text"
        width={100 + "%"}
        height={150}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
      <Skeleton
        variant="rect"
        width={100 + "%"}
        height={320}
        animation="wave"
        style={{ borderRadius: 20 }}
      />
    </>
  );
};

const TableGeneralReport = () => {
  const history = useHistory();
  const classes = useStyles();
  const [selectedDateStart, setSelectedDateStart] = useState(new Date());
  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date());
  const [reportList, setReportList] = React.useState([]);

  const downloadPdf = () => {
    const doc = new jsPDF()

    doc.setFontSize(40);
    doc.text("A D P R O", 75, 20, 'center')
    doc.addImage(Logo, 'JEPG', 15, 9, 23, 23)

    doc.setFontSize(12);
    doc.text('FIBERPROMEX S.A.S de C.V Administrador\rde Proyectos.', 42, 27);

    doc.setFontSize(10);
    doc.text('Domicilio Fiscal: Privada Xitle #343,\rColonia los volcanes. C.P.62350\rDelegación Cuernavaca, Mor.', 139, 13);

    doc.setFontSize(15);
    doc.text('Lista de Proyectos Generales', 70, 45);

    doc.autoTable({
      margin: { top: 50 },
      theme: "striped",
      columns: columnsPDF.map(col => ({ ...col, dataKey: col.field })),
      body: reportList
    })
    doc.save('Reporte_General.pdf');
  }

  const handleDateChangeStart = (date) => {
    setSelectedDateStart(date);
  };

  const handleDateChangeEnd = (date) => {
    setSelectedDateEnd(date);
  };

  const goToReport = (projectRowData) => {
    history.push(`/homeAdministrator/generalReport/individualReport/${projectRowData.id}`);
  };

  const dateRange = async () => {
    const rangeDate = {
      min: selectedDateStart,
      max: selectedDateEnd
    }
    await API.post("/project/report", rangeDate)
      .then((response) => {
        if (selectedDateStart > selectedDateEnd) {
          Toast.ErrorMessage("Información", "No se puede buscar con fecha mayor a la inicial.")
        } else {
          let upDate = []
          response.data.map((project) => {
            project.createdAt = DateFormat.instance.setFormat(project.createdAt);
            project.tentativeAt = DateFormat.instance.setFormat(project.tentativeAt);
            project.isCompleted = project.isCompleted ? "Finalizado" : "Proceso";
            project.linkKm = project.linkKm === null ? 0 : project.linkKm.toFixed(2);
            project.networkKm = project.networkKm === null ? 0 : project.networkKm.toFixed(2);
            project.totalKm = project.totalKm === null ? 0 : project.totalKm.toFixed(2);
            upDate.push(project)
          })
          setReportList(upDate);
          Toast.InfoMessage(
            "Consulta Realizada",
            "Revisa los nuevos datos.",
          );
        }
        if (response.data.length === 0) {
          Toast.InfoMessage(
            "Información",
            "No se encontro proyectos en esas fechas.",
          );
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Ha ocurrido un error!",
          "Los sentimos, no se pudo completar el envío"
        );
      });
  };

  const peticionGetGeneralReport = async () => {
    await API.get("/project/report/")
      .then((response) => {
        let upDate = []
        response.data.map((project) => {
          project.createdAt = DateFormat.instance.setFormat(project.createdAt);
          project.tentativeAt = DateFormat.instance.setFormat(project.tentativeAt);
          project.isCompleted = project.isCompleted ? "Finalizado" : "Proceso";
          project.linkKm = project.linkKm === null ? 0 : project.linkKm.toFixed(2);
          project.networkKm = project.networkKm === null ? 0 : project.networkKm.toFixed(2);
          project.totalKm = project.totalKm === null ? 0 : project.totalKm.toFixed(2);
          upDate.push(project)
        })
        setReportList(upDate);
        if (response.data.length === 0) {
          Toast.InfoMessage(
            "Información",
            "Aún no hay proyectos registrados.",
          );
        } else if (response.data.length > 0) {
          Toast.SuccessMessage("¡Datos refrescados!", "Revisa si es que hay algo nuevo.")
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.ErrorMessage(
          "¡Algo ocurrió en el servidor!", "No se logró obtener información de los reportes generales."
        );
      });
  };


  useEffect(() => {
    peticionGetGeneralReport();
  }, []);


  return (
    <>
      <Container>
        <Paper elevation={1} style={{ borderRadius: 10, borderLeft: '8px solid #01579b' }}>
          <Grid
            style={{ marginBottom: 20, marginTop: 10 }}
            container
            spacing={3}
            justify="space-between"
          >
            <Grid item>
              <Typography variant="h5" style={{ marginLeft: 20 }}>
                <b>Consulta en un rango de fechas</b>
              </Typography>
              <Grid >
                <Button
                  onClick={() => {
                    peticionGetGeneralReport()
                  }}
                  startIcon={<Cached />}
                  style={{
                    color: "white",
                    backgroundColor: "#00A4D8",
                    width: 200,
                    height: 39,
                    marginLeft: 20,
                    marginRight: 10,
                    marginTop: 10
                  }}
                >
                  Refrescar datos
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                <form noValidate autoComplete="off">
                  <div>
                    <KeyboardDatePicker
                      format="yyyy/MM/dd"
                      margin="normal"
                      required
                      id="date-picker-inline"
                      label="Fecha de Inicio"
                      value={selectedDateStart}
                      onChange={handleDateChangeStart}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      style={{ marginRight: 10, marginTop: 0 }}
                    />

                    <KeyboardDatePicker
                      format="yyyy/MM/dd"
                      margin="normal"
                      required
                      id="date-picker-inline"
                      label="Fecha de Fin"
                      value={selectedDateEnd}
                      onChange={handleDateChangeEnd}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      style={{ marginTop: 0 }}
                    />

                    <Button
                      startIcon={<Search />}
                      style={{ marginTop: 12, marginRight: 20, marginLeft: 10, color: "white", backgroundColor: "#38BC7C" }}
                      onClick={dateRange}
                    >
                      Consultar
                    </Button>
                  </div>
                </form>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <Container>
        {reportList.length === 0 ? (
          <Loading />
        ) : (
          <MaterialTable
            style={{ borderRadius: 20, padding: 10 }}
            columns={[
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                    Proyecto
                  </Typography>
                ),
                field: 'name',
                headerStyle: {
                  WebkitBorderTopLeftRadius: 10,
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                   Limite de Kilometros
                  </Typography>
                ),
                field: 'limitKm',
                type: "numeric",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                   Fecha de creación
                  </Typography>
                ),
                field: 'createdAt',
                type: "date",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                   Fecha final tentativa
                  </Typography>
                ),
                field: 'tentativeAt',
                type: "date",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                   Estatus
                  </Typography>
                ),
                field: 'isCompleted',
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
                render: (rowData) =>
                  rowData.isCompleted === true ? (
                    <Typography>Finalizado</Typography>
                  ) : (
                    <Typography>Proceso</Typography>
                  ),
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                   Total de Kilometros de enlace
                  </Typography>
                ),
                field: 'linkKm',
                type: "numeric",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                  Total de Kilometros de zona
                  </Typography>
                ),
                field: 'networkKm',
                type: "numeric",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
              {
                title: (
                  <Typography className={classes.colHeader} variant="subtitle2">
                  Total de Kilometros
                  </Typography>
                ),
                field: 'totalKm',
                type: "numeric",
                headerStyle: {
                  WebkitBorderTopRightRadius: 0,
                },
              },
            ]}
            data={reportList}
            title=""
            actions={[
              {
                icon: () => <PrintIcon />,// you can pass icon too
                tooltip: "Exportar PDF",
                onClick: () => downloadPdf(),
                isFreeAction: true
              },

              (rowData) => ({
                icon: () => (
                  <Icon style={{ color: "#00A4D8" }}>remove_red_eye</Icon>
                ),
                tooltip: <Typography>Ver Proyecto</Typography>,
                onClick: (event, rowData) => goToReport(rowData),
              }),
            ]}
            //Configuraciín de las etiquetas de la tabla
            options={{
              draggable: false,
              actionsColumnIndex: -1, //orientación de los botonotes de Acciones
              headerStyle: {
                fontWeight: "bold",
                backgroundColor: "#01579b",
                color: "#FFF",
                WebkitBorderTopRightRadius: 10,
              },
            }}
            localization={{
              header: {
                actions: "Acciones",
              },
              body: {
                emptyDataSourceMessage: "No hay registros aún",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Filtro",
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsPerPage: "Filas por página:",
                labelRowsSelect: "Filas",
                firstAriaLabel: "Primer página",
                firstTooltip: "Primer página",
                previousAriaLabel: "Anterior",
                previousTooltip: "Anterior",
                nextAriaLabel: "Siguiente",
                nextTooltip: "Siguiente",
                lastAriaLabel: "Última",
                lastTooltip: "Última",
              },
            }}
          />
        )}
      </Container>



    </>
  );
};

export default TableGeneralReport;
